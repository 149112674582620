import React, { useContext } from 'react';
import { Data, Filters } from '../contexts';
import { parseMoney } from '../../utils';
import { CommonSizeArrowsHelper, MonthlyPNLandBalValues, MonthlyNAICSFocusedVals } from '../report_components/MoneyHelpers';

const MonthlyHistoricTable = ({ clientName, editMetrics, handleEditMetrics, handleSelectAndLayout, rows, sheet, title, year, ...props }) => {
  const { calcs, yoy_calcs } = useContext(Data)
  const { currentNewFilters } = useContext(Filters);
  let compareWith = currentNewFilters.compare_with
  const monthNames = Object.values(props.monthsObj)
  let monthsAvailable = 0
  let arrowCellClass = props.pdf ? 'common-size-arrow-pdf' : 'common-size-arrow';
  let commonSizeValCellClass = props.pdf ? 'common-size-cell-pdf' : 'common-size-cell';
  let useableMonths = Object.values(calcs['you']['cash'])
  useableMonths.forEach(thing => thing !== null ? monthsAvailable += 1 : null)

  const buildEmptyRow = (i, copy = '', relatedCheckedKey, padding = '', checked, displayChart, copyRowStyle, copyCellStyle) => {
    if (copy === 'Current Liabilities') {
      copyRowStyle['paddingTop'] = '20px'
    }
    copyRowStyle['height'] = props.page === 'naics_focused' ? '20px' : ''
    let rowChecked = displayChart && checked && editMetrics && props.page === 'money' ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''

    return (
      <tr key={`${i}-empty-${copy}`} onClick={() => editMetrics ? handleSelectAndLayout('money', sheet, relatedCheckedKey) : null}>
        {props.page === 'money' && !props.pdf ? <td>{rowChecked}</td> : null}
        <td className={copyCellStyle} style={copyRowStyle}>{padding + copy}</td>
        <td className='table-copy' style={copyRowStyle}></td>
        <td className='table-copy' style={copyRowStyle}></td>
        <td className='table-copy' style={copyRowStyle}></td>
        <td className='table-copy' style={copyRowStyle}></td>
        <td className='table-copy' style={copyRowStyle}></td>
        <td className='table-copy' style={copyRowStyle}></td>
        <td className='table-copy' style={copyRowStyle}></td>
        <td className='table-copy' style={copyRowStyle}></td>
        <td className='table-copy' style={copyRowStyle}></td>
        <td className='table-copy' style={copyRowStyle}></td>
        <td className='table-copy' style={copyRowStyle}></td>
        <td className='table-copy' style={copyRowStyle}></td>
        <td className='table-copy' style={copyRowStyle}></td>
        <td className={commonSizeValCellClass} style={copyRowStyle}></td>
        {compareWith === 'Default:None' ? null : <td className={arrowCellClass} style={copyRowStyle}></td>}
        {compareWith.includes('Forecast') || currentNewFilters.report_period === 'Trailing 12' ? <td className={commonSizeValCellClass} style={copyRowStyle}></td> : null}
        {compareWith === 'Default:None' ? null : <td className={commonSizeValCellClass} style={copyRowStyle}></td>}
      </tr>
    )
  };

  const Total = (props) => {
    let style = { ...props.style, 'fontWeight': 'bold' };
    if (props.array.length === 0) { return <td style={style}></td> }
    if (props.sheet == 'pnl') {
      return (<td style={style}>{parseMoney(props.array.reduce((a, b) => a + b, 0))}</td>);
    } else {
      return (<td style={style}>{parseMoney(props.array[monthsAvailable - 1])}</td>);
    }
  };

  const MonthlyCommonSized = (props) => {
    if (props.array.length === 0) { return <td className={commonSizeValCellClass} style={props.style}></td> }
    if (props.sheet == 'pnl') {
      const reducedArray = props.array.slice(0, monthsAvailable).reduce((a, b) => a + b, 0)

      if (_.isNumber(reducedArray) && !_.isEmpty(props.passedCalcs)) {
        const totalRevenue = Object.values(props.passedCalcs['total_revenue']).slice(0, monthsAvailable).reduce((a, b) => a + b, 0)

        return (<td className={commonSizeValCellClass} style={props.style}>
          {((reducedArray / totalRevenue) * 100).toFixed(2) + '%'}
        </td>)
      } else {
        return (<td className={commonSizeValCellClass} style={props.style}>-</td>);
      }
    } else {
      const totalAssets = Object.values(props.passedCalcs['total_assets']).filter(val => _.isNumber(val))
      const totalAssetsValue = totalAssets.length > 0 ? totalAssets[monthsAvailable - 1] : null;

      if (_.isNumber(props.array[0]) && _.isNumber(totalAssetsValue)) {
        let lastValue = totalAssets.length > 0 ? props.array[monthsAvailable - 1] : null;

        return (<td className={commonSizeValCellClass} style={props.style}>
          {((lastValue / totalAssetsValue) * 100).toFixed(2) + '%'}
        </td>)
      } else {
        return (<td className={commonSizeValCellClass} style={props.style}>-</td>);
      }
    }
  }

  const buildRow = ({ i, header, header_is_total = false, header_key = '', key, copy, padding, bold, forecast, checked, displayChart, relatedCheckedKey, map_keys = [], emptyRow = false, topline = '', whiteSpace = '' }) => {
    let passedCalcs = {}
    let underlineFormat, boldFont, valueArr;
    let compareValueArr = [];
    let zeroMonths = [];
    let toplineFormat = topline ? 'solid 1px' : '';

    if (compareWith === 'Default:Peers') {
      passedCalcs = calcs['avg']
    } else if (compareWith === 'Default:YOY') {
      passedCalcs = yoy_calcs['you']
    } else if (compareWith.includes('Forecast')) {
      passedCalcs = props.forecastVals
    }

    if (forecast) {
      if (forecast.total) {
        if (forecast.total.boldedUnderline) {
          underlineFormat = 'solid 2px';
        } else if (forecast.total.doubleUnderline) {
          underlineFormat = 'double 2px';
        } else if (forecast.total.underline) {
          underlineFormat = 'solid 1px';
        }

        toplineFormat = forecast.total.topline ? 'solid 1px' : '';
      } else {
        if (forecast.boldedUnderline) {
          underlineFormat = 'solid 2px';
        } else if (forecast.doubleUnderline) {
          underlineFormat = 'double 2px';
        } else if (forecast.underline) {
          underlineFormat = 'solid 1px';
        }

        boldFont = bold || forecast.bold ? 'bold' : '';
        toplineFormat = forecast.topline ? 'solid 1px' : '';
      }
    }

    const copyRowStyle = {
      'fontWeight': boldFont,
      'textAlign': 'left',
      'borderBottom': underlineFormat,
      'borderTop': toplineFormat,
    }

    const copyCellStyle = whiteSpace ? 'table-copy wrap-copy-cell' : 'table-copy';

    const valueStyle = {
      'textAlign': 'right',
      'borderBottom': underlineFormat,
      'borderTop': toplineFormat ? 'solid 1px' : '',
    }

    if (header && header_is_total == false || header_key && header_is_total == false || emptyRow && header_is_total == false) return buildEmptyRow(i, copy, relatedCheckedKey, padding, checked, displayChart, copyRowStyle, copyCellStyle);

    if (props.page === 'naics_focused') {
      valueArr = MonthlyNAICSFocusedVals(monthsAvailable, key, map_keys, calcs['you'], rows)

      if (compareWith === 'Default:Peers') {
        compareValueArr = MonthlyNAICSFocusedVals(monthsAvailable, key, map_keys, calcs['docs_avg'], rows)
        passedCalcs = calcs['docs_avg']
      } else if (compareWith === 'Default:YOY') {
        compareValueArr = MonthlyNAICSFocusedVals(monthsAvailable, key, map_keys, yoy_calcs['you'], rows)
        passedCalcs = yoy_calcs['you']
      } else if (compareWith.includes("Forecast")) {
        compareValueArr = MonthlyNAICSFocusedVals(monthsAvailable, key, map_keys, props.forecastVals, rows)
        passedCalcs = props.forecastVals
      }
    } else {
      const you = calcs['you'] ? calcs['you'][key] : undefined;
      const avg = calcs['avg'] ? calcs['avg'][key] : undefined;
      const yoy = yoy_calcs['you'] ? yoy_calcs['you'][key] : undefined;
      const forecast = props.forecastVals ? props.forecastVals[key] : undefined;

      valueArr = MonthlyPNLandBalValues(monthsAvailable, sheet, key, you, calcs['you'])

      if (compareWith === 'Default:Peers') {
        compareValueArr = MonthlyPNLandBalValues(monthsAvailable, sheet, key, avg, calcs['avg'])
      } else if (compareWith === 'Default:YOY') {
        compareValueArr = MonthlyPNLandBalValues(monthsAvailable, sheet, key, yoy, yoy_calcs['you'])
      } else if (compareWith.includes("Forecast")) {
        compareValueArr = MonthlyPNLandBalValues(monthsAvailable, sheet, key, forecast, props.forecastVals)
      }
    }

    for (let i = 0; i < monthsAvailable + 1; i++) {
      valueArr[i] === 0 && compareValueArr.reduce((a, b) => a + b, 0) === 0 ? zeroMonths.push(i) : null;
    }
    let hideRow = zeroMonths.length === monthsAvailable ? copy : null;
    let rowStyle = copy === hideRow ? { display: 'none' } : {}
    let rowChecked = displayChart && checked && editMetrics && props.page === 'money' ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''

    return (
      <tr id={`${sheet}-report-row-${i}`} key={`${sheet}-report-row-${key}-${i}`} style={rowStyle} onClick={() => editMetrics ? handleSelectAndLayout('money', sheet, relatedCheckedKey) : null}>
        {props.page === 'money' && !props.pdf ? <td>{rowChecked}</td> : null}
        <td className={copyCellStyle} style={copyRowStyle}>
          {padding + copy}
        </td>
        <td style={valueStyle}>
          {parseMoney(valueArr[0])}
        </td>
        <td style={valueStyle}>
          {parseMoney(valueArr[1])}
        </td>
        <td style={valueStyle}>
          {parseMoney(valueArr[2])}
        </td>
        <td style={valueStyle}>
          {parseMoney(valueArr[3])}
        </td>
        <td style={valueStyle}>
          {parseMoney(valueArr[4])}
        </td>
        <td style={valueStyle}>
          {parseMoney(valueArr[5])}
        </td>
        <td style={valueStyle}>
          {parseMoney(valueArr[6])}
        </td>
        <td style={valueStyle}>
          {parseMoney(valueArr[7])}
        </td>
        <td style={valueStyle}>
          {parseMoney(valueArr[8])}
        </td>
        <td style={valueStyle}>
          {parseMoney(valueArr[9])}
        </td>
        <td style={valueStyle}>
          {parseMoney(valueArr[10])}
        </td>
        <td style={valueStyle}>
          {parseMoney(valueArr[11])}
        </td>
        <Total
          array={valueArr}
          style={valueStyle}
          sheet={sheet}
        />
        <MonthlyCommonSized
          array={valueArr}
          metricKey={key}
          style={valueStyle}
          sheet={sheet}
          passedCalcs={calcs['you']}
        />
        {compareWith === 'Default:None'
          ? null
          : <CommonSizeArrowsHelper
            cssClass={''}
            display={'monthly'}
            youTotRev={calcs['you']['total_revenue']}
            youTotAssets={calcs['you']['total_assets']}
            arrayYou={valueArr}
            arrayCompareTo={compareValueArr}
            keyArrBuilder={key}
            monthsAvailable={monthsAvailable}
            passedCalcs={passedCalcs}
            pdf={props.pdf}
            sheet={sheet}
            style={valueStyle}
          />}
        {compareWith.includes('Forecast') || currentNewFilters.report_period === 'Trailing 12'
          ? <Total
            array={compareValueArr}
            style={valueStyle}
            sheet={sheet}
          /> : null
        }
        {compareWith === 'Default:None'
          ? null
          : <MonthlyCommonSized
            array={compareValueArr}
            metricKey={key}
            style={valueStyle}
            sheet={sheet}
            passedCalcs={passedCalcs}
          />}
      </tr>
    );
  };

  let monthlyRows = [];
  rows.forEach((row, i) => {
    row.i = i
    monthlyRows.push(buildRow(row))
  })


  let numberCompare;
  if (compareWith === 'Default:YOY') {
    numberCompare = 'n = 1'
  } else {
    numberCompare = `n = ${props.n}`
  }

  let tableID = props.page !== 'naics_focused' ? `${sheet}-monthly-money` : 'naics_focused-monthy-money'

  let YOYyearDisplay = props.calendarYearEnd ? year - 1 : year
  let firstTotal = currentNewFilters.report_period === 'Trailing 12' ? `T12 ${year}` : 'Total'
  let firstPercent = currentNewFilters.report_period === 'Trailing 12' ? `You T12 ${year}` : 'You YTD'
  let secondTotal = null
  let secondPercent = null
  if (compareWith.includes('Forecast') && currentNewFilters.report_period !== 'Trailing 12') {
    secondTotal = `${props.monthlyCompareWithTitle} Total`
    secondPercent = props.monthlyCompareWithTitle
  } else if (compareWith === 'Default:Peers' && currentNewFilters.report_period !== 'Trailing 12') {
    secondPercent = 'Peers Avg'
  } else if (compareWith === 'Default:YOY' && yoy_calcs['you'] && currentNewFilters.report_period !== 'Trailing 12') {
    secondPercent = `YOY ${YOYyearDisplay}`
  } else if (currentNewFilters.report_period === 'Trailing 12' && compareWith !== 'Default:None') {
    secondTotal = `T12 ${year - 1}`
    secondPercent = `T12 ${year - 1}`
  }

  const buildMonthlyPDF = (pageViewClassName, tableRows, continued = false) => {
    let sheetTitle = continued ? `${title} - ${clientName} (Continued)` : `${title} - ${clientName}`

    return (
      <div className={pageViewClassName}>
        <div className='report-pdf-landscape'>
          <div>
            <h1 className='report-table-title'>{sheetTitle}</h1>
            <div className='money-pdf-table-div'>
              <table style={{ whiteSpace: 'pre' }} className='money-monthly-pdf-table'>
                <thead>
                  <tr key={`${sheet}-months-header-pdf`}>
                    <td></td>
                    <td>{monthNames[0]}</td>
                    <td>{monthNames[1]}</td>
                    <td>{monthNames[2]}</td>
                    <td>{monthNames[3]}</td>
                    <td>{monthNames[4]}</td>
                    <td>{monthNames[5]}</td>
                    <td>{monthNames[6]}</td>
                    <td>{monthNames[7]}</td>
                    <td>{monthNames[8]}</td>
                    <td>{monthNames[9]}</td>
                    <td>{monthNames[10]}</td>
                    <td>{monthNames[11]}</td>
                    <td style={{ 'fontWeight': 'bold' }}>{firstTotal}</td>
                    <td className={commonSizeValCellClass}>{firstPercent}</td>
                    <td className={arrowCellClass}></td>
                    {secondTotal
                      ? <td className='common-size-cell'>{secondTotal}</td>
                      : null
                    }
                    {secondPercent
                      ? <td className='common-size-cell'>{secondPercent}</td>
                      : null
                    }
                  </tr>
                </thead>
                <tbody>
                  {tableRows}
                  <tr><td className='footer-cell'><i>{numberCompare}</i></td></tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
        </div>
      </div>
    )
  }

  const buildMonthlyTable = () => {
    if (props.pdf) {
      let pageViewClass = 'landscape-view'
      let rowLength = monthlyRows.length

      if (rowLength > 35) {
        let firstPageRows = monthlyRows.slice(0, 35)
        let secondPageRows = monthlyRows.slice(35)
        return (
          <>
            {buildMonthlyPDF(pageViewClass, firstPageRows)}
            {buildMonthlyPDF(pageViewClass, secondPageRows, true)}
          </>
        )
      } else {
        return buildMonthlyPDF(pageViewClass, monthlyRows)
      }

    } else {
      return (
        <div className='money-report-card monthly-sheet'>
          <div className='money-report-container'>
            <table className='monthly-report-table sheets' id={tableID}>
              <thead>
                <tr key={`${title}-monthly-header`}>
                  {props.page === 'money' ? (<td className='edit-metrics-cell' onClick={() => handleEditMetrics()}>{editMetrics ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td>) : null}
                  <td></td>
                  <td>{monthNames[0]}</td>
                  <td>{monthNames[1]}</td>
                  <td>{monthNames[2]}</td>
                  <td>{monthNames[3]}</td>
                  <td>{monthNames[4]}</td>
                  <td>{monthNames[5]}</td>
                  <td>{monthNames[6]}</td>
                  <td>{monthNames[7]}</td>
                  <td>{monthNames[8]}</td>
                  <td>{monthNames[9]}</td>
                  <td>{monthNames[10]}</td>
                  <td>{monthNames[11]}</td>
                  <td style={{ 'fontWeight': 'bold' }}>{firstTotal}</td>
                  <td className='common-size-cell'>{firstPercent}</td>
                  <td className='common-size-arrow'></td>
                  {secondTotal
                    ? <td className='common-size-cell'>{secondTotal}</td>
                    : null
                  }
                  {secondPercent
                    ? <td className='common-size-cell'>{secondPercent}</td>
                    : null
                  }
                </tr>
              </thead>
              <tbody>
                {monthlyRows}
              </tbody>
              <tfoot>
                <tr><td><i>{numberCompare}</i></td></tr>
              </tfoot>
            </table>
          </div>
        </div>
      )
    }
  }

  return buildMonthlyTable()
};

export default MonthlyHistoricTable;
