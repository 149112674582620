import React, { useState, useEffect } from 'react';
import Dropdown from '../utils/Dropdown';
import EditChartOfAccountsPreview from './EditChartOfAccountsPreview.js';
import ProgressBarModal from '../progress_bar/ProgressBarModal';

function buildName(stack, name) {
  const pre = stack.map(s => `[${s}]`).join('');
  return pre + `[${name}]`;
}

const Sub = ({ name, value, stack }) => {
  const [on, setOn] = useState(value);
  const [altered, alter] = useState(false);
  const [open, setOpen] = useState(false);

  return(
    <div>
      <div>
        {name}:{'\u00A0\u00A0'}
        <input
          type='checkbox'
          checked={on}
          onChange={({target}) => {
            setOn(target.checked);
            alter(true);
          }}
        />
        <input
          type='hidden'
          name={altered && !open ? buildName(stack, name) : null}
          value={on}
        />
      </div>
      {on ?
          <Child
            title={name + ' settings'}
            stack={stack.concat(name)}
            dropdownChange={(open) => setOpen(open)}
            {...value}
          />
          : null}
    </div>
  )
}

const Input = ({ name, stack, type, value, onCopyChange }) => {
  const [altered, alter] = useState(false);
  const [val, setVal] = useState(type === 'checkbox' && !value ? false : value);

  function handleChange({ target }) {
    alter(true);
    setVal(type === 'checkbox' ? target.checked : target.value);
    if (onCopyChange && name === 'copy') onCopyChange(target.value);
  }

  return(
    <div>
      {name}:{'\u00A0\u00A0'}
      <input
        type={type}
        defaultChecked={value}
        defaultValue={value}
        onChange={handleChange}
      />
      <input
        type='hidden'
        name={altered ? buildName(stack, name) : null}
        value={val}
      />
    </div>
  );
}

const inputs = [
  {name: 'real_key', type: 'text'},
  {name: 'copy', type: 'text'},
  {name: 'map', type: 'checkbox'},
  {name: 'prefix', type: 'checkbox'},
  {name: 'index', type: 'number'},
  {name: 'hide', type: 'checkbox'},
  {name: 'parent_prefix', type: 'text'},
  {name: 'pad', type: 'checkbox'},
  {name: 'no_pad', type: 'checkbox'},
  {name: 'no_header', type: 'checkbox'},
  {name: 'header_is_total', type: 'checkbox'},
  {name: 'negative', type: 'checkbox'},
  {name: 'subtract', type: 'checkbox'},
  {name: 'stop', type: 'checkbox'},
  {name: 'bold', type: 'checkbox'},
  {name: 'topline', type: 'checkbox'},
  {name: 'underline', type: 'checkbox'},
  {name: 'doubleUnderline', type: 'checkbox'},
  {name: 'boldedUnderline', type: 'checkbox'},
  {name: 'displayChart', type: 'checkbox'},
  {name: 'displayTotalOnChart', type: 'checkbox'},
  {name: 'yearsView', type: 'checkbox'},
  {name: 'monthlyView', type: 'checkbox'},
  {name: 'ytdView', type: 'checkbox'},
  {name: 'trailing12Metric', type: 'checkbox'},
];

const Child = (props) => {
  const { hidden, ke, title, copy, total, money, people,
          customers, forecast, stack, industry, children,
          onCopyChange, dropdownChange } = props;

  return(
    <Dropdown copy={title || copy || ke} hidden={hidden} onChange={dropdownChange} >
      {inputs.map((input,i) =>
        <Input
          key={i}
          stack={stack}
          value={props[input.name]}
          onCopyChange={onCopyChange}
          {...input}
        />
      )}
      <Sub name={'total'} value={total} stack={stack} />
      <Sub name={'money'} value={money} stack={stack} />
      <Sub name={'people'} value={people} stack={stack} />
      <Sub name={'customers'} value={customers} stack={stack} />
      <Sub name={'forecast'} value={forecast} stack={stack} />
      <Dropdown copy={'Children'}>
        <div>
          {children ? buildChildren(children, stack.concat('children')) : null}
          <NewAccount
            parentKey={ke}
            stack={stack.concat('children')}
            index={children ? Object.keys(children).length + 1 : 1}
          />
        </div>
      </Dropdown>
    </Dropdown>
  );
}

const NewAccount = ({ stack, index, parentKey }) => {
  const [newAccount, setNewAccount] = useState(false);
  const [key, setKey] = useState('_new_');

  function updateKey(copy) {
    const res = parentKey + '_' + copy.toLowerCase().replace(' ', '_');
    setKey(res);
  }

  return(
    <div>
      {newAccount ?
        <div>
          <Child
            title='New Account'
            stack={stack.concat(key)}
            onCopyChange={(val) => updateKey(val)}
            index={index}
          />
          <input type='hidden' name={buildName(stack.concat(key), 'industry')} value='true' />
          <NewAccount parentKey={parentKey} stack={stack} index={index + 1}/>
        </div>
        : <i className='fa fa-plus-square new-account' onClick={() => setNewAccount(true)} />}
    </div>
  );
}

function buildChildren(children, stack) {
  Object.keys(children).forEach(key => {
    if (children[key] === null) {
      delete children[key];
    }
  });
  const keys = Object.keys(children).sort((a, b) => { children[a].index - children[b].index })
  return keys.map((key, i) => {
    return(
      <Child
        key={i}
        stack={stack.concat(key)}
        {...{ ke: key, ...children[key] }}
      />
    );
  });
}

const EditChartOfAccounts = (props) => {
  const [tree, setTree] = useState(props.account_tree);
  const [map_list, setMapList] = useState(props.map_list);
  const [progressBar, setProgressBar] = useState(false);

  useEffect(() => {
    $('#coa-form').change(function(e){
      const formData = new FormData(document.getElementById('coa-form'));
      const request = new XMLHttpRequest();
      request.onerror = () => console.error(this.statusText);
      request.onload = () => {
        const res = JSON.parse(request.response);
        setTree(res.coa);
        setMapList(res.map_list);
      };
      request.open('POST', `/admin/naicses/chart_of_accounts/${props.id}`);
      request.send(formData);
    });
  }, []);

  if (progressBar) return(
    <ProgressBarModal
      job={'ChartOfAccountsWorker'}
      refresh={true}
    />
  );

  return(
    <div>
      <h1>Edit Chart of Accounts - {props.code}</h1>
      <br></br>
      <div id='edit-coa'>
        <form className='coa-child' id='coa-form' action={`/admin/naicses/chart_of_accounts/${props.id}?save=true`} data-remote={true} method='POST' onSubmit={() => setProgressBar(true)} >
          {buildChildren(props.tree, ['tree'])}
          <br></br>
          <input type='submit' value='Save' className='btn btn-primary' />
        </form>
        <EditChartOfAccountsPreview tree={tree} map_list={map_list} />
      </div>
    </div>
  );
}

export default EditChartOfAccounts;
