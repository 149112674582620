import { parseMoney } from "../../utils";
import { CommonSizeArrows, MonthlyPNLandBalValues, MonthlyPNLandBalCommonSized, YTDPnlandBalCommonSizeValue, AnnualCashFlowValues, MonthlyCashFlowValues, AnnualKFIsValues, calculateKFIMetric, YTDPnlandBalValues, YTDCashFlowValues, MonthlyKFIsValues, KFIsCommonSizeArrows, CashFlowCommonSizeArrows } from './MoneyHelpers';
import { trailing12MonthCalculations } from "../monthly/monthy_analysis_table_trailing12_calculations";
import { peopleCustomersT12Metrics, peersAnalysisPerCustomerVals } from "./PeopleCustomersHelpers";
const moneyText = {
    'total_revenue': {
        copy: 'Revenue Growth Rate',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `You grew ${deltaValue} slower than your ${compareWord}.`,
                    text2: `Your Revenue Growth is ${impactValue} less than your ${compareWord}.`,
                    textNotesSection: `Your Revenue Growth is ${impactValue} less than your ${compareWord}. Consider steps to keep pace with your ${compareWord}`,
                },
                worse: {
                    text1: `You grew ${deltaValue} slower than your ${compareWord}.`,
                    text2: `Your Revenue Growth is ${impactValue} less than your ${compareWord}.`,
                    textNotesSection: `Your Revenue Growth is ${impactValue} less than your ${compareWord}. Consider steps to keep pace with your ${compareWord}.`
                },
                maintain: {
                    text1: `You are ${deltaValue} different than your ${compareWord}.`,
                    text2: `Your Revenue Growth is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Revenue Growth is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `You grew ${deltaValue} faster than your ${compareWord}.`,
                    text2: `Your Revenue Growth is ${impactValue} more than your ${compareWord}.`,
                    textNotesSection: `Your Revenue Growth is ${impactValue} more than your ${compareWord}. Maintain progress, but identify factors fueling growth and assess overall (1) sustainability, (2) long-term impact on operations and (3) potential opportunity costs`
                },
                best: {
                    text1: `You grew ${deltaValue} faster than your ${compareWord}.`,
                    text2: `Your Revenue Growth is ${impactValue} more than your ${compareWord}.`,
                    textNotesSection: `Your Revenue Growth is ${impactValue} more than your ${compareWord}. Maintain progress, but identify factors fueling growth and assess overall (1) sustainability, (2) long-term impact on operations and (3) potential opportunity costs`
                }
            }
            return statusTexts[status]
        }
    },
    'labor_costs': {
        copy: 'COGS-Labor',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your COGS-Labor expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your COGS-Labor is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your COGS-Labor is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                worse: {
                    text1: `Your COGS-Labor expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your COGS-Labor is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your COGS-Labor is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                maintain: {
                    text1: `Your COGS-Labor expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your COGS-Labor is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your COGS-Labor is within the average range of your ${compareWord}. Maintain progress.`
                },
                better: {
                    text1: `Your COGS-Labor expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your COGS-Labor is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your COGS-Labor is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
                best: {
                    text1: `Your COGS-Labor expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your COGS-Labor is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your COGS-Labor is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'cogs_subcontractors': {
        copy: 'COGS-Subcontractors',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your COGS-Subcontractors expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your COGS-Subcontractors is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your COGS-Subcontractors is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                worse: {
                    text1: `Your COGS-Subcontractors expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your COGS-Subcontractors is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your COGS-Subcontractors is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                maintain: {
                    text1: `Your COGS-Subcontractors expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your COGS-Subcontractors is within the average range of your ${compareWord}. Maintain Progress.`,
                    textNotesSection: `Your COGS-Subcontractors is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your COGS-Subcontractors expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your COGS-Subcontractors is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your COGS-Subcontractors is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
                best: {
                    text1: `Your COGS-Subcontractors expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your COGS-Subcontractors is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your COGS-Subcontractors is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'materials_costs': {
        copy: 'COGS-Materials',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your COGS-Materials expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your COGS-Materials is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your COGS-Materials is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Materials-related elements including pricing, purchasing, scrap, and/or shrinkage.`
                },
                worse: {
                    text1: `Your COGS-Materials expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your COGS-Materials is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your COGS-Materials is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Materials-related costs to determine if change is needed.`
                },
                maintain: {
                    text1: `Your COGS-Materials expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your COGS-Materials is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your COGS-Materials is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your COGS-Materials expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your COGS-Materials is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your COGS-Materials is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage. Review all Materials-related costs to determine if change is needed.`
                },
                best: {
                    text1: `Your COGS-Materials expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your COGS-Materials is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your COGS-Materials is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage. Review all Materials-related costs to determine if change is needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'overhead': {
        copy: 'COGS-Overhead',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your COGS-Overhead expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your COGS-Overhead is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your COGS-Overhead is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Overhead expenses or allocations and reduce, if possible.`
                },
                worse: {
                    text1: `Your COGS-Overhead expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your COGS-Overhead is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your COGS-Overhead is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Overhead expenses or allocations and reduce, if possible.`
                },
                maintain: {
                    text1: `Your COGS-Overhead expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your COGS-Overhead is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your COGS-Overhead is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your COGS-Overhead expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your COGS-Overhead is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your COGS-Overhead is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`
                },
                best: {
                    text1: `Your COGS-Overhead expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your COGS-Overhead is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your COGS-Overhead is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`
                },
            }
            return statusTexts[status]
        }
    },
    'transportation': {
        copy: 'COGS-Transportation',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your COGS-Transportation expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your COGS-Transportation is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your COGS-Transportation is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Transportation expenses and reduce, if possible.`
                },
                worse: {
                    text1: `Your COGS-Transportation expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your COGS-Transportation is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your COGS-Transportation is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Transportation expenses and reduce, if possible.`
                },
                maintain: {
                    text1: `Your COGS-Transportation expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your COGS-Transportation is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your COGS-Transportation is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your COGS-Transportation expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your COGS-Transportation is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your COGS-Transportation is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`
                },
                best: {
                    text1: `Your COGS-Transportation expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your COGS-Transportation is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your COGS-Transportation is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`
                },
            }
            return statusTexts[status]
        }
    },
    'other_costs': {
        copy: 'COGS-Other',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your COGS-Other expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your COGS-Other is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your COGS-Other is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Other expenses and reduce, if possible.`
                },
                worse: {
                    text1: `Your COGS-Other expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your COGS-Other is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your COGS-Other is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Other expenses and reduce, if possible.`
                },
                maintain: {
                    text1: `Your COGS-Other expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your COGS-Other is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your COGS-Other is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your COGS-Other expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your COGS-Other is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your COGS-Other is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`
                },
                best: {
                    text1: `Your COGS-Other expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your COGS-Other is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your COGS-Other is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_costs': {
        copy: 'Total COGS',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Total COGS expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Total COGS is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your Total COGS is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Total COGS-related elements including pricing, purchasing, scrap, and/or shrinkage.`
                },
                worse: {
                    text1: `Your Total COGS expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Total COGS is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your Total COGS is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Total COGS-related costs to determine if change is needed.`
                },
                maintain: {
                    text1: `Your Total COGS expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your COGS are within the average range of your ${compareWord}.`,
                    textNotesSection: `Your COGS are within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Total COGS expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Total COGS is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your Total COGS is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage. Review all Total COGS-related costs to determine if change is needed.`
                },
                best: {
                    text1: `Your Total COGS expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Total COGS is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your Total COGS is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage. Review all Total COGS-related costs to determine if change is needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'gross_profit': {
        copy: 'Gross Profit',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Gross Profit was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Gross Profit is ${impactValue} less than your ${compareWord}, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your Gross Profit is ${impactValue} less than your ${compareWord}, potentially a significant competitive disadvantage. Consider steps to keep pace with your ${compareWord}.`
                },
                worse: {
                    text1: `Your Gross Profit was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Gross Profit is ${impactValue} less than your ${compareWord}, potentially a competitive disadvantage.`,
                    textNotesSection: `Your Gross Profit is ${impactValue} less than your ${compareWord}, potentially a competitive disadvantage. Consider steps to keep pace with your ${compareWord}.`
                },
                maintain: {
                    text1: `Your Gross Profit was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Gross Profit is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Gross Profit is within the average range of your ${compareWord}. Maintain progress.`
                },
                better: {
                    text1: `Your Gross Profit was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Gross Profit is ${impactValue} more than your ${compareWord}, potentially a competitive advantage.`,
                    textNotesSection: `Your Gross Profit is ${impactValue} more than your ${compareWord}, potentially a competitive advantage. Maintain progress.`
                },
                best: {
                    text1: `Your Gross Profit was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Gross Profit is ${impactValue} more than your ${compareWord}, potentially a significant competitive advantage.`,
                    textNotesSection: `Your Gross Profit is ${impactValue} more than your ${compareWord}, potentially a significant competitive advantage. Maintain progress.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_compensation': {
        copy: 'Compensation',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Compensation expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Compensation is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your Compensation is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                worse: {
                    text1: `Your Compensation expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Compensation is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your Compensation is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                maintain: {
                    text1: `Your Compensation expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Compensation is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Compensation is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Compensation expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Compensation is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your Compensation is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
                best: {
                    text1: `Your Compensation expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Compensation is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your Compensation is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_taxes_and_benefits': {
        copy: 'Taxes & Benefits',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Taxes & Benefits expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Taxes & Benefits is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your Taxes & Benefits is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                worse: {
                    text1: `Your Taxes & Benefits expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Taxes & Benefits is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your Taxes & Benefits is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                maintain: {
                    text1: `Your Taxes & Benefits expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Taxes & Benefits is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Taxes & Benefits is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Taxes & Benefits expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Taxes & Benefits is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your Taxes & Benefits is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
                best: {
                    text1: `Your Taxes & Benefits expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Taxes & Benefits is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your Taxes & Benefits is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_corporate_culture': {
        copy: 'Corporate Culture',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Corporate Culture expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Corporate Culture is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your Corporate Culture is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                worse: {
                    text1: `Your Corporate Culture expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Corporate Culture is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your Corporate Culture is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                maintain: {
                    text1: `Your Corporate Culture expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Corporate Culture is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Corporate Culture is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Corporate Culture expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Corporate Culture is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your Corporate Culture is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
                best: {
                    text1: `Your Corporate Culture expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Corporate Culture is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your Corporate Culture is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'subcontractor_expenses': {
        copy: 'Subcontractor Expense',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: ` Your Subcontractor Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Subcontractor Expense is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your Subcontractor Expense is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                worse: {
                    text1: ` Your Subcontractor Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Subcontractor Expense is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your Subcontractor Expense is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                maintain: {
                    text1: ` Your Subcontractor Expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Subcontractor Expense is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Subcontractor Expense is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: ` Your Subcontractor Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Subcontractor Expense is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your Subcontractor Expense is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
                best: {
                    text1: ` Your Subcontractor Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Subcontractor Expense is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your Subcontractor Expense is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'people_misc_professional_fees': {
        copy: 'Professional Fees-HR',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Professional Fees-HR expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Professional Fees-HR is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your Professional Fees-HR is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                worse: {
                    text1: `Your Professional Fees-HR expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Professional Fees-HR is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your Professional Fees-HR is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Labor-related costs to determine if change is needed.`
                },
                maintain: {
                    text1: `Your Professional Fees-HR expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Professional Fees-HR is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Professional Fees-HR is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Professional Fees-HR expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Professional Fees-HR is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your Professional Fees-HR is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
                best: {
                    text1: `Your Professional Fees-HR expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Professional Fees-HR is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your Professional Fees-HR is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage. Review all Labor-related costs to determine if change is needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_customer_costs_sales_& marketing': {
        copy: 'Sales & Marketing',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Sales & Marketing expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Sales & Marketing is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your Sales & Marketing is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Sales & Marketing-related costs to determine if change is needed.`
                },
                worse: {
                    text1: `Your Sales & Marketing expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Sales & Marketing is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your Sales & Marketing is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Sales & Marketing-related costs to determine if change is needed.`
                },
                maintain: {
                    text1: `Your Sales & Marketing expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Sales & Marketing is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Sales & Marketing is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Sales & Marketing expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Sales & Marketing is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your Sales & Marketing is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage. Review all Sales & Marketing-related costs to determine if change is needed.`
                },
                best: {
                    text1: `Your Sales & Marketing expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Sales & Marketing is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your Sales & Marketing is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage. However, lower Sales & Marketing expense for a long period could jeopardize future growth.`
                },
            }
            return statusTexts[status]
        }
    },
    'travel_entertainment_and_meals': {
        copy: 'Travel, Entertainment & Meals',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Travel, Entertainment & Meals expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Travel, Entertainment & Meals is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your Travel, Entertainment & Meals is ${impactValue} more than your ${compareWord} average, potentially a significant competitive disadvantage. Review all Travel, Entertainment & Meals-related costs to determine if change is needed.`
                },
                worse: {
                    text1: `Your Travel, Entertainment & Meals expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Travel, Entertainment & Meals is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage.`,
                    textNotesSection: `Your Travel, Entertainment & Meals is ${impactValue} more than your ${compareWord} average, potentially a competitive disadvantage. Review all Travel, Entertainment & Meals-related costs to determine if change is needed.`
                },
                maintain: {
                    text1: `Your Travel, Entertainment & Meals expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Travel, Entertainment & Meals is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Travel, Entertainment & Meals is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Travel, Entertainment & Meals expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Travel, Entertainment & Meals is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage.`,
                    textNotesSection: `Your Travel, Entertainment & Meals is ${impactValue} less than your ${compareWord} average, potentially a competitive advantage. Review all Travel, Entertainment & Meals-related costs to determine if change is needed.`
                },
                best: {
                    text1: `Your Travel, Entertainment & Meals expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Travel, Entertainment & Meals is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage.`,
                    textNotesSection: `Your Travel, Entertainment & Meals is ${impactValue} less than your ${compareWord} average, potentially a significant competitive advantage. Review all Travel, Entertainment & Meals-related costs to determine if change is needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_customer_costs_billings_& collections': {
        copy: 'Billing & Collections',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Billing & Collections expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Billing & Collections is ${impactValue} more than your ${compareWord} average. `,
                    textNotesSection: `Your Billing & Collections is ${impactValue} more than your ${compareWord} average. `
                },
                worse: {
                    text1: `Your Billing & Collections expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Billing & Collectionss is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Billing & Collectionss is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Billing & Collections expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Billing & Collections is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Billing & Collections is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Billing & Collections expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Billing & Collections is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Billing & Collections is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Billing & Collections expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Billing & Collections is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Billing & Collections is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'customers_misc_professional_fees': {
        copy: 'Professional Fees-Customers',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Professional Fees-Customers expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Professional Fees-Customers is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Professional Fees-Customers is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Professional Fees-Customers expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Professional Fees-Customers is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Professional Fees-Customers is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Professional Fees-Customers expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Professional Fees-Customers is within the average range of your ${compareWord}. Maintain Progress.`,
                    textNotesSection: `Your Professional Fees-Customers is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Professional Fees-Customers expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Professional Fees-Customers is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Professional Fees-Customers is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Professional Fees-Customers expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Professional Fees-Customers is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Professional Fees-Customers is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'automobile_expenses': {
        copy: 'Automobile Expense',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Automobile Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Automobile Expense is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Automobile Expense is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Automobile Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Automobile Expense is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Automobile Expense is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Automobile Expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Automobile Expense is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Automobile Expense is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Automobile Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Automobile Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Automobile Expense is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Automobile Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Automobile Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Automobile Expense is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'bad_debt_expenses': {
        copy: 'Bad Debt Expense',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Bad Debt Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Bad Debt Expense is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Bad Debt Expense is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Bad Debt Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Bad Debt Expense is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Bad Debt Expense is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Bad Debt Expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Bad Debt Expense is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Bad Debt Expense is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Bad Debt Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Bad Debt Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Bad Debt Expense is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Bad Debt Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Bad Debt Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Bad Debt Expense is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'computer_related_costs': {
        copy: 'Computer Related Costs',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Computer Related Costs expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Computer Related Costs is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Computer Related Costs is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Computer Related Costs expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Computer Related Costs is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Computer Related Costs is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Computer Related Costs expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Computer Related Costs is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Computer Related Costs is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Computer Related Costs expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Computer Related Costs is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Computer Related Costs is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Computer Related Costs expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Computer Related Costs is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Computer Related Costs is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'contributions_and_donations': {
        copy: 'Contributions & Donations',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Contributions & Donations expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Contributions & Donations is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Contributions & Donations is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Contributions & Donations expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Contributions & Donations is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Contributions & Donations is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Contributions & Donations expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Contributions & Donations is within the average range of your ${compareWord}. Maintain Progress.`,
                    textNotesSection: `Your Contributions & Donations is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Contributions & Donations expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Contributions & Donations is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Contributions & Donations is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Contributions & Donations expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Contributions & Donations is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Contributions & Donations is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'insurance_expenses': {
        copy: 'Insurance Expense',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Insurance Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Insurance Expense is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Insurance Expense is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Insurance Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Insurance Expense is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Insurance Expense is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Insurance Expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Insurance Expense is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Insurance Expense is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Insurance Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Insurance Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Insurance Expense is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Insurance Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Insurance Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Insurance Expense is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'office_communications': {
        copy: 'Office Communications',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Office Communications expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Office Communications is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Office Communications is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Office Communications expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Office Communications is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Office Communications is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Office Communications expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Office Communications is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Office Communications is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Office Communications expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Office Communications is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Office Communications is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Office Communications expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Office Communications is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Office Communications is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'office_expenses': {
        copy: 'Office Expense',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Office Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Office Expense is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Office Expense is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Office Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Office Expense is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Office Expense is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Office Expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Office Expense is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Office Expense is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Office Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Office Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Office Expense is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Office Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Office Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Office Expense is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'other_operating_expenses': {
        copy: 'Other Operating Expense',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Other Operating Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Other Operating Expense is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Other Operating Expense is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Other Operating Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Other Operating Expense is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Other Operating Expense is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Other Operating Expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Other Operating Expense is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Other Operating Expense is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Other Operating Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Other Operating Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Other Operating Expense is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Other Operating Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Other Operating Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Other Operating Expense is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_professional_fees': {
        copy: 'Professional Fees - Acct/IT/Legal/Other',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Professional Fees - Acct/IT/Legal/Other expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Professional Fees - Acct/IT/Legal/Other is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Professional Fees - Acct/IT/Legal/Other is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Professional Fees - Acct/IT/Legal/Other expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Professional Fees - Acct/IT/Legal/Other is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Professional Fees - Acct/IT/Legal/Other is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Professional Fees - Acct/IT/Legal/Other expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Professional Fees - Acct/IT/Legal/Other is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Professional Fees - Acct/IT/Legal/Other is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Professional Fees - Acct/IT/Legal/Other expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Professional Fees - Acct/IT/Legal/Other is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Professional Fees - Acct/IT/Legal/Other is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Professional Fees - Acct/IT/Legal/Other expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Professional Fees - Acct/IT/Legal/Other is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Professional Fees - Acct/IT/Legal/Other is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'rent_and_facilities_costs': {
        copy: 'Rent & Facilities',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Rent & Facilities expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Rent & Facilities is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Rent & Facilities is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Rent & Facilities expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Rent & Facilities is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Rent & Facilities is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Rent & Facilities expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Rent & Facilities is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Rent & Facilities is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Rent & Facilities expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Rent & Facilities is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Rent & Facilities is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Rent & Facilities expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Rent & Facilities is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Rent & Facilities is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'laboratory_fees': {
        copy: 'Laboratory Fees',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Laboratory Fees expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Laboratory Fees is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Laboratory Fees is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Laboratory Fees expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Laboratory Fees is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Laboratory Fees is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Laboratory Fees expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Laboratory Fees is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Laboratory Fees is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Laboratory Fees expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Laboratory Fees is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Laboratory Fees is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Laboratory Fees expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Laboratory Fees is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Laboratory Fees is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'dental_supplies': {
        copy: 'Dental/Medical Supplies',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Dental/Medical Supplies expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Dental/Medical Supplies is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Dental/Medical Supplies is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Dental/Medical Supplies expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Dental/Medical Supplies is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Dental/Medical Supplies is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Dental/Medical Supplies expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Dental/Medical Supplies is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Dental/Medical Supplies is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Dental/Medical Supplies expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Dental/Medical Supplies is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Dental/Medical Supplies is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Dental/Medical Supplies expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Dental/Medical Supplies is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Dental/Medical Supplies is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_operating_expenses': {
        copy: 'Total Operating Expenses',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Total Operating Expenses was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Total Operating Expenses is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Total Operating Expenses is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Total Operating Expenses was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Total Operating Expenses is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Total Operating Expenses is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Total Operating Expenses was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Total Operating Expenses is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Total Operating Expenses is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Total Operating Expenses was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Total Operating Expenses is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Total Operating Expenses is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Total Operating Expenses was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Total Operating Expenses is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Total Operating Expenses is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'operating_profit': {
        copy: 'Operating Profit',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Operating Profit was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Operating Profit is ${impactValue} less than your ${compareWord}, potentially a significant competitive disadvantage.`,
                    textNotesSection: `Your Operating Profit is ${impactValue} less than your ${compareWord}, potentially a significant competitive disadvantage. Consider steps to keep pace with your ${compareWord}.`
                },
                worse: {
                    text1: `Your Operating Profit was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Operating Profit is ${impactValue} less than your ${compareWord}, potentially a competitive disadvantage.`,
                    textNotesSection: `Your Operating Profit is ${impactValue} less than your ${compareWord}, potentially a competitive disadvantage. Consider steps to keep pace with your ${compareWord}.`
                },
                maintain: {
                    text1: `Your Operating Profit was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Operating Profit is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Operating Profit is within the average range of your ${compareWord}. Maintain progress.`
                },
                better: {
                    text1: `Your Operating Profit was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Operating Profit is $7,733,583 more than your ${compareWord}, potentially a competitive advantage.`,
                    textNotesSection: `Your Operating Profit is $7,733,583 more than your ${compareWord}, potentially a competitive advantage. Maintain progress.`
                },
                best: {
                    text1: `Your Operating Profit was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Operating Profit is $7,733,583 more than your ${compareWord}, potentially a significant competitive advantage.`,
                    textNotesSection: `Your Operating Profit is $7,733,583 more than your ${compareWord}, potentially a significant competitive advantage. Maintain progress.`
                },
            }
            return statusTexts[status]
        }
    },
    'interest_and_other_expenses': {
        copy: 'Interest & Other Expense',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Interest & Other Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Interest & Other Expense is ${impactValue} more than your ${compareWord} average. `,
                    textNotesSection: `Your Interest & Other Expense is ${impactValue} more than your ${compareWord} average. `
                },
                worse: {
                    text1: `Your Interest & Other Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Interest & Other Expense is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Interest & Other Expense is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Interest & Other Expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Interest & Other Expense is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Interest & Other Expense is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Interest & Other Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Interest & Other Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Interest & Other Expense is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Interest & Other Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Interest & Other Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Interest & Other Expense is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_other_costs_taxes_- federal & state': {
        copy: 'Taxes - Federal & State',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Taxes - Federal & State expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Taxes - Federal & State is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Taxes - Federal & State is ${impactValue} more than your ${compareWord} average.`
                },
                worse: {
                    text1: `Your Taxes - Federal & State expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Taxes - Federal & State is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Taxes - Federal & State is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Taxes - Federal & State expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Taxes - Federal & State is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Taxes - Federal & State is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Taxes - Federal & State expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Taxes - Federal & State is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Taxes - Federal & State is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Taxes - Federal & State expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Taxes - Federal & State is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Taxes - Federal & State is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'depreciation_and_amortization': {
        copy: 'Depreciation & Amortization',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Depreciation & Amortization expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Depreciation & Amortization is ${impactValue} more than your ${compareWord} average. `,
                    textNotesSection: `Your Depreciation & Amortization is ${impactValue} more than your ${compareWord} average. `
                },
                worse: {
                    text1: `Your Depreciation & Amortization expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Depreciation & Amortization is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Depreciation & Amortization is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Depreciation & Amortization expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Depreciation & Amortization is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Depreciation & Amortization is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Depreciation & Amortization expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Depreciation & Amortization is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Depreciation & Amortization is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Depreciation & Amortization expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Depreciation & Amortization is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Depreciation & Amortization is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'other_income_expenses': {
        copy: 'Other (Income) Expense',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Other (Income) Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Other (Income) Expense is ${impactValue} more than your ${compareWord} average. `,
                    textNotesSection: `Your Other (Income) Expense is ${impactValue} more than your ${compareWord} average. `
                },
                worse: {
                    text1: `Your Other (Income) Expense was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Other (Income) Expense is ${impactValue} more than your ${compareWord} average.`,
                    textNotesSection: `Your Other (Income) Expense is ${impactValue} more than your ${compareWord} average.`
                },
                maintain: {
                    text1: `Your Other (Income) Expense was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Other (Income) Expense is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Other (Income) Expense is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Other (Income) Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Other (Income) Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Other (Income) Expense is ${impactValue} less than your ${compareWord} average.`
                },
                best: {
                    text1: `Your Other (Income) Expense was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Other (Income) Expense is ${impactValue} less than your ${compareWord} average.`,
                    textNotesSection: `Your Other (Income) Expense is ${impactValue} less than your ${compareWord} average.`
                },
            }
            return statusTexts[status]
        }
    },
    'operating_expenses_net_income': {
        copy: 'Net Income',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Net Income was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Net Income is ${impactValue} less than your ${compareWord}, potentially a significant competitive disadvantage. Consider steps to keep pace with your ${compareWord}.`,
                    textNotesSection: `Your Net Income is ${impactValue} less than your ${compareWord}, potentially a significant competitive disadvantage. Consider steps to keep pace with your ${compareWord}.`
                },
                worse: {
                    text1: `Your Net Income was ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Net Income is ${impactValue} less than your ${compareWord}, potentially a competitive disadvantage.`,
                    textNotesSection: `Your Net Income is ${impactValue} less than your ${compareWord}, potentially a competitive disadvantage. Consider steps to keep pace with your ${compareWord}.`
                },
                maintain: {
                    text1: `Your Net Income was ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Net Income is within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Net Income is within the average range of your ${compareWord}. Maintain progress.`
                },
                better: {
                    text1: `Your Net Income was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Net Income is ${impactValue} more than your ${compareWord}, potentially a competitive advantage.`,
                    textNotesSection: `Your Net Income is ${impactValue} more than your ${compareWord}, potentially a competitive advantage. Maintain progress.`
                },
                best: {
                    text1: `Your Net Income was ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Net Income is ${impactValue} more than your ${compareWord}, potentially a significant competitive advantage.`,
                    textNotesSection: `Your Net Income is ${impactValue} more than your ${compareWord}, potentially a significant competitive advantage. Maintain progress.`
                },
            }
            return statusTexts[status]
        }
    },
    'cash': {
        copy: 'Cash',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `You are carrying ${deltaValue} fewer Days Cash on Hand than your ${compareWord}.`,
                    text2: `You are holding ${impactValue} less Cash than your ${compareWord}.`,
                    textNotesSection: `You are holding ${impactValue} less Cash than your ${compareWord}.`
                },
                worse: {
                    text1: `You are carrying ${deltaValue} fewer Days Cash on Hand than your ${compareWord}.`,
                    text2: `You are holding ${impactValue} less Cash than your ${compareWord}.`,
                    textNotesSection: `You are holding ${impactValue} less Cash than your ${compareWord}.`
                },
                maintain: {
                    text1: `You Cash is ${deltaValue} different from your ${compareWord}.`,
                    text2: `You are holding ${impactValue} more or less Cash than your ${compareWord}.`,
                    textNotesSection: `You are holding ${impactValue} more or less Cash than your ${compareWord}.`
                },
                better: {
                    text1: `You are carrying ${deltaValue} more Days Cash on Hand than your ${compareWord}.`,
                    text2: `You are holding ${impactValue} more Cash than your ${compareWord}.`,
                    textNotesSection: `You are holding ${impactValue} more Cash than your ${compareWord}.`
                },
                best: {
                    text1: `You are carrying ${deltaValue} more Days Cash on Hand than your ${compareWord}.`,
                    text2: `You are holding ${impactValue} more Cash than your ${compareWord}.`,
                    textNotesSection: `You are holding ${impactValue} more Cash than your ${compareWord}.`
                },
            }
            return statusTexts[status]
        }
    },
    'accounts_receivable': {
        copy: 'Accounts Receivables',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `You are collecting your Accounts Receivables ${deltaValue} days slower than your ${compareWord}.`,
                    text2: `You are collecting your Receivables much slower than your ${compareWord}, potentially leading you to have excess A/R of ${impactValue} more than your ${compareWord}.`,
                    textNotesSection: `You are collecting your Receivables much slower than your ${compareWord}, potentially leading you to have excess A/R of ${impactValue} more than your ${compareWord}. Consider more aggressive collection policies, evaluating which customers are worthy of credit or changing policies for habitual offenders.`
                },
                worse: {
                    text1: `You are collecting your Accounts Receivables ${deltaValue} days slower than your ${compareWord}.`,
                    text2: `You are collecting your Receivables slower than your ${compareWord}, potentially leading you to have excess A/R of ${impactValue} more than your ${compareWord}.`,
                    textNotesSection: `You are collecting your Receivables slower than your ${compareWord}, potentially leading you to have excess A/R of ${impactValue} more than your ${compareWord}. Consider more aggressive collection policies, evaluating which customers are worthy of credit or changing policies for habitual offenders.`
                },
                maintain: {
                    text1: `Your Accounts Receivables are ${deltaValue} days different than your ${compareWord}.`,
                    text2: `You are collecting your Receivables on pace with your ${compareWord}.`,
                    textNotesSection: `You are collecting your Receivables on pace with your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `You are collecting your Accounts Receivables ${deltaValue} days faster than your ${compareWord}.`,
                    text2: `You are collecting your Receivables faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Receivables than your ${compareWord}.`,
                    textNotesSection: `You are collecting your Receivables faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Receivables than your ${compareWord}. Assess potential positive impact on sales of extending credit to customers.`
                },
                best: {
                    text1: `You are collecting your Accounts Receivables ${deltaValue} days faster than your ${compareWord}.`,
                    text2: `You are collecting your Receivables much faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Receivables than your ${compareWord}.`,
                    textNotesSection: `You are collecting your Receivables much faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Receivables than your ${compareWord}. Assess potential positive impact on sales of extending credit to customers.`
                },
            }
            return statusTexts[status]
        }
    },
    'inventory': {
        copy: 'Inventory Turns',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `You are turning your Inventory ${deltaValue} times fewer than your ${compareWord}.`,
                    text2: `Your Inventory Turns are much slower than your ${compareWord}, potentially leading you to have an excess of ${impactValue} more than your ${compareWord}.`,
                    textNotesSection: `Your Inventory Turns are much slower than your ${compareWord}, potentially leading you to have an excess of ${impactValue} more than your ${compareWord}. Could indicate a serious problem of excess or obsolete inventory. Take steps to analyze inventory and reduce where possible.`
                },
                worse: {
                    text1: `You are turning your Inventory ${deltaValue} times fewer than your ${compareWord}.`,
                    text2: `Your Inventory Turns are slower than your ${compareWord}, potentially leading you to have an excess of ${impactValue} more than your ${compareWord}.`,
                    textNotesSection: `Your Inventory Turns are slower than your ${compareWord}, potentially leading you to have an excess of ${impactValue} more than your ${compareWord}. Take steps to analyze your inventory and reduce where possible, especially items that aren't moving or are obsolete.`
                },
                maintain: {
                    text1: `Your Inventory Turns are ${deltaValue} times different than your ${compareWord}.`,
                    text2: `Your Inventory Turns are within the average range of your ${compareWord}.`,
                    textNotesSection: `Your Inventory Turns are within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `You are turning your Inventory ${deltaValue} times more than your ${compareWord}.`,
                    text2: `Your Inventory Turns are faster than your ${compareWord}, potentially leading you to have ${impactValue} lower inventory than your ${compareWord}.`,
                    textNotesSection: `Your Inventory Turns are faster than your ${compareWord}, potentially leading you to have ${impactValue} lower inventory than your ${compareWord}. Maintain Progress.`
                },
                best: {
                    text1: `You are turning your Inventory ${deltaValue} times more than your ${compareWord}.`,
                    text2: `Your Inventory Turns are much faster than your ${compareWord}, potentially leading you to have ${impactValue} lower inventory than your ${compareWord}.`,
                    textNotesSection: `Your Inventory Turns are much faster than your ${compareWord}, potentially leading you to have ${impactValue} lower inventory than your ${compareWord}. Might be an indication of managing turns well. If, however, inventory is too low, it might cause sales or delivery to be missed. Take steps to ensure that the right level of inventory is being carried.`
                },
            }
            return statusTexts[status]
        }
    },
    'under_billings': {
        copy: '',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                worse: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                maintain: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                better: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                best: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
            }
            return statusTexts[status]
        }
    },
    'prepaid_and_other_current_assets': {
        copy: '',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                worse: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                maintain: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                better: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                best: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
            }
            return statusTexts[status]
        }
    },
    'total_current_assets': {
        copy: '',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                worse: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                maintain: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                better: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                best: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
            }
            return statusTexts[status]
        }
    },
    'property_and_equipment': {
        copy: 'Property & Equipment',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Property & Equipment is ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Property & Equipment is ${impactValue} lower than your ${compareWord}.`,
                    textNotesSection: `Your Property & Equipment is ${impactValue} lower than your ${compareWord}. This could be an indication of a need to invest in assets that drive your business.`
                },
                worse: {
                    text1: `Your Property & Equipment is ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Property & Equipment is ${impactValue} lower than your ${compareWord}.`,
                    textNotesSection: `Your Property & Equipment is ${impactValue} lower than your ${compareWord}. This could be an indication of a need to invest in assets that drive your business.`
                },
                maintain: {
                    text1: `Your Property & Equipment is ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Property & Equipment is ${impactValue} different from your ${compareWord}.`,
                    textNotesSection: `Your Property & Equipment is ${impactValue} different from your ${compareWord}. Maintain progress.`
                },
                better: {
                    text1: `Your Property & Equipment is ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Property & Equipment is ${impactValue} higher than your ${compareWord}.`,
                    textNotesSection: `Your Property & Equipment is ${impactValue} higher than your ${compareWord}. This could be an indication of a need to invest in assets that drive your business.`
                },
                best: {
                    text1: `Your Property & Equipment is ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Property & Equipment is ${impactValue} higher than your ${compareWord}.`,
                    textNotesSection: `Your Property & Equipment is ${impactValue} higher than your ${compareWord}. This could be very positive, but could also be an indication of over-investment.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_fixed_assets_investments_long term': {
        copy: '',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                worse: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                maintain: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                better: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                best: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
            }
            return statusTexts[status]
        }
    },
    'other_long_term_assets': {
        copy: 'Other Long Term Assets',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Other Long Term Assets is ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Other Long Term Assets is ${impactValue} lower than your ${compareWord}.`,
                    textNotesSection: `Your Other Long Term Assets is ${impactValue} lower than your ${compareWord}. This could be an indication of a need to invest in intellectual property to keep pace with your ${compareWord}.`
                },
                worse: {
                    text1: `Your Other Long Term Assets is ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Other Long Term Assets is ${impactValue} lower than your ${compareWord}.`,
                    textNotesSection: `Your Other Long Term Assets is ${impactValue} lower than your ${compareWord}. This could be an indication of a need to invest in intellectual property to keep pace with your ${compareWord}.`
                },
                maintain: {
                    text1: `Your Other Long Term Assets is ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Other Long Term Assets is ${impactValue} different from your ${compareWord}.`,
                    textNotesSection: `Your Other Long Term Assets is ${impactValue} different from your ${compareWord}. Maintain progress.`
                },
                better: {
                    text1: `Your Property & Equipment is ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Other Long Term Assets is ${impactValue} higher than your ${compareWord}.`,
                    textNotesSection: `Your Other Long Term Assets is ${impactValue} higher than your ${compareWord}. Maintain progress.`
                },
                best: {
                    text1: `Your Property & Equipment is ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Other Long Term Assets is ${impactValue} higher than your ${compareWord}.`,
                    textNotesSection: `Your Other Long Term Assets is ${impactValue} higher than your ${compareWord}. Most likely positive, but consider if you are over-investing in OLTA.`
                },
            }
            return statusTexts[status]
        }
    },
    'less_accumulated_depreciation': {
        copy: 'Accumulated Depreciation',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Accumulated Depreciation is ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Accumulated Depreciation is ${impactValue} lower than your ${compareWord}.`,
                    textNotesSection: `Your Accumulated Depreciation is ${impactValue} lower than your ${compareWord}. This could be an indication of a need to invest in assets that drive your business.`
                },
                worse: {
                    text1: `Your Accumulated Depreciation is ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Accumulated Depreciation is ${impactValue} lower than your ${compareWord}.`,
                    textNotesSection: `Your Accumulated Depreciation is ${impactValue} lower than your ${compareWord}. This could be an indication of a need to invest in assets that drive your business.`
                },
                maintain: {
                    text1: `Your Accumulated Depreciation is ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Accumulated Depreciation is ${impactValue} different from your ${compareWord}.`,
                    textNotesSection: `Your Accumulated Depreciation is ${impactValue} different from your ${compareWord}. Maintain progress.`
                },
                better: {
                    text1: `Your Accumulated Depreciation is ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Accumulated Depreciation is ${impactValue} higher than your ${compareWord}.`,
                    textNotesSection: `Your Accumulated Depreciation is ${impactValue} higher than your ${compareWord}. This could be an indication of a need to invest in assets that drive your business.`
                },
                best: {
                    text1: `Your Accumulated Depreciation is ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Accumulated Depreciation is ${impactValue} higher than your ${compareWord}.`,
                    textNotesSection: `Your Accumulated Depreciation is ${impactValue} higher than your ${compareWord}. This could be very positive, but could also be an indication of over-investment.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_fixed_assets': {
        copy: '',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                worse: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                maintain: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                better: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                best: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
            }
            return statusTexts[status]
        }
    },
    'accounts_payable': {
        copy: 'Accounts Payables',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `You are paying your Accounts Payables ${deltaValue} days slower than your ${compareWord}.`,
                    text2: `You are paying your Payables much slower than your ${compareWord}, potentially leading you to have excess A/P of ${impactValue} more than your ${compareWord}. This could be causing issues with vendors.`,
                    textNotesSection: `You are paying your Payables much slower than your ${compareWord}, potentially leading you to have excess A/P of ${impactValue} more than your ${compareWord}. This could be causing issues with vendors. Consider taking steps to reduce A/P Days by examining Cash Flow metrics.`
                },
                worse: {
                    text1: `You are paying your Accounts Payables ${deltaValue} days slower than your ${compareWord}.`,
                    text2: `You are paying your Payables slower than your ${compareWord}, potentially leading you to have an excess A/P of ${impactValue} more than your ${compareWord}.`,
                    textNotesSection: `You are paying your Payables slower than your ${compareWord}, potentially leading you to have an excess A/P of ${impactValue} more than your ${compareWord}. This could be causing issues with vendors. Consider taking steps to reduce A/P Days by examining Cash Flow metrics.`
                },
                maintain: {
                    text1: `Your Accounts Payables are ${deltaValue} days different than your ${compareWord}.`,
                    text2: `You are paying your Payables on pace with your ${compareWord}.`,
                    textNotesSection: `You are paying your Payables on pace with your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `You are paying your Accounts Payables ${deltaValue} days faster than your ${compareWord}.`,
                    text2: `You are paying your Payables faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Payables than your ${compareWord}.`,
                    textNotesSection: `You are paying your Payables faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Payables than your ${compareWord}. Potential opportunity to negotiate with your vendors, seek better pricing, terms, delivery, etc. in exchange for your payment history.`
                },
                best: {
                    text1: `You are paying your Accounts Payables ${deltaValue} days faster than your ${compareWord}.`,
                    text2: `You are paying your Payables much faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Payables than your ${compareWord}.`,
                    textNotesSection: `You are paying your Payables much faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Payables than your ${compareWord}. Potential opportunity to negotiate with your vendors, seek better pricing, terms, delivery, etc. in exchange for your payment history.`
                },
            }
            return statusTexts[status]
        }
    },
    'accrued_liabilities': {
        copy: 'Accrued Liabilities',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `You are paying your Accrued Liabilities ${deltaValue} days slower than your ${compareWord}.`,
                    text2: `You are paying your Accrued Liabilities much slower than your ${compareWord}, potentially leading you to have excess Accrued Liabilities of ${impactValue} more than your ${compareWord}.`,
                    textNotesSection: `You are paying your Accrued Liabilities much slower than your ${compareWord}, potentially leading you to have excess Accrued Liabilities of ${impactValue} more than your ${compareWord}. Improve Cash Flow Metrics and pay down Accrued Liabilities to avoid issues.`
                },
                worse: {
                    text1: `You are paying your Accrued Liabilities ${deltaValue} days slower than your ${compareWord}.`,
                    text2: `You are paying your Accrued Liabilities slower than your ${compareWord}, potentially leading you to have an excess Accrued Liabilities of ${impactValue} more than your ${compareWord}.`,
                    textNotesSection: `You are paying your Accrued Liabilities slower than your ${compareWord}, potentially leading you to have an excess Accrued Liabilities of ${impactValue} more than your ${compareWord}. `
                },
                maintain: {
                    text1: `Your Accrued Liabilities are ${deltaValue} days different than your ${compareWord}.`,
                    text2: `You are paying your Payables on pace with your ${compareWord}.`,
                    textNotesSection: `You are paying your Payables on pace with your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `You are paying your Accrued Liabilities ${deltaValue} days faster than your ${compareWord}.`,
                    text2: `You are paying your Accrued Liabilities faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Accrued Liabilities than your ${compareWord}.`,
                    textNotesSection: `You are paying your Accrued Liabilities faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Accrued Liabilities than your ${compareWord}.`
                },
                best: {
                    text1: `You are paying your Accrued Liabilities ${deltaValue} days faster than your ${compareWord}.`,
                    text2: `You are paying your Accrued Liabilities much faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Accrued Liabilities than your ${compareWord}.`,
                    textNotesSection: `You are paying your Accrued Liabilities much faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Accrued Liabilities than your ${compareWord}.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_current_liabilities_unearned_revenue': {
        copy: '',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                worse: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                maintain: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                better: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                best: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
            }
            return statusTexts[status]
        }
    },
    'line_of_credit': {
        copy: 'Line of Credit',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Line of Credit is ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Line of Credit is ${impactValue} higher than your ${compareWord}.`,
                    textNotesSection: `Your Line of Credit is ${impactValue} higher than your ${compareWord}. Compare to Current Assets to support the LOC with your lender. Might want to consider moving to Long-Term Debt or paying down, if possible.`
                },
                worse: {
                    text1: `Your Line of Credit is ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Line of Credit is ${impactValue} higher than your ${compareWord}.`,
                    textNotesSection: `Your Line of Credit is ${impactValue} higher than your ${compareWord}. Compare to Current Assets to support the LOC with your lender. Might want to consider moving to Long-Term Debt or paying down, if possible.`
                },
                maintain: {
                    text1: `Your Line of Credit is ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Line of Credit is ${impactValue} different from your ${compareWord}.`,
                    textNotesSection: `Your Line of Credit is ${impactValue} different from your ${compareWord}. Maintain progress.`
                },
                better: {
                    text1: `Your Line of Credit is ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Line of Credit is ${impactValue} lower than your ${compareWord}.`,
                    textNotesSection: `Your Line of Credit is ${impactValue} lower than your ${compareWord}. Compare to Current Assets, but might be sources of capital to fund growth, if needed.`
                },
                best: {
                    text1: `Your Line of Credit is ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Line of Credit is ${impactValue} lower than your ${compareWord}.`,
                    textNotesSection: `Your Line of Credit is ${impactValue} lower than your ${compareWord}. Compare to Current Assets, but might be sources of capital to fund growth, if needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'current_portion_of_ltd': {
        copy: 'Current Portion of LTD',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Current Portion of LTD is ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Current Portion of LTD is ${impactValue} higher than your ${compareWord}.`,
                    textNotesSection: `Your Current Portion of LTD is ${impactValue} higher than your ${compareWord}. Compare to Current Assets to support the Current Portion of LTD with your lender. Might want to consider moving to Long-Term Debt or paying down, if possible.`
                },
                worse: {
                    text1: `Your Current Portion of LTD is ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Current Portion of LTD is ${impactValue} higher than your ${compareWord}.`,
                    textNotesSection: `Your Current Portion of LTD is ${impactValue} higher than your ${compareWord}. Compare to Current Assets to support the Current Portion of LTD with your lender. Might want to consider moving to Long-Term Debt or paying down, if possible.`
                },
                maintain: {
                    text1: `Your Current Portion of LTD is ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Current Portion of LTD is ${impactValue} different from your ${compareWord}.`,
                    textNotesSection: `Your Current Portion of LTD is ${impactValue} different from your ${compareWord}. Maintain progress.`
                },
                better: {
                    text1: `Your Current Portion of LTD is ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Current Portion of LTD is ${impactValue} lower than your ${compareWord}.`,
                    textNotesSection: `Your Current Portion of LTD is ${impactValue} lower than your ${compareWord}. Consider Debt, Equity & Cash Flow, but might be a source of capital to fund growth, if needed.`
                },
                best: {
                    text1: `Your Current Portion of LTD is ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Current Portion of LTD is ${impactValue} lower than your ${compareWord}.`,
                    textNotesSection: `Your Current Portion of LTD is ${impactValue} lower than your ${compareWord}. Consider Debt, Equity & Cash Flow, but might be a source of capital to fund growth, if needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'over_billings': {
        copy: '',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                worse: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                maintain: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                better: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                best: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
            }
            return statusTexts[status]
        }
    },
    'other_current_liabilities': {
        copy: 'Other Current Liabilities',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Other Current Liabilities are ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Other Current Liabilities are ${impactValue} higher than your ${compareWord}.`,
                    textNotesSection: `Your Other Current Liabilities are ${impactValue} higher than your ${compareWord}. Compare to Current Assets to support the LOC with your lender. Might want to consider moving to Long-Term Debt or paying down, if possible.`
                },
                worse: {
                    text1: `Your Other Current Liabilities are ${deltaValue} higher than your ${compareWord}.`,
                    text2: `Your Other Current Liabilities are ${impactValue} higher than your ${compareWord}.`,
                    textNotesSection: `Your Other Current Liabilities are ${impactValue} higher than your ${compareWord}. Compare to Current Assets to support the LOC with your lender. Might want to consider moving to Long-Term Debt or paying down, if possible.`
                },
                maintain: {
                    text1: `Your Other Current Liabilities are ${deltaValue} different from your ${compareWord}.`,
                    text2: `Your Other Current Liabilities are ${impactValue} different from your ${compareWord}.`,
                    textNotesSection: `Your Other Current Liabilities are ${impactValue} different from your ${compareWord}. Maintain progress.`
                },
                better: {
                    text1: `Your Other Current Liabilities are ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Other Current Liabilities are ${impactValue} lower than your ${compareWord}.`,
                    textNotesSection: `Your Other Current Liabilities are ${impactValue} lower than your ${compareWord}. Compare to Current Assets, but might be sources of capital to fund growth, if needed.`
                },
                best: {
                    text1: `Your Other Current Liabilities are ${deltaValue} lower than your ${compareWord}.`,
                    text2: `Your Other Current Liabilities are ${impactValue} lower than your ${compareWord}.`,
                    textNotesSection: `Your Other Current Liabilities are ${impactValue} lower than your ${compareWord}. Compare to Current Assets, but might be sources of capital to fund growth, if needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_current_liabilities': {
        copy: '',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                worse: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                maintain: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                better: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                best: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
            }
            return statusTexts[status]
        }
    },
    'long_term_debt': {
        copy: '',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                worse: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                maintain: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                better: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                best: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
            }
            return statusTexts[status]
        }
    },
    'net_worth': {
        copy: '',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                worse: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                maintain: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                better: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
                best: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: ``
                },
            }
            return statusTexts[status]
        }
    },
    'current_ratio': {
        copy: 'Current Ratio',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Current Ratio is ${deltaValue} lower than your ${compareWord}`,
                    text2: ``,
                    textNotesSection: `Could be an indication of your company's inability to meet its current obligations. Retain cash in the business, pay down current liabilities, or restructure short-term debt to be paid over longer term than one year.`
                },
                worse: {
                    text1: `Current Ratio is ${deltaValue} lower than your ${compareWord}`,
                    text2: ``,
                    textNotesSection: `Could be an indication of your company's inability to meet its current obligations. Retain cash in the business, pay down current liabilities, or restructure short-term debt to be paid over longer term than one year.`
                },
                maintain: {
                    text1: `Current Ratio is ${deltaValue} lower/higher than your ${compareWord}`,
                    text2: ``,
                    textNotesSection: `Maintain Progress.`
                },
                better: {
                    text1: `Current Ratio is ${deltaValue} higher than your ${compareWord}`,
                    text2: ``,
                    textNotesSection: `A high Current Ratio indicates the company has strong ability to meet its current obligations.`
                },
                best: {
                    text1: `Current Ratio is ${deltaValue} higher than your ${compareWord}`,
                    text2: ``,
                    textNotesSection: `A high Current Ratio indicates the company has strong ability to meet its current obligations. If much higher for greater than 1 year, consider investing a portion of your current assets.`
                },
            }
            return statusTexts[status]
        }
    },
    'senior_debt_to_ebitda': {
        copy: 'Senior Debt / EBITDA',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Indicates lower senior debt and/or higher cash flow than ${compareWord}. This could be an opportunity to leverage additional debt.`,
                    text2: ``,
                    textNotesSection: `Indicates lower senior debt and/or higher cash flow than ${compareWord}. This could be an opportunity to leverage additional debt.`
                },
                worse: {
                    text1: `Indicates lower senior debt and/or higher cash flow than ${compareWord}. This could be an opportunity to leverage additional debt.`,
                    text2: ``,
                    textNotesSection: `Indicates lower senior debt and/or higher cash flow than ${compareWord}. This could be an opportunity to leverage additional debt.`
                },
                maintain: {
                    text1: `Maintain Progress.`,
                    text2: ``,
                    textNotesSection: `Maintain Progress.`
                },
                better: {
                    text1: `Consider reducing Senior Debt, increasing EBITDA, or both.`,
                    text2: ``,
                    textNotesSection: `Consider reducing Senior Debt, increasing EBITDA, or both.`
                },
                best: {
                    text1: `Consider reducing Senior Debt, increasing EBITDA, or both.`,
                    text2: ``,
                    textNotesSection: `Consider reducing Senior Debt, increasing EBITDA, or both.`
                },
            }
            return statusTexts[status]
        }
    },
    'debt_to_equity': {
        copy: 'Debt / Equity',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Could be over-leveraged compared to ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Could be over-leveraged compared to ${compareWord}. Consider taking on debt to finance growth.`
                },
                worse: {
                    text1: `Could be over-leveraged compared to ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Could be over-leveraged compared to ${compareWord}. Consider taking on debt to finance growth.`
                },
                maintain: {
                    text1: `Maintain Progress.`,
                    text2: ``,
                    textNotesSection: `Maintain Progress.`
                },
                better: {
                    text1: `Could be under-leveraged compared to ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Could be under-leveraged compared to ${compareWord}. Consider paying down debt, retaining profits in the business, injecting equity into the business, or some combination of the three.`
                },
                best: {
                    text1: `Could be under-leveraged compared to ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Could be under-leveraged compared to ${compareWord}. Consider paying down debt, retaining profits in the business, injecting equity into the business, or some combination of the three.`
                },
            }
            return statusTexts[status]
        }
    },
    'ar_days': {
        copy: 'Accounts Receivable Days',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                best: {
                    text1: `You are collecting your Receivables much faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Receivables than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `You are collecting your Receivables much faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Receivables than your ${compareWord}. Assess potential positive impact on sales of extending credit to customers.`
                },
                better: {
                    text1: `You are collecting your Receivables faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Receivables than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `You are collecting your Receivables faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Receivables than your ${compareWord}. Assess potential positive impact on sales of extending credit to customers.`
                },
                maintain: {
                    text1: `You are collecting your Receivables on pace with your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `You are collecting your Receivables on pace with your ${compareWord}. Maintain Progress`
                },
                worse: {
                    text1: `You are collecting your Receivables slower than your ${compareWord}, potentially leading you to have excess A/R of ${impactValue} more than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `You are collecting your Receivables slower than your ${compareWord}, potentially leading you to have excess A/R of ${impactValue} more than your ${compareWord}. Consider more aggressive collection policies, evaluating which customers are worthy of credit or changing policies for habitual offenders.`
                },
                worst: {
                    text1: `You are collecting your Receivables much slower than your ${compareWord}, potentially leading you to have excess A/R of ${impactValue} more than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `You are collecting your Receivables much slower than your ${compareWord}, potentially leading you to have excess A/R of ${impactValue} more than your ${compareWord}. Consider more aggressive collection policies, evaluating which customers are worthy of credit or changing policies for habitual offenders.`
                },
            }
            return statusTexts[status]
        }
    },
    'ap_days': {
        copy: 'Accounts Payable Days',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `You are paying your Payables much slower than your ${compareWord}, potentially leading you to have excess A/P of ${impactValue} more than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `You are paying your Payables much slower than your ${compareWord}, potentially leading you to have excess A/P of ${impactValue} more than your ${compareWord}. This could be causing issues with vendors. Consider taking steps to reduce A/P Days by examining Cash Flow metrics.`
                },
                worse: {
                    text1: `You are paying your Payables slower than your ${compareWord}, potentially leading you to have an excess A/P of ${impactValue} more than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `You are paying your Payables slower than your ${compareWord}, potentially leading you to have an excess A/P of ${impactValue} more than your ${compareWord}. This could be causing issues with vendors. Consider taking steps to reduce A/P Days by examining Cash Flow metrics.`
                },
                maintain: {
                    text1: `You are paying your Payables on pace with your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `You are paying your Payables on pace with your ${compareWord}. Maintain Progress`
                },
                better: {
                    text1: `You are paying your Payables faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Payables than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `You are paying your Payables faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Payables than your ${compareWord}. Potential opportunity to negotiate with your vendors, seek better pricing, terms, delivery, etc. in exchange for your payment history.`
                },
                best: {
                    text1: `You are paying your Payables much faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Payables than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `You are paying your Payables much faster than your ${compareWord}, potentially leading you to have ${impactValue} fewer Payables than your ${compareWord}. Potential opportunity to negotiate with your vendors, seek better pricing, terms, delivery, etc. in exchange for your payment history.`
                },
            }
            return statusTexts[status]
        }
    },

}

const peopleText = {
    'total_revenue_per_employee': {
        copy: 'Revenue Per Employee',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Revenue Per Employee is ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Revenue Per Employee is ${impactValue} less than your ${compareWord}, which could be a significant competitive disadvantage. Review Revenue, employment levels, compensation and turnover to determine if action is needed.`
                },
                worse: {
                    text1: `Your Revenue Per Employee is ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Revenue Per Employee is ${impactValue} less than your ${compareWord}, which could be a competitive disadvantage.`
                },
                maintain: {
                    text1: `Your Revenue Per Employee is ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Revenue Per Employee is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Revenue Per Employee is ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Revenue Per Employee is ${impactValue} more than your ${compareWord}, which could be a competitive advantage.`
                },
                best: {
                    text1: `Your Revenue Per Employee is ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Revenue Per Employee is ${impactValue} more than your ${compareWord}, which could be a competitive advantage. Review employment levels, turnover, and compensation to ensure long-term sustainability.`
                },
            }
            return statusTexts[status]
        }
    },
    'operating_expenses_net_income_per_employee': {
        copy: 'Profit Per Employee',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Profit Per Employee was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Profit Per Employee is ${impactValue} lower than your ${compareWord}, which could be a significant competitive disadvantage. Review Revenue, employment levels, compensation and turnover to determine if action is needed.`
                },
                worse: {
                    text1: `Your Profit Per Employee was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Profit Per Employee is ${impactValue} lower than your ${compareWord}, which could be a competitive disadvantage.`
                },
                maintain: {
                    text1: `Your Profit Per Employee was ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Profit Per Employee is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Profit Per Employee was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Profit Per Employee is ${impactValue} higher than your ${compareWord}, which could be a competitive advantage.`
                },
                best: {
                    text1: `Your Profit Per Employee was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Profit Per Employee is ${impactValue} higher than your ${compareWord}, which could be a competitive advantage. Review employment levels, turnover, and compensation to ensure long-term sustainability.`
                },
            }
            return statusTexts[status]
        }
    },
    'sum_compensation_growth_rate': {
        copy: 'Compensation Growth Rate',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Compensation Growth Rate was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Compensation Growth Rate is ${impactValue} higher than your ${compareWord}, potentially a competitive disadvantage. Review revenue, profitability, employment levels, and turnover rate to determine of action is needed.`
                },
                worse: {
                    text1: `Your Compensation Growth Rate was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Compensation Growth Rate is ${impactValue} higher than your ${compareWord}, potentially a competitive disadvantage, unless being done strategically.`
                },
                maintain: {
                    text1: `Your Compensation Growth Rate was ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Compensation Growth Rate is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Compensation Growth Rate was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Compensation Growth Rate is ${impactValue} lower than your ${compareWord}, potentially a competitive advantage. Maintain Progress.`
                },
                best: {
                    text1: `Your Compensation Growth Rate was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Compensation Growth Rate is ${impactValue} lower than your ${compareWord}, potentially a competitive advantage, unless causes lower efficiency or higher turnover. Review for long-term sustainability.`
                },
            }
            return statusTexts[status]
        }
    },
    'sum_taxes_and_benefits_growth_rate': {
        copy: 'Benefits Growth Rate',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Benefits Growth Rate was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Benefits Growth Rate is ${impactValue} higher than your ${compareWord}, potentially a competitive disadvantage. Review revenue, profitability, employment levels, and turnover rate to determine of action is needed.`
                },
                worse: {
                    text1: `Your Benefits Growth Rate was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Benefits Growth Rate is ${impactValue} higher than your ${compareWord}, potentially a competitive disadvantage, unless being done strategically.`
                },
                maintain: {
                    text1: `Your Benefits Growth Rate was ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Benefits Growth Rate is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Benefits Growth Rate was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Benefits Growth Rate is ${impactValue} lower than your ${compareWord}, potentially a competitive advantage. Maintain Progress.`
                },
                best: {
                    text1: `Your Benefits Growth Rate was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Benefits Growth Rate is ${impactValue} lower than your ${compareWord}, potentially a competitive advantage, unless causes lower efficiency or higher turnover. Review for long-term sustainability.`
                },
            }
            return statusTexts[status]
        }
    },
    'sum_subcontractors_growth_rate': {
        copy: 'Use of Subcontractors Growth Rate',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Use of Subcontractors Growth Rate was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Use of Subcontractors Growth Rate is ${impactValue} higher than your ${compareWord}, potentially a competitive disadvantage, unless relieving you of fixed Compensation costs. Review overall People spend and revise if needed.`
                },
                worse: {
                    text1: `Your Use of Subcontractors Growth Rate was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Use of Subcontractors Growth Rate is ${impactValue} higher than your ${compareWord}, potentially a competitive disadvantage, unless relieving you of fixed Compensation costs.`
                },
                maintain: {
                    text1: `Your Use of Subcontractors Growth Rate was ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Use of Subcontractors Growth Rate is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Use of Subcontractors Growth Rate was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Use of Subcontractors Growth Rate is ${impactValue} lower than your ${compareWord}, potentially a competitive advantage. Maintain progress.`
                },
                best: {
                    text1: `Your Use of Subcontractors Growth Rate was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Use of Subcontractors Growth Rate is ${impactValue} lower than your ${compareWord}, potentially a competitive advantage, unless your fixed Compensation costs offer lower flexibility and higher costs.`
                },
            }
            return statusTexts[status]
        }
    },
    'average_wages_and_salaries': {
        copy: 'Avg Wages & Salaries Per Employee',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Avg Wages & Salaries Per Employee was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Avg Wages & Salaries Per Employee is ${impactValue} more than your ${compareWord}, potentially a significant competitive disadvantage. Review all Compensation-related costs to determine if change is needed.`
                },
                worse: {
                    text1: `Your Avg Wages & Salaries Per Employee was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Avg Wages & Salaries Per Employee is ${impactValue} more than your ${compareWord}, potentially a competitive disadvantage. Review all Wages & Salaries Per Employee-related costs to determine if change is needed.`
                },
                maintain: {
                    text1: `Your Avg Wages & Salaries Per Employee was ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Avg Wages & Salaries Per Employee is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Avg Wages & Salaries Per Employee was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Avg Wages & Salaries Per Employee is ${impactValue} less than your ${compareWord}, potentially a competitive advantage. Review all Wages & Salaries Per Employee-related costs to determine if change is needed.`
                },
                best: {
                    text1: `Your Avg Wages & Salaries Per Employee was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Avg Wages & Salaries Per Employee is ${impactValue} less than your ${compareWord}, potentially a significant competitive advantage. However, lower Wages & Salaries Per Employee expense for a long period could jeopardize future growth.`
                },
            }
            return statusTexts[status]
        }
    },
    'average_benefits': {
        copy: 'Avg Benefits Per Employee',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Benefits Per Employee was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Benefits Per Employee is ${impactValue} higher than your ${compareWord}, potentially a significant competitive disadvantage. Review all Compensation-related costs to determine if change is needed.`
                },
                worse: {
                    text1: `Your Benefits Per Employee was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Benefits Per Employee is ${impactValue} higher than your ${compareWord}, potentially a competitive disadvantage. Review all Wages & Salaries Per Employee-related costs to determine if change is needed.`
                },
                maintain: {
                    text1: `Your Benefits Per Employee was ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Benefits Per Employee is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Benefits Per Employee was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Benefits Per Employee is ${impactValue} lower than your ${compareWord}, potentially a competitive advantage. Review all Wages & Salaries Per Employee-related costs to determine if change is needed.`
                },
                best: {
                    text1: `Your Benefits Per Employee was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Benefits Per Employee is ${impactValue} lower than your ${compareWord},, potentially a significant competitive advantage. However, lower Wages & Salaries Per Employee expense for a long period could jeopardize future growth.`
                },
            }
            return statusTexts[status]
        }
    },
    'turnover_rate': {
        copy: 'Turnover Rate',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Turnover Rate was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: ``
                },
                worse: {
                    text1: `Your Turnover Rate was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: ``
                },
                maintain: {
                    text1: `Your Turnover Rate was ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: ``
                },
                better: {
                    text1: `Your Turnover Rate was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: ``
                },
                best: {
                    text1: `Your Turnover Rate was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: ``
                },
            }
            return statusTexts[status]
        }
    },
    'cost_of_turnover': {
        copy: 'Cost of Turnover',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: `Your Cost of Turnover is ${deltaValue} higher than your ${compareWord}, potentially a significant competitive disadvantage.`
                },
                worse: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: `Your Cost of Turnover is ${deltaValue} higher than your ${compareWord}, potentially a competitive disadvantage.`
                },
                maintain: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: `Your Cost of Turnover is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: `Your Cost of Turnover is ${deltaValue} lower than your ${compareWord}, potentially a competitive advantage.`
                },
                best: {
                    text1: ``,
                    text2: ``,
                    textNotesSection: `Your Cost of Turnover is ${deltaValue} lower than your ${compareWord}, potentially a competitive advantage.`
                },
            }
            return statusTexts[status]
        }
    }
}

const customersText = {
    'revenue_per_customer': {
        copy: 'Revenue Per Client',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Revenue Per Client was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Revenue Per Client is ${impactValue} lower than your ${compareWord}, potentially a competitive disadvantage. Review Growth Rate, Gross Profit, and Net Profit to determine if change is needed.`
                },
                worse: {
                    text1: `Your Revenue Per Client was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Revenue Per Client is ${impactValue} lower than your ${compareWord}, potentially a competitive disadvantage. Review Growth Rate, Gross Profit, and Net Profit to determine if change is needed.`
                },
                maintain: {
                    text1: `Your Revenue Per Client was ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Revenue Per Client is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Revenue Per Client was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Revenue Per Client is ${impactValue} higher than your ${compareWord}, potentially a competitive advantage. Review Growth Rate, Gross Profit, and Net Profit to determine if change is needed.`
                },
                best: {
                    text1: `Your Revenue Per Client was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Revenue Per Client is ${impactValue} higher than your ${compareWord}, potentially a competitive advantage. Review Growth Rate, Gross Profit, and Net Profit to determine if change is needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'gross_profit_per_customer': {
        copy: 'Gross Profit Per Client',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Gross Profit Per Client was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Gross Profit Per Client is ${impactValue} lower than your ${compareWord}, potentially a competitive disadvantage. Review Growth Rate, Gross Profit, and Net Profit to determine if change is needed.`
                },
                worse: {
                    text1: `Your Gross Profit Per Client was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Gross Profit Per Client is ${impactValue} lower than your ${compareWord}, potentially a competitive disadvantage. Review Growth Rate, Gross Profit, and Net Profit to determine if change is needed.`
                },
                maintain: {
                    text1: `Your Gross Profit Per Client was ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Gross Profit Per Client is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Gross Profit Per Client was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Gross Profit Per Client is ${impactValue} higher than your ${compareWord}, potentially a competitive advantage. Review Growth Rate, Gross Profit, and Net Profit to determine if change is needed.`
                },
                best: {
                    text1: `Your Gross Profit Per Client was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Gross Profit Per Client is ${impactValue} higher than your ${compareWord}, potentially a competitive advantage. Review Growth Rate, Gross Profit, and Net Profit to determine if change is needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'operating_expenses_net_income_per_customer': {
        copy: 'Net Profit Per Client',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Net Profit Per Client was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Net Profit Per Client is ${impactValue} lower than your ${compareWord}, potentially a competitive disadvantage. Review Growth Rate, Gross Profit, and Net Profit to determine if change is needed.`
                },
                worse: {
                    text1: `Your Net Profit Per Client was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Net Profit Per Client is ${impactValue} lower than your ${compareWord}, potentially a competitive disadvantage. Review Growth Rate, Gross Profit, and Net Profit to determine if change is needed.`
                },
                maintain: {
                    text1: `Your Net Profit Per Client was ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Net Profit Per Client is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Net Profit Per Client was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Net Profit Per Client is ${impactValue} higher than your ${compareWord}, potentially a competitive advantage. Review Growth Rate, Gross Profit, and Net Profit to determine if change is needed.`
                },
                best: {
                    text1: `Your Net Profit Per Client was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Net Profit Per Client is ${impactValue} higher than your ${compareWord}, potentially a competitive advantage. Review Growth Rate, Gross Profit, and Net Profit to determine if change is needed.`
                },
            }
            return statusTexts[status]
        }
    },
    'percent_of_sales_from_the_top_10_percent': {
        copy: 'Customer Concentration - 10%',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Revenue from the Top 10% of your Customers was ${deltaValue} more than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Revenue from the Top 10% of your Customers is ${impactValue} higher than your ${compareWord}, potentially a significant competitive disadvantage.`
                },
                worse: {
                    text1: `Your Revenue from the Top 10% of your Customers was ${deltaValue} more than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Revenue from the Top 10% of your Customers is ${impactValue} higher than your ${compareWord}, potentially a competitive disadvantage.`
                },
                maintain: {
                    text1: `Your Revenue from the Top 10% of your Customers was ${deltaValue} more/less than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Customer Concentration Rate is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Revenue from the Top 10% of your Customers was ${deltaValue} less than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Revenue from the Top 10% of your Customers is ${impactValue} lower than your ${compareWord}, potentially a competitive advantage.`
                },
                best: {
                    text1: `Your Revenue from the Top 10% of your Customers was ${deltaValue} less than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Revenue from the Top 10% of your Customers is ${impactValue} lower than your ${compareWord}, potentially a competitive advantage.`
                },
            }
            return statusTexts[status]
        }
    },
    'percent_of_sales_from_the_top_25_percent': {
        copy: 'Customer Concentration - 25%',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Revenue from the Top 25% of your Customers was ${deltaValue} more than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Revenue from the Top 25% of your Customers is ${impactValue} higher than your ${compareWord}, potentially a significant competitive disadvantage.`
                },
                worse: {
                    text1: `Your Revenue from the Top 25% of your Customers was ${deltaValue} more than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Revenue from the Top 25% of your Customers is ${impactValue} higher than your ${compareWord}, potentially a competitive disadvantage.`
                },
                maintain: {
                    text1: `Your Revenue from the Top 25% of your Customers was ${deltaValue} more/less than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Customer Concentration Rate is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Revenue from the Top 25% of your Customers was ${deltaValue} less than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Revenue from the Top 25% of your Customers is ${impactValue} lower than your ${compareWord}, potentially a competitive advantage.`
                },
                best: {
                    text1: `Your Revenue from the Top 25% of your Customers was ${deltaValue} less than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Revenue from the Top 25% of your Customers is ${impactValue} lower than your ${compareWord}, potentially a competitive advantage.`
                },
            }
            return statusTexts[status]
        }
    },
    'percent_of_sales_from_the_top_50_percent': {
        copy: 'Customer Concentration - 50%',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Revenue from the Top 50% of your Customers was ${deltaValue} more than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Revenue from the Top 50% of your Customers is ${impactValue} higher than your ${compareWord}, potentially a significant competitive disadvantage.`
                },
                worse: {
                    text1: `Your Revenue from the Top 50% of your Customers was ${deltaValue} more than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Revenue from the Top 50% of your Customers is ${impactValue} higher than your ${compareWord}, potentially a competitive disadvantage.`
                },
                maintain: {
                    text1: `Your Revenue from the Top 50% of your Customers was ${deltaValue} more/less than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Customer Concentration Rate is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Revenue from the Top 50% of your Customers was ${deltaValue} less than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Revenue from the Top 50% of your Customers is ${impactValue} lower than your ${compareWord}, potentially a competitive advantage.`
                },
                best: {
                    text1: `Your Revenue from the Top 50% of your Customers was ${deltaValue} less than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Revenue from the Top 50% of your Customers is ${impactValue} lower than your ${compareWord}, potentially a competitive advantage.`
                },
            }
            return statusTexts[status]
        }
    },
    'total_customer_costs_common_size_revenue': {
        copy: 'Marketing Spend',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Marketing Spend is much higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Consider in conjunction with Marketing ROI and adjust if needed.`
                },
                worse: {
                    text1: `Your Marketing Spend is higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Consider in conjunction with Marketing ROI and adjust if needed.`
                },
                maintain: {
                    text1: `Your Marketing Spend was ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Maintain Progress.`
                },
                better: {
                    text1: `Your Marketing Spend is lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `If Marketing ROI is high, could indicate efficiency. Determine the long-term impact of a Marketing Spend that's much lower than your ${compareWord}.`
                },
                best: {
                    text1: `Your Marketing Spend is much lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `If Marketing ROI is high, could indicate efficiency. Determine the long-term impact of a Marketing Spend that's much lower than your ${compareWord}.`
                },
            }
            return statusTexts[status]
        }
    },
    'customer_roi': {
        copy: 'Marketing ROI',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Marketing ROI was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Marketing ROI is ${impactValue} lower than your ${compareWord}. Consider editing how much and where you spend your marketing budget.`
                },
                worse: {
                    text1: `Your Marketing ROI was ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Marketing ROI is ${impactValue} lower than your ${compareWord}. Consider editing how much and where you spend your marketing budget.`
                },
                maintain: {
                    text1: `Your Marketing ROI was ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Marketing ROI is within the average range of your ${compareWord}. Maintain Progress.`
                },
                better: {
                    text1: `Your Marketing ROI was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Marketing ROI is ${impactValue} higher than your ${compareWord}. Maintain Progress.`
                },
                best: {
                    text1: `Your Marketing ROI was ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `Your Marketing ROI is ${impactValue} higher than your ${compareWord}. If because of low investment, consider how sustainable this is long-term.`
                },
            }
            return statusTexts[status]
        }
    },
    'number_of_new_customers_needed_to_maintain_growth': {
        copy: 'Growth Requirements',
        texts: function (status, impactValue, deltaValue, compareWord) {
            let statusTexts = {
                worst: {
                    text1: `Your Growth Requirements are ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `You need ${deltaValue} more clients than your peers in order to maintain your growth rate, potentially a significant competitive disadvantage.`
                },
                worse: {
                    text1: `Your Growth Requirements are ${deltaValue} higher than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `You need ${deltaValue} more clients than your peers in order to maintain your growth rate, potentially a competitive disadvantage.`
                },
                maintain: {
                    text1: `Your Growth Requirements are ${deltaValue} higher/lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `On average, you need the same number of new customers to grow your business at the same pace as your peers.`
                },
                better: {
                    text1: `Your Growth Requirements are ${deltaValue} lower than your ${compareWord}.`,
                    text2: ``,
                    textNotesSection: `You need ${deltaValue} fewer clients than your peers in order to maintain your growth rate, potentially a competitive advantage.`
                },
                best: {
                    text1: `Your Growth Requirements are ${deltaValue} lower than your ${compareWord}, potentially a competitive advantage.`,
                    text2: ``,
                    textNotesSection: `You need ${deltaValue} fewer clients than your peers in order to maintain your growth rate, potentially a significant competitive advantage.`
                },
            }
            return statusTexts[status]
        }
    }
}

const formatValue = (value, type, sheet = '', key) => {
    if (sheet === 'kfis') {
        const roundedDecimal = ['number_of_holes_on_golf_course', 'number_of_new_customers_needed_to_maintain_growth'];
        const ratio = ['current_ratio', 'net_working_capital_ratio', 'debt_to_equity', 'senior_debt_to_ebitda', 'ebitda_to_annual_interest']
        const days = ['ar_days', 'ap_days']
        const money = ['net_working_capital', 'operating_expenses_net_income', 'interest_and_other_expenses', 'total_other_costs_taxes_- federal & state', 'depreciation_and_amortization', 'ebitda', 'maintenace_per_golf_hole']
        const twoDecimal = ['asset_turnover_ratio', 'inventory_turnover_ratio']
        const percent = ['roa', 'roe', 'roce', 'gross_profit_common_size_revenue', 'operating_profit_common_size_revenue']

        if (roundedDecimal.includes(key)) {
            return formatters['roundedDecimal'](value)
        } else if (ratio.includes(key)) {
            return formatters['ratio'](value)
        } else if (days.includes(key)) {
            return formatters['days'](value)
        } else if (money.includes(key)) {
            return formatters['currency'](value)
        } else if (twoDecimal.includes(key)) {
            return formatters['twoDecimal'](value)
        } else if (percent.includes(key)) {
            return formatters['formatPercent'](value)
        } else {
            return _.isNumber(amount) ? '-' : ''
        };
    } else {
        return formatters[type](value)
    }
}

const formatters = {
    currency: function (value) {
        return parseMoney(value);
    },
    money: function (value) {
        return parseMoney(value);
    },
    percent: function (value) {
        return `${value.toFixed(1)}%`;
    },
    formatPercent: function (value) {
        return `${value.toFixed(1)}%`;
    },
    decimal: function (value) {
        return `${Math.round(value * 10) / 10}`;
    },
    roundedDecimal: function (value) {
        return `${Math.round(value)}`;
    },
    twoDecimal: function (value) {
        return value ? value.toFixed(2) : '';
    },
    ratio: function (value) {
        return value ? `${value.toFixed(1)} to 1` : '';
    },
    days: function (value) {
        return value ? `${value.toFixed(1)} days` : '';
    },
}

const getRangeValueKFI = (you, peer, key = null, parentKey = null) => {
    let youVal = you;
    let avgVal = peer;
    const percentKeys = ['roa', 'roe', 'roce', 'gross_profit_common_size_revenue', 'operating_profit_common_size_revenue']
    if (percentKeys.includes(key) && youVal) {
        youVal = you * 100
    }
    if (percentKeys.includes(key) && avgVal) {
        avgVal = peer * 100
    }
    let range = [(avgVal * 0.5), (avgVal * 0.75), (avgVal * 1.25), (avgVal * 1.5)].sort(function (a, b) { return a - b });

    // Only ar_days inventory_turnover_ratio debt_to_equity are good with lower values than peers avg
    const goodDownArrowKeys = ['ar_days', 'debt_to_equity', 'maintenace_per_golf_hole'];
    const keyIsGoodDown = goodDownArrowKeys.includes(key);
    const ingoreArrowKeys = ['ebitda'];
    if (ingoreArrowKeys.includes(key) || ingoreArrowKeys.includes(parentKey)) {
        return 'maintain';
    }

    if (key === 'debt_to_equity' && youVal < 0) {
        return 'worse'
    }

    switch (true) {
        case (youVal < range[0]):
            return keyIsGoodDown ? 'best' : 'worst';
        case (range[0] < youVal && youVal < range[1]):
            return keyIsGoodDown ? 'better' : 'worse';
        case (range[1] < youVal && youVal < range[2]):
            return 'maintain';
        case (range[2] < youVal && youVal < range[3]):
            return keyIsGoodDown ? 'worse' : 'better';
        case (range[3] < youVal):
            return keyIsGoodDown ? 'worst' : 'best';
        default:
            return 'n/a'
    }
}

const getRangeValue = (you, peer, key = null, parentKey = null) => {
    let youVal = you;
    let avgVal = peer;
    let range = [(peer * 0.5), (peer * 0.75), (peer * 1.25), (peer * 1.5)].sort(function (a, b) { return a - b });
    // Common sized values
    const goodUpArrowKeys = ['total_revenue', 'gross_profit', 'operating_profit', 'operating_expenses_net_income', 'total_current_assets', 'total_fixed_assets', 'total_assets', 'under_billings', 'prepaid_and_other_current_assets', 'property_and_equipment', 'other_long_term_assets', 'net_operating_activities', 'net_investing_activities', 'net_financing_activities', 'net_change_cash', 'cash', 'operating_cash_flow', 'capital_expenditure', 'free_cash_flow', 'equity', 'revenue_per_customer', 'gross_profit_per_customer', 'operating_expenses_net_income_per_customer', 'customer_roi', 'number_of_new_customers_needed_to_maintain_growth'];
    const goodUpBalanceSheetParents = ['total_current_assets', 'total_fixed_assets', 'total_assets'];
    const ingoreArrowKeys = ['net_worth', 'total_liabilities_and_equity', 'ebitda'];

    let keyIsGoodUp = goodUpArrowKeys.includes(key);
    if (goodUpBalanceSheetParents.includes(parentKey) || !key) {
        keyIsGoodUp = true;
    }
    if (ingoreArrowKeys.includes(key) || ingoreArrowKeys.includes(parentKey)) {
        return 'maintain';
    }

    switch (true) {
        case (youVal < range[0]):
            return keyIsGoodUp ? 'worst' : 'best';
        case (range[0] < youVal && youVal < range[1]):
            return keyIsGoodUp ? 'worse' : 'better';
        case (range[1] < youVal && youVal < range[2]):
            return 'maintain';
        case (range[2] < youVal && youVal < range[3]):
            return keyIsGoodUp ? 'better' : 'worse';
        case (range[3] < youVal):
            return keyIsGoodUp ? 'best' : 'worst';
        default:
            return 'n/a'
    }
}

const specialImpactCalcs = (key, values) => {
    let finalValue = null;
    if (key === 'total_revenue') {
        if (values['prevRev']) {
            finalValue = (values['currentRev'] / values['prevRev']) - 1;
        }
    }
    if (key === 'gross_profit') {
        if (values['currentRev']) {
            finalValue = (values['currentRev'] - values['cogs']) / values['currentRev'];
        }
    }
    if (key === 'operating_expenses_net_income') {
        if (values['currentRev']) {
            finalValue = values['netIncome'] / values['currentRev'];
        }
    }

    if (key === 'inventory') {
        let denominator = ((currentInventory + previousInventory) / 2);
        if (denominator) {
            finalValue = materialCosts / denominator;
        }

    }
    return finalValue;
}

const buildImpactBox = (metric, sheet, displayColumnsBy, reportPeriod, compareWith, year, yearRange, calcs, previousYearCalcs, yoy_calcs, previous_yoy_calcs, calcsAvgType = null, calcs_3_years_back = null, monthlyCompareWithTitle = null, forecastVals = null) => {
    // Need 'you' value of selected metric
    // Also the difference in percentage with you and peers common size
    let youValue, compareValue = null;
    let youAvg, compareAvg = null;
    let impactValue, impactValueFormatted = null;
    let deltaValue, deltaCommonSize = null;
    let status, impactText, copy = null;
    const sum = (a, b) => a + b;
    const specialKeys = ['total_revenue', 'gross_profit', 'operating_expenses_net_income', 'inventory', 'inventory_turnover_ratio', 'accounts_receivable', 'accounts_payable', 'ar_days', 'ap_days']
    const specialCashFlowKeys = ['net_operating_activities', 'net_investing_activities', 'net_financing_activities', 'net_change_cash', 'operating_cash_flow', 'capital_expenditure', 'free_cash_flow']
    const peopleKpiMoneyKeys = ['total_revenue_per_employee', 'operating_expenses_net_income_per_employee', 'average_wages_and_salaries', 'average_benefits', 'cost_of_turnover', 'number_of_nurses', 'number_of_assistants', 'number_of_office_employees', 'number_of_doctors', 'number_of_hygienists', 'number_of_dentists']
    const peopleKpiT12 = ['total_revenue_per_employee', 'operating_expenses_net_income_per_employee', 'average_wages_and_salaries', 'average_benefits', 'cost_of_turnover', 'number_of_employees_who_left', 'sum_compensation_growth_rate', 'sum_compensation_per_employee', 'sum_health_insurance_per_employee', 'sum_payroll_per_employee', 'sum_retirement_per_employee', 'sum_subcontractors_growth_rate', 'sum_taxes_and_benefits_growth_rate', 'sum_taxes_and_benefits_per_employee', 'total_number_of_employees', 'training_per_employee', 'turnover_rate']
    const peoplePercentKeys = ['sum_compensation_growth_rate', 'sum_taxes_and_benefits_growth_rate', 'sum_subcontractors_growth_rate']
    const analysisPerCustomer = ['revenue_per_customer', 'gross_profit_per_customer', 'operating_expenses_net_income_per_customer']
    const customersPercentKeys = ['percent_of_sales_from_the_top_10_percent', 'percent_of_sales_from_the_top_25_percent', 'percent_of_sales_from_the_top_50_percent']
    let compareWord = '';
    let compareAvgAnnual = {}
    if (compareWith === 'Peers' || monthlyCompareWithTitle === 'Peers') {
        compareWord = 'peers'
        compareAvgAnnual = calcs['avg']
    } else if (compareWith === 'Practice') {
        compareWord = 'practice'
        compareAvgAnnual = calcs['practice_avg']
    } else if (compareWith === 'NSCHBC') {
        compareWord = 'NSCHBC peers'
        compareAvgAnnual = calcs['nschbc']
    } else if (compareWith === 'YOY') {
        compareWord = 'YOY'
    } else if (monthlyCompareWithTitle) {
        compareWord = monthlyCompareWithTitle
    }

    if (specialKeys.includes(metric.key)) {
        if (displayColumnsBy === 'Years' && reportPeriod !== 'Year to Date') {
            let key = metric.key;
            if (metric.key === 'total_revenue') {
                youAvg = calcs['you']['total_revenue_growth_rate'] ? calcs['you']['total_revenue_growth_rate'][year] : 0;
                compareAvg = compareAvgAnnual['total_revenue_growth_rate'] ? compareAvgAnnual['total_revenue_growth_rate'][year] : 0;
                deltaValue = Math.abs((youAvg * 100) - (compareAvg * 100))
                deltaCommonSize = formatValue(deltaValue, 'percent');
                impactValue = calcs['you']['total_revenue'][year - 1] * (youAvg - compareAvg)
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                status = getRangeValue(youAvg, compareAvg, metric.key)
            }
            if (metric.key === 'gross_profit') {
                youAvg = calcs['you']['gross_margin'] ? calcs['you']['gross_margin'][year] : 0
                compareAvg = compareAvgAnnual['gross_margin'] ? compareAvgAnnual['gross_margin'][year] : 0;
                deltaValue = Math.abs((youAvg * 100) - (compareAvg * 100))
                deltaCommonSize = formatValue(deltaValue, 'percent');
                impactValue = calcs['you']['total_revenue'][year] * (youAvg - compareAvg)
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                status = getRangeValue(youAvg, compareAvg, metric.key)
            }
            if (metric.key === 'operating_expenses_net_income') {
                youAvg = calcs['you']['profit_margin'] ? calcs['you']['profit_margin'][year] : 0;
                compareAvg = compareAvgAnnual['profit_margin'] ? compareAvgAnnual['profit_margin'][year] : 0;
                deltaValue = Math.abs((youAvg * 100) - (compareAvg * 100))
                deltaCommonSize = formatValue(deltaValue, 'percent');
                impactValue = calcs['you']['total_revenue'][year] * (youAvg - compareAvg)
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                status = getRangeValue(youAvg, compareAvg, metric.key)
            }
            if (metric.key === 'inventory' || metric.key === 'inventory_turnover_ratio') {
                key = 'inventory'
                youAvg = calcs['you']['inventory_turnover_ratio'] ? calcs['you']['inventory_turnover_ratio'][year] : 0;
                compareAvg = compareAvgAnnual['inventory_turnover_ratio'] ? compareAvgAnnual['inventory_turnover_ratio'][year] : 0;
                deltaValue = Math.abs(youAvg - compareAvg)
                deltaCommonSize = formatValue(deltaValue, 'decimal');
                let youInventory = calcs['you']['inventory'] ? calcs['you']['inventory'][year] : 0
                impactValue = ((360 / compareAvg) * (youInventory / (360 / youAvg))) - youInventory;
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                status = getRangeValueKFI(youAvg, compareAvg, 'inventory_turnover_ratio')
            }

            if (metric.key === 'accounts_receivable' || metric.key === 'ar_days') {
                key = 'accounts_receivable'
                youAvg = calcs['you']['ar_days'] ? calcs['you']['ar_days'][year] : 0;
                compareAvg = compareAvgAnnual['ar_days'] ? compareAvgAnnual['ar_days'][year] : 0;
                deltaValue = Math.abs(youAvg - compareAvg)
                deltaCommonSize = formatValue(deltaValue, 'days');
                let accountsReceivable = calcs['you']['accounts_receivable'] ? calcs['you']['accounts_receivable'][year] : 0;
                impactValue = accountsReceivable - (compareAvg * (calcs['you']['total_revenue'][year] / 360));
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                status = getRangeValueKFI(youAvg, compareAvg, 'ar_days')
            }

            if (metric.key === 'accounts_payable' || metric.key === 'ap_days') {
                key = 'accounts_payable'
                youAvg = calcs['you']['ap_days'] ? calcs['you']['ap_days'][year] : 0;
                compareAvg = compareAvgAnnual['ap_days'] ? compareAvgAnnual['ap_days'][year] : 0;
                deltaValue = Math.abs(youAvg - compareAvg)
                deltaCommonSize = formatValue(deltaValue, 'days');
                let accountsPayable = calcs['you']['accounts_payable'] ? calcs['you']['accounts_payable'][year] : 0;
                impactValue = accountsPayable - ((accountsPayable / youAvg) * compareAvg);
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                status = getRangeValueKFI(youAvg, compareAvg, 'ap_days')
            }
            impactText = moneyText[key].texts(status, impactValueFormatted, deltaCommonSize, compareWord)
            copy = moneyText[key]['copy']
        }
        if (displayColumnsBy === 'Months' || displayColumnsBy === 'Years' && reportPeriod === 'Year to Date') {
            let key = metric.key;
            let avgExists, you, peer, previousYou, previousPeer, calcsUsed, useableMonths;
            const yoy = yoy_calcs.you || {};
            const previousYOY = previous_yoy_calcs.you || {};
            let numberOfMonths = 0;
            if (reportPeriod === 'Year to Date') {
                let calcYears = Object.keys(calcs);
                let currentYear = calcYears[1];
                let previousYear = calcYears[0];
                avgExists = Object.keys(calcs[currentYear].avg).length > 0 ? true : false;
                you = calcs[currentYear].you || {};
                peer = calcs[currentYear].avg || {};
                previousYou = calcs[previousYear].you || {};
                previousPeer = calcs[previousYear].avg || {};
            } else {
                avgExists = Object.keys(calcs.avg).length > 0 ? true : false;
                you = calcs.you || {};
                peer = calcs.avg || {};
                previousYou = previousYearCalcs.you || {};
                previousPeer = previousYearCalcs.avg || {};
                useableMonths = Object.values(you['cash'])
                useableMonths.forEach(thing => thing !== null ? numberOfMonths += 1 : null)
            }

            let compare = null;
            let previousCompare = null;

            if (compareWith === 'Peers' || compareWith === 'Default:Peers') {
                compare = peer
                previousCompare = previousPeer
            } else if (compareWith === 'YOY' || compareWith === 'Default:YOY') {
                compare = yoy
                previousCompare = previousYOY
            } else if (compareWith.includes('Forecast')) {
                compare = forecastVals
                previousCompare = previousYou
            }

            if (metric.key === 'total_revenue') {
                let youCurrentTotalRev = you.hasOwnProperty('total_revenue') ? Object.values(you['total_revenue']).reduce(sum) : null;
                let youPreviousTotalRev = previousYou.hasOwnProperty('total_revenue') ? Object.values(previousYou['total_revenue']).reduce(sum) : null;
                let compareCurrentTotalRev = compare.hasOwnProperty('total_revenue') ? Object.values(compare['total_revenue']).slice(0, numberOfMonths).reduce(sum) : null;
                let comparePreviousTotalRev = previousCompare.hasOwnProperty('total_revenue') ? Object.values(previousCompare['total_revenue']).slice(0, numberOfMonths).reduce(sum) : null;
                let youFinalValue = specialImpactCalcs('total_revenue', { currentRev: youCurrentTotalRev, prevRev: youPreviousTotalRev });
                let compareFinalValue = specialImpactCalcs('total_revenue', { currentRev: compareCurrentTotalRev, prevRev: comparePreviousTotalRev });

                let deltaValue = Math.abs((youFinalValue * 100) - (compareFinalValue * 100));
                let impactValue = youPreviousTotalRev ? youPreviousTotalRev * (youFinalValue - compareFinalValue) : 0
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                deltaCommonSize = formatValue(deltaValue, 'percent');
                status = getRangeValue(youFinalValue, compareFinalValue, metric.key);
            }

            if (metric.key === 'gross_profit') {
                let youCurrentTotalRev = you.hasOwnProperty('total_revenue') ? Object.values(you['total_revenue']).reduce(sum) : null;
                let compareCurrentTotalRev = compare.hasOwnProperty('total_revenue') ? Object.values(compare['total_revenue']).slice(0, numberOfMonths).reduce(sum) : null;
                let youCOGS = you.hasOwnProperty('total_costs') ? Object.values(you['total_costs']).reduce(sum) : null;
                let compareCOGS = compare.hasOwnProperty('total_costs') ? Object.values(compare['total_costs']).slice(0, numberOfMonths).reduce(sum) : null;
                let youFinalValue = specialImpactCalcs('gross_profit', { currentRev: youCurrentTotalRev, cogs: youCOGS });
                let compareFinalValue = specialImpactCalcs('gross_profit', { currentRev: compareCurrentTotalRev, cogs: compareCOGS });

                let deltaValue = Math.abs((youFinalValue * 100) - (compareFinalValue * 100));
                let impactValue = youCurrentTotalRev ? youCurrentTotalRev * (youFinalValue - compareFinalValue) : 0
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                deltaCommonSize = formatValue(deltaValue, 'percent');
                status = getRangeValue(youFinalValue, compareFinalValue, metric.key);
            }

            if (metric.key === 'operating_expenses_net_income') {
                let youCurrentTotalRev = you.hasOwnProperty('total_revenue') ? Object.values(you['total_revenue']).reduce(sum) : null;
                let compareCurrentTotalRev = compare.hasOwnProperty('total_revenue') ? Object.values(compare['total_revenue']).slice(0, numberOfMonths).reduce(sum) : null;
                let youCurrentNetIncome = you.hasOwnProperty('operating_expenses_net_income') ? Object.values(you['operating_expenses_net_income']).reduce(sum) : null;
                let compareCurrentNetIncome = compare.hasOwnProperty('operating_expenses_net_income') ? Object.values(compare['operating_expenses_net_income']).slice(0, numberOfMonths).reduce(sum) : null;

                let youFinalValue = specialImpactCalcs('operating_expenses_net_income', { netIncome: youCurrentNetIncome, currentRev: youCurrentTotalRev });
                let compareFinalValue = specialImpactCalcs('operating_expenses_net_income', { netIncome: compareCurrentNetIncome, currentRev: compareCurrentTotalRev });

                deltaValue = Math.abs((youFinalValue * 100) - (compareFinalValue * 100));
                impactValue = youCurrentTotalRev ? youCurrentTotalRev * (youFinalValue - compareFinalValue) : 0
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                deltaCommonSize = formatValue(deltaValue, 'percent');
                status = getRangeValue(youFinalValue, compareFinalValue, metric.key);
            }

            if (metric.key === 'inventory' || metric.key === 'inventory_turnover_ratio') {
                key = 'inventory'
                let youFinalValue, compareFinalValue, youInventory;
                if (reportPeriod === 'Year to Date') {
                    youInventory = calculateKFIMetric('inventory', you);
                    youFinalValue = calculateKFIMetric('inventory_turnover_ratio', you, previousYou);
                    compareFinalValue = calculateKFIMetric('inventory_turnover_ratio', peer, previousPeer);
                } else {
                    let newMonthlyAnalysisCalculatedValues = trailing12MonthCalculations('Inventory Turnover Ratio', you, previousYou)
                    youInventory = you.inventory[numberOfMonths];
                    let comparisonMonthlyAnalysisCalculatedValues = null;
                    if (compareWith === 'YOY' && previousYOY || compareWith === 'Default:YOY') {
                        comparisonMonthlyAnalysisCalculatedValues = trailing12MonthCalculations('Inventory Turnover Ratio', previousYou, previousYOY)
                    } else if (compareWith === 'Peers' || compareWith === 'Default:Peers') {
                        comparisonMonthlyAnalysisCalculatedValues = trailing12MonthCalculations('Inventory Turnover Ratio', peer, previousPeer)
                    } else if (compareWith.includes('Forecast')) {
                        comparisonMonthlyAnalysisCalculatedValues = trailing12MonthCalculations('Inventory Turnover Ratio', forecastVals, previousYou)
                    }
                    youFinalValue = newMonthlyAnalysisCalculatedValues ? newMonthlyAnalysisCalculatedValues[numberOfMonths] : null;
                    compareFinalValue = comparisonMonthlyAnalysisCalculatedValues ? comparisonMonthlyAnalysisCalculatedValues[numberOfMonths] : null;
                }
                deltaValue = Math.abs(youFinalValue - compareFinalValue);
                deltaCommonSize = formatValue(deltaValue, 'decimal');
                status = getRangeValueKFI(youFinalValue, compareFinalValue, 'inventory_turnover_ratio');
                impactValue = ((360 / compareFinalValue) * (youInventory / (360 / youFinalValue))) - youInventory;
                impactValueFormatted = parseMoney(Math.abs(impactValue))
            }

            if (metric.key === 'accounts_receivable' || metric.key === 'ar_days') {
                key = 'accounts_receivable'
                let youCurrentTotalRev = you.hasOwnProperty('total_revenue') ? Object.values(you['total_revenue']).reduce(sum) : null;
                let youFinalValue, compareFinalValue;
                if (reportPeriod === 'Year to Date') {
                    let youAccountsReceivable = calculateKFIMetric('accounts_receivable', you);
                    youFinalValue = calculateKFIMetric('ar_days', you);
                    compareFinalValue = calculateKFIMetric('ar_days', peer);
                    impactValue = youAccountsReceivable - (compareFinalValue * (youCurrentTotalRev / 360));
                } else {
                    let newMonthlyAnalysisCalculatedValues = trailing12MonthCalculations('Accounts Receivable Days', you, previousYou)
                    let comparisonMonthlyAnalysisCalculatedValues = null;
                    if (compareWith === 'YOY' && previousYOY || compareWith === 'Default:YOY') {
                        comparisonMonthlyAnalysisCalculatedValues = trailing12MonthCalculations('Accounts Receivable Days', previousYou, previousYOY)
                    } else if (compareWith === 'Peers' || compareWith === 'Default:Peers') {
                        comparisonMonthlyAnalysisCalculatedValues = trailing12MonthCalculations('Accounts Receivable Days', peer, previousPeer)
                    } else if (compareWith.includes('Forecast')) {
                        comparisonMonthlyAnalysisCalculatedValues = trailing12MonthCalculations('Accounts Receivable Days', forecastVals, previousYou)
                    }
                    youFinalValue = newMonthlyAnalysisCalculatedValues ? newMonthlyAnalysisCalculatedValues[numberOfMonths] : null;
                    compareFinalValue = comparisonMonthlyAnalysisCalculatedValues ? comparisonMonthlyAnalysisCalculatedValues[numberOfMonths] : null;
                    impactValue = newMonthlyAnalysisCalculatedValues - (compareFinalValue * (youCurrentTotalRev / 360));
                }
                deltaValue = Math.abs(youFinalValue - compareFinalValue);
                deltaCommonSize = formatValue(deltaValue, 'roundedDecimal');
                status = getRangeValueKFI(youFinalValue, compareFinalValue, 'ar_days');
                impactValueFormatted = parseMoney(Math.abs(impactValue))
            }

            if (metric.key === 'accounts_payable' || metric.key === 'ap_days') {
                key = 'accounts_payable'
                let youFinalValue, compareFinalValue;
                if (reportPeriod === 'Year to Date') {
                    let youAccountsPayable = calculateKFIMetric('accounts_payable', you);
                    youFinalValue = calculateKFIMetric('ap_days', you, previousYou);
                    compareFinalValue = calculateKFIMetric('ap_days', peer, previousPeer);
                    impactValue = youAccountsPayable - ((youAccountsPayable / youFinalValue) * compareFinalValue);
                } else {
                    let newMonthlyAnalysisCalculatedValues = trailing12MonthCalculations('Accounts Payable Days', you, previousYou)
                    let comparisonMonthlyAnalysisCalculatedValues = null;
                    if (compareWith === 'YOY' && previousYOY || compareWith === 'Default:YOY') {
                        comparisonMonthlyAnalysisCalculatedValues = trailing12MonthCalculations('Accounts Payable Days', previousYou, previousYOY)
                    } else if (compareWith === 'Peers' || compareWith === 'Default:Peers') {
                        comparisonMonthlyAnalysisCalculatedValues = trailing12MonthCalculations('Accounts Payable Days', peer, previousPeer)
                    } else if (compareWith.includes('Forecast')) {
                        comparisonMonthlyAnalysisCalculatedValues = trailing12MonthCalculations('Accounts Payable Days', forecastVals, previousYou)
                    }
                    youFinalValue = newMonthlyAnalysisCalculatedValues ? newMonthlyAnalysisCalculatedValues[numberOfMonths] : null;
                    compareFinalValue = comparisonMonthlyAnalysisCalculatedValues ? comparisonMonthlyAnalysisCalculatedValues[numberOfMonths] : null;
                    impactValue = newMonthlyAnalysisCalculatedValues - ((newMonthlyAnalysisCalculatedValues / youFinalValue) * compareFinalValue);
                }
                deltaValue = Math.abs(youFinalValue - compareFinalValue);
                deltaCommonSize = formatValue(deltaValue, 'decimal');
                status = getRangeValueKFI(youFinalValue, compareFinalValue, 'ap_days');
                impactValueFormatted = parseMoney(Math.abs(impactValue))
            }
            impactText = moneyText[key].texts(status, impactValueFormatted, deltaCommonSize, compareWord)
            copy = moneyText[key]['copy']
        }
    } else if (analysisPerCustomer.includes(metric.key)) {
        if (displayColumnsBy === 'Years' && reportPeriod !== 'Year to Date') {
            youAvg = calcs['you'][metric.key] && calcs['you'][metric.key][year] ? calcs['you'][metric.key][year] : 0;
            compareAvg = compareAvgAnnual[metric.key] && compareAvgAnnual[metric.key][year] ? peersAnalysisPerCustomerVals(metric.key, year, compareAvgAnnual) : 0;
            deltaValue = Math.abs(100 - ((youAvg / compareAvg) * 100))
            deltaCommonSize = formatValue(deltaValue, 'percent');
            impactValue = compareAvg - youAvg
            impactValueFormatted = parseMoney(Math.abs(impactValue))
            status = getRangeValue(youAvg, compareAvg, metric.key)
            impactText = customersText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord)
            copy = customersText[metric.key]['copy']
        }
        if (displayColumnsBy === 'Years' && reportPeriod === 'Year to Date') {
            let lastMonthNumber = 0;
            let useableMonths = Object.values(calcs[year]['you']['roa']);
            useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);
            const compareWithYTD = previousYearCalcs['monthly'][year]['avg']
            const compareWithPreviousYearCalcsYTD = previousYearCalcs['monthly'][year - 1]['avg']
            const compareWithPreviousYOYCalcsYTD = previousYearCalcs['monthly'][year - 2]['avg']

            const calcsCurrentYearYTDYou = previousYearCalcs['monthly'][year]['you']
            const previousYearCalcsYTDYou = previousYearCalcs['monthly'][year - 1]['you']
            const previous2YearsBackYTDYou = previousYearCalcs['monthly'][year - 2]['you']
            let youT12Obj = peopleCustomersT12Metrics('customers', metric.key, calcsCurrentYearYTDYou, previousYearCalcsYTDYou, previous2YearsBackYTDYou)
            let compareT12Obj = peopleCustomersT12Metrics('customers', metric.key, compareWithYTD, compareWithPreviousYearCalcsYTD, compareWithPreviousYOYCalcsYTD)
            let youAvg = isFinite(youT12Obj[metric.key][lastMonthNumber]) ? youT12Obj[metric.key][lastMonthNumber] : 0
            let compareAvg = isFinite(compareT12Obj[metric.key][lastMonthNumber]) ? compareT12Obj[metric.key][lastMonthNumber] : 0
            deltaValue = Math.abs(100 - ((youAvg / compareAvg) * 100))
            deltaCommonSize = formatValue(deltaValue, 'percent');
            impactValue = compareAvg - youAvg
            impactValueFormatted = parseMoney(Math.abs(impactValue))
            status = getRangeValue(youAvg, compareAvg, metric.key)
            impactText = customersText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord)
            copy = customersText[metric.key]['copy']
        }
        if (displayColumnsBy === 'Months') {
            let lastMonthNumber = 0;
            let useableMonths = Object.values(calcs['you']['roa']);
            useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);
            let youT12Obj = peopleCustomersT12Metrics('customers', metric.key, calcs.you, previousYearCalcs.you, previous_yoy_calcs.you)
            let compareT12Obj = null;
            if (compareWith === 'Peers' || compareWith === 'Default:Peers') {
                compareT12Obj = peopleCustomersT12Metrics('customers', metric.key, calcs.avg, previousYearCalcs.avg, previous_yoy_calcs.you)
            } else if (compareWith === 'YOY' || compareWith === 'Default:YOY') {
                compareT12Obj = peopleCustomersT12Metrics('customers', metric.key, previousYearCalcs.you, previous_yoy_calcs.you, calcs_3_years_back.you)
            }
            youAvg = isFinite(youT12Obj[metric.key][lastMonthNumber]) ? youT12Obj[metric.key][lastMonthNumber] : 0
            compareAvg = isFinite(compareT12Obj[metric.key][lastMonthNumber]) ? compareT12Obj[metric.key][lastMonthNumber] : 0
            deltaValue = Math.abs(100 - ((youAvg / compareAvg) * 100))
            deltaCommonSize = formatValue(deltaValue, 'percent');
            impactValue = compareAvg - youAvg
            impactValueFormatted = parseMoney(Math.abs(impactValue))
            status = getRangeValue(youAvg, compareAvg, metric.key)
            impactText = customersText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord)
            copy = customersText[metric.key]['copy']
        }
    } else if (metric.key === 'number_of_new_customers_needed_to_maintain_growth') {
        if (displayColumnsBy === 'Years' && reportPeriod !== 'Year to Date') {
            youAvg = calcs['you'][metric.key] && calcs['you'][metric.key][year] ? calcs['you'][metric.key][year] : 0;
            compareAvg = compareAvgAnnual[metric.key] && compareAvgAnnual[metric.key][year] ? compareAvgAnnual[metric.key][year] : 0;
            deltaValue = youAvg - compareAvg
            deltaCommonSize = formatValue(deltaValue, 'roundedDecimal');
            // impact same as delta
            impactValueFormatted = deltaCommonSize
            status = getRangeValue(youAvg, compareAvg, metric.key)
            impactText = customersText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord)
            copy = customersText[metric.key]['copy']
        }

        if (displayColumnsBy === 'Years' && reportPeriod === 'Year to Date') {
            let lastMonthNumber = 0;
            let useableMonths = Object.values(calcs[year]['you']['roa']);
            useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);
            const compareWithYTD = previousYearCalcs['monthly'][year]['avg']
            const compareWithPreviousYearCalcsYTD = previousYearCalcs['monthly'][year - 1]['avg']
            const compareWithPreviousYOYCalcsYTD = previousYearCalcs['monthly'][year - 2]['avg']

            const calcsCurrentYearYTDYou = previousYearCalcs['monthly'][year]['you']
            const previousYearCalcsYTDYou = previousYearCalcs['monthly'][year - 1]['you']
            const previous2YearsBackYTDYou = previousYearCalcs['monthly'][year - 2]['you']
            let youT12 = peopleCustomersT12Metrics('customers', metric.key, calcsCurrentYearYTDYou, previousYearCalcsYTDYou, previous2YearsBackYTDYou)
            let compareT12 = peopleCustomersT12Metrics('customers', metric.key, compareWithYTD, compareWithPreviousYearCalcsYTD, compareWithPreviousYOYCalcsYTD)
            let youAvg = isFinite(youT12[metric.key][lastMonthNumber]) ? youT12[metric.key][lastMonthNumber] : 0
            let compareAvg = isFinite(compareT12[metric.key][lastMonthNumber]) ? compareT12[metric.key][lastMonthNumber] : 0
            deltaValue = youAvg - compareAvg
            deltaCommonSize = formatValue(deltaValue, 'roundedDecimal');
            // impact same as delta
            impactValueFormatted = deltaCommonSize
            status = getRangeValue(youAvg, compareAvg, metric.key)
            impactText = customersText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord)
            copy = customersText[metric.key]['copy']
        }

        if (displayColumnsBy === 'Months') {
            let lastMonthNumber = 0;
            let useableMonths = Object.values(calcs['you']['roa']);
            useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);
            let youT12Obj = peopleCustomersT12Metrics('customers', metric.key, calcs.you, previousYearCalcs.you, previous_yoy_calcs.you)
            let compareT12Obj = null;
            if (compareWith === 'Peers' || compareWith === 'Default:Peers') {
                compareT12Obj = peopleCustomersT12Metrics('customers', metric.key, calcs.avg, previousYearCalcs.avg, previous_yoy_calcs.you)
            } else if (compareWith === 'YOY' || compareWith === 'Default:YOY') {
                compareT12Obj = peopleCustomersT12Metrics('customers', metric.key, previousYearCalcs.you, previous_yoy_calcs.you, calcs_3_years_back.you)
            }
            youAvg = isFinite(youT12Obj[metric.key][lastMonthNumber]) ? youT12Obj[metric.key][lastMonthNumber] : 0
            compareAvg = isFinite(compareT12Obj[metric.key][lastMonthNumber]) ? compareT12Obj[metric.key][lastMonthNumber] : 0
            deltaValue = youAvg - compareAvg
            deltaCommonSize = formatValue(deltaValue, 'roundedDecimal');
            // impact same as delta
            impactValueFormatted = deltaCommonSize
            status = getRangeValue(youAvg, compareAvg, metric.key)
            impactText = customersText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord)
            copy = customersText[metric.key]['copy']
        }
    } else if (metric.key === 'total_customer_costs_common_size_revenue' || metric.key === 'customer_roi') {
        if (displayColumnsBy === 'Years' && reportPeriod !== 'Year to Date') {
            youAvg = calcs['you'][metric.key] && calcs['you'][metric.key][year] ? calcs['you'][metric.key][year] : 0;
            compareAvg = compareAvgAnnual[metric.key] && compareAvgAnnual[metric.key][year] ? compareAvgAnnual[metric.key][year] : 0;
            deltaValue = metric.key === 'total_customer_costs_common_size_revenue' ? (youAvg - compareAvg) * 100 : youAvg - compareAvg
            deltaCommonSize = formatValue(deltaValue, 'percent');
            // impact same as delta
            impactValueFormatted = deltaCommonSize
            status = getRangeValue(youAvg, compareAvg, metric.key)
            impactText = customersText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord)
            copy = customersText[metric.key]['copy']
        }

        if (displayColumnsBy === 'Years' && reportPeriod === 'Year to Date') {
            let lastMonthNumber = 0;
            let useableMonths = Object.values(calcs[year]['you']['roa']);
            useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);
            const compareWithYTD = previousYearCalcs['monthly'][year]['avg']
            const compareWithPreviousYearCalcsYTD = previousYearCalcs['monthly'][year - 1]['avg']
            const compareWithPreviousYOYCalcsYTD = previousYearCalcs['monthly'][year - 2]['avg']

            const calcsCurrentYearYTDYou = previousYearCalcs['monthly'][year]['you']
            const previousYearCalcsYTDYou = previousYearCalcs['monthly'][year - 1]['you']
            const previous2YearsBackYTDYou = previousYearCalcs['monthly'][year - 2]['you']
            let youT12 = peopleCustomersT12Metrics('customers', metric.key, calcsCurrentYearYTDYou, previousYearCalcsYTDYou, previous2YearsBackYTDYou)
            let compareT12 = peopleCustomersT12Metrics('customers', metric.key, compareWithYTD, compareWithPreviousYearCalcsYTD, compareWithPreviousYOYCalcsYTD)
            let youAvg = isFinite(youT12[metric.key][lastMonthNumber]) ? youT12[metric.key][lastMonthNumber] : 0
            let compareAvg = isFinite(compareT12[metric.key][lastMonthNumber]) ? compareT12[metric.key][lastMonthNumber] : 0
            deltaValue = (youAvg - compareAvg) * 100
            deltaCommonSize = formatValue(deltaValue, 'percent');
            // impact same as delta
            impactValueFormatted = deltaCommonSize
            status = getRangeValue(youAvg, compareAvg, metric.key)
            impactText = customersText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord)
            copy = customersText[metric.key]['copy']
        }

        if (displayColumnsBy === 'Months') {
            let lastMonthNumber = 0;
            let useableMonths = Object.values(calcs['you']['roa']);
            useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);
            let youT12Obj = peopleCustomersT12Metrics('customers', metric.key, calcs.you, previousYearCalcs.you, previous_yoy_calcs.you)
            let compareT12Obj = null;
            if (compareWith === 'Peers' || compareWith === 'Default:Peers') {
                compareT12Obj = peopleCustomersT12Metrics('customers', metric.key, calcs.avg, previousYearCalcs.avg, previous_yoy_calcs.you)
            } else if (compareWith === 'YOY' || compareWith === 'Default:YOY') {
                compareT12Obj = peopleCustomersT12Metrics('customers', metric.key, previousYearCalcs.you, previous_yoy_calcs.you, calcs_3_years_back.you)
            }
            youAvg = isFinite(youT12Obj[metric.key][lastMonthNumber]) ? youT12Obj[metric.key][lastMonthNumber] : 0
            compareAvg = isFinite(compareT12Obj[metric.key][lastMonthNumber]) ? compareT12Obj[metric.key][lastMonthNumber] : 0
            deltaValue = (youAvg - compareAvg) * 100
            deltaCommonSize = formatValue(deltaValue, 'percent');
            // impact same as delta
            impactValueFormatted = deltaCommonSize
            status = getRangeValue(youAvg, compareAvg, metric.key)
            impactText = customersText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord)
            copy = customersText[metric.key]['copy']
        }
    } else if (customersPercentKeys.includes(metric.key)) {
        if (displayColumnsBy === 'Years' && reportPeriod !== 'Year to Date') {
            youAvg = calcs['you'][metric.key] && calcs['you'][metric.key][year] ? calcs['you'][metric.key][year] : 0;
            compareAvg = compareAvgAnnual[metric.key] && compareAvgAnnual[metric.key][year] ? compareAvgAnnual[metric.key][year] : 0;
            let youTotalRevVal = calcs['you']['total_revenue'] ? calcs['you']['total_revenue'][year] : 0;
            let compareTotalRev = compareAvgAnnual['total_revenue'] ? compareAvgAnnual['total_revenue'][year] : 0;
            deltaValue = Math.abs(compareAvg - youAvg)
            deltaCommonSize = deltaValue.toFixed(1) + '%'
            impactValue = ((compareAvg / 100) * compareTotalRev) - ((youAvg / 100) * youTotalRevVal)
            impactValueFormatted = parseMoney(Math.abs(impactValue))
            status = getRangeValue(youAvg, compareAvg, metric.key)
            impactText = customersText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord)
            copy = customersText[metric.key]['copy']
        } else {
            // Percent of Sales only in Yearly/Annual Data
            deltaCommonSize = '0%'
            impactValueFormatted = '0%'
            impactText = customersText[metric.key].texts('maintain', impactValueFormatted, deltaCommonSize, compareWord)
            copy = customersText[metric.key]['copy']
        }
    } else {
        if (sheet === 'kpi_people') {
            if (displayColumnsBy === 'Years' && reportPeriod !== 'Year to Date') {

                if (peopleKpiMoneyKeys.includes(metric.key)) {
                    youAvg = calcs['you'][metric.key] && calcs['you'][metric.key][year] ? calcs['you'][metric.key][year] : 0;
                    compareAvg = compareAvgAnnual[metric.key] && compareAvgAnnual[metric.key][year] ? compareAvgAnnual[metric.key][year] : 0;
                    deltaValue = Math.abs(100 - ((youAvg / compareAvg) * 100))
                    deltaCommonSize = formatValue(deltaValue, metric.format);
                    impactValue = Math.abs(youAvg - compareAvg)
                    impactValueFormatted = parseMoney(Math.abs(impactValue))
                    status = getRangeValue(youAvg * 100, compareAvg * 100, metric.key, metric.parentKey);
                } else if (peoplePercentKeys.includes(metric.key)) {
                    let youTotalRevVal = calcs['you']['total_revenue'] ? calcs['you']['total_revenue'][year - 1] : 0;
                    youAvg = calcs['you'][metric.key] && calcs['you'][metric.key][year] ? calcs['you'][metric.key][year] : 0;
                    compareAvg = compareAvgAnnual[metric.key] && compareAvgAnnual[metric.key][year] ? compareAvgAnnual[metric.key][year] : 0;
                    deltaValue = Math.abs(youAvg - compareAvg)
                    deltaCommonSize = formatValue(deltaValue, metric.format);
                    impactValue = Math.abs(youTotalRevVal * (deltaValue / 100))
                    impactValueFormatted = parseMoney(Math.abs(impactValue))
                    status = getRangeValue(youAvg / 100, compareAvg / 100, metric.key, metric.parentKey);
                } else if (metric.key === 'turnover_rate') {
                    youAvg = calcs['you'][metric.key] && calcs['you'][metric.key][year] ? calcs['you'][metric.key][year] : 0;
                    compareAvg = compareAvgAnnual[metric.key] && compareAvgAnnual[metric.key][year] ? compareAvgAnnual[metric.key][year] : 0;
                    deltaValue = Math.abs(youAvg - compareAvg)
                    deltaCommonSize = formatValue(deltaValue, metric.format);
                    // no impact values for turnover_rate
                    impactValueFormatted = 0
                    status = getRangeValue(youAvg / 100, compareAvg / 100, metric.key, metric.parentKey)
                } else if (metric.key === 'cost_of_turnover') {
                    // cost_of_turnover is only displayed for Years / Annual Data
                    const lostProductivity = .2;
                    const lostProductivityTime = .125;
                    const lostWagesTime = .125;
                    const employeeProductivityDecline = .5;
                    const employeeProductivityDeclineTime = .185;

                    if (calcs['you']['turnover_rate'][year] !== 0 && calcs['you']['total_number_of_employees'][year] !== 0 && calcs['you']['revenue_per_employee'][year] !== 0 && calcs['you']['average_wages_and_salaries'][year] !== 0) {
                        youAvg = ((calcs['you']['turnover_rate'][year] - compareAvgAnnual['turnover_rate'][year])
                            * (calcs['you']['total_number_of_employees'][year]
                                * ((calcs['you']['revenue_per_employee'][year] * lostProductivity * lostProductivityTime)
                                    + (calcs['you']['average_wages_and_salaries'][year] * lostWagesTime)
                                    + ((calcs['you']['training'][year] * .25) / calcs['you']['total_number_of_employees'][year])
                                    + (calcs['you']['revenue_per_employee'][year] * employeeProductivityDecline * employeeProductivityDeclineTime))));
                    } else {
                        youAvg = 0;
                    }

                    if (compareAvgAnnual['turnover_rate'][year] !== 0 && compareAvgAnnual['total_number_of_employees'][year] !== 0 && compareAvgAnnual['revenue_per_employee'][year] !== 0 && compareAvgAnnual['average_wages_and_salaries'][year] !== 0) {
                        compareAvg = ((compareAvgAnnual['turnover_rate'][year] - calcs['you']['turnover_rate'][year])
                            * (compareAvgAnnual['total_number_of_employees'][year]
                                * ((compareAvgAnnual['revenue_per_employee'][year] * lostProductivity * lostProductivityTime)
                                    + (compareAvgAnnual['average_wages_and_salaries'][year] * lostWagesTime)
                                    + ((compareAvgAnnual['training'][year] * .25) / compareAvgAnnual['total_number_of_employees'][year])
                                    + (compareAvgAnnual['revenue_per_employee'][year] * employeeProductivityDecline * employeeProductivityDeclineTime))));
                    } else {
                        compareAvg = 0;
                    }

                    deltaValue = Math.abs(youAvg - compareAvg)
                    deltaCommonSize = formatValue(deltaValue, metric.format);
                    impactValueFormatted = 0
                    status = getRangeValue(youAvg, compareAvg, metric.key, metric.parentKey)
                }
            }

            if (displayColumnsBy === 'Months') {
                if (peopleKpiT12.includes(metric.key)) {
                    let lastMonthNumber = 0;
                    let useableMonths = Object.values(calcs['you']['roa']);
                    useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);
                    let youT12Obj = peopleCustomersT12Metrics('people', metric.key, calcs.you, previousYearCalcs.you, previous_yoy_calcs.you)
                    let compareT12Obj = null;
                    if (compareWith === 'Peers' || compareWith === 'Default:Peers') {
                        compareT12Obj = peopleCustomersT12Metrics('people', metric.key, calcs.avg, previousYearCalcs.avg, previous_yoy_calcs.you)
                    } else if (compareWith === 'YOY' || compareWith === 'Default:YOY') {
                        compareT12Obj = peopleCustomersT12Metrics('people', metric.key, previousYearCalcs.you, previous_yoy_calcs.you, calcs_3_years_back.you)
                    }
                    youAvg = isFinite(youT12Obj[metric.key][lastMonthNumber]) ? youT12Obj[metric.key][lastMonthNumber] : 0
                    compareAvg = isFinite(compareT12Obj[metric.key][lastMonthNumber]) ? compareT12Obj[metric.key][lastMonthNumber] : 0
                    deltaValue = (youAvg - compareAvg) * 100
                    deltaCommonSize = formatValue(deltaValue, 'percent');
                    // impact same as delta
                    impactValueFormatted = deltaCommonSize
                    status = getRangeValue(youAvg, compareAvg, metric.key)
                }
            }

            if (reportPeriod === 'Year to Date') {
                if (peopleKpiT12.includes(metric.key)) {
                    let lastMonthNumber = 0;
                    let useableMonths = Object.values(calcs[year]['you']['roa']);
                    useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);
                    const compareWithYTD = previousYearCalcs['monthly'][year]['avg']
                    const compareWithPreviousYearCalcsYTD = previousYearCalcs['monthly'][year - 1]['avg']
                    const compareWithPreviousYOYCalcsYTD = previousYearCalcs['monthly'][year - 2]['avg']

                    const calcsCurrentYearYTDYou = previousYearCalcs['monthly'][year]['you']
                    const previousYearCalcsYTDYou = previousYearCalcs['monthly'][year - 1]['you']
                    const previous2YearsBackYTDYou = previousYearCalcs['monthly'][year - 2]['you']
                    let youT12 = peopleCustomersT12Metrics('people', metric.key, calcsCurrentYearYTDYou, previousYearCalcsYTDYou, previous2YearsBackYTDYou)
                    let compareT12 = peopleCustomersT12Metrics('people', metric.key, compareWithYTD, compareWithPreviousYearCalcsYTD, compareWithPreviousYOYCalcsYTD)
                    let youAvg = isFinite(youT12[metric.key][lastMonthNumber]) ? youT12[metric.key][lastMonthNumber] : 0
                    let compareAvg = isFinite(compareT12[metric.key][lastMonthNumber]) ? compareT12[metric.key][lastMonthNumber] : 0
                    deltaValue = (youAvg - compareAvg) * 100
                    deltaCommonSize = formatValue(deltaValue, 'percent');
                    // impact same as delta
                    impactValueFormatted = deltaCommonSize
                    status = getRangeValue(youAvg, compareAvg, metric.key)
                }
            }
            impactText = peopleText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord)
            copy = peopleText[metric.key]['copy']
        }

        if (sheet === 'pnl' || sheet === 'financial_detail' || sheet === 'kpi') {
            // PNL impactValue = 'you' total_revenue * (you commonsize % - avg or compare commonsize %) 
            if (displayColumnsBy === 'Years' && reportPeriod !== 'Year to Date') {
                const youTotalRevVal = calcs['you']['total_revenue'][year]
                youAvg = calcs['you'][metric.key + '_common_size_revenue'] ? calcs['you'][metric.key + '_common_size_revenue'][year] : null;
                compareAvg = compareAvgAnnual[metric.key + '_common_size_revenue'] ? compareAvgAnnual[metric.key + '_common_size_revenue'][year] : null;
                deltaValue = Math.abs((youAvg * 100) - (compareAvg * 100))
                deltaCommonSize = formatValue(deltaValue, metric.format);
                impactValue = youTotalRevVal * (youAvg - compareAvg)
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                status = getRangeValue(youAvg * 100, compareAvg * 100, metric.key, metric.parentKey);
            }

            if (displayColumnsBy === 'Months') {
                let monthsAvailable = 0
                let useableMonths = Object.values(calcs['you']['cash'])
                useableMonths.forEach(thing => thing !== null ? monthsAvailable += 1 : null)
                const you = calcs['you'] ? calcs['you'][metric.key] : undefined;
                const avg = calcs['avg'] ? calcs['avg'][metric.key] : undefined;
                const yoy = yoy_calcs['you'] ? yoy_calcs['you'][metric.key] : undefined;
                const youTotalRevenue = calcs['you'] ? calcs['you']['total_revenue'] : undefined;
                const youTotalRevVals = MonthlyPNLandBalValues(monthsAvailable, sheet, 'total_revenue', youTotalRevenue, calcs['you']);
                const youTotalRevVal = youTotalRevVals.reduce((a, b) => a + b, 0)
                let youValArr = MonthlyPNLandBalValues(monthsAvailable, sheet, metric.key, you, calcs['you'])
                youAvg = MonthlyPNLandBalCommonSized(sheet, youValArr, calcs['you'])
                let compareValArr = null;

                if (compareWith === 'Peers' || compareWith === 'Default:Peers') {
                    compareValArr = MonthlyPNLandBalValues(monthsAvailable, sheet, metric.key, avg, calcs['avg'])
                    compareAvg = MonthlyPNLandBalCommonSized(sheet, compareValArr, calcs['avg'])
                } else if (compareWith === 'YOY' || compareWith === 'Default:YOY') {
                    compareValArr =  MonthlyPNLandBalValues(monthsAvailable, sheet, metric.key, yoy, yoy_calcs['you'])
                    compareAvg = MonthlyPNLandBalCommonSized(sheet, compareValArr, yoy_calcs['you'])
                } else if (compareWith.includes('Forecast')) {
                    const forecast = forecastVals ? forecastVals[metric.key] : undefined;
                    compareValArr = MonthlyPNLandBalValues(monthsAvailable, sheet, metric.key, forecast, forecastVals)
                    compareAvg = MonthlyPNLandBalCommonSized(sheet, compareValArr, forecastVals)
                }
                
                deltaValue = Math.abs((youAvg * 100) - (compareAvg * 100))
                deltaCommonSize = formatValue(deltaValue, 'percent')
                impactValue = youTotalRevVal * (youAvg - compareAvg)
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                status = getRangeValue(youAvg * 100, compareAvg * 100, metric.key, metric.parentKey);
            }

            if (reportPeriod === 'Year to Date') {
                const currentYearNonMonthlyYTDYou = calcs[year] ? calcs[year]['you'][metric.key] : undefined;
                const currentYearNonMonthlyYTDPeers = calcs[year] ? calcs[year]['avg'][metric.key] : undefined;
                const youTotalRevVals = calcs[year] ? calcs[year]['you']['total_revenue'] : undefined;
                const youTotalRevVal = YTDPnlandBalValues(sheet, 'total_revenue', youTotalRevVals, calcs[year]['you'])
                youValue = YTDPnlandBalValues(sheet, metric.key, currentYearNonMonthlyYTDYou, calcs[year]['you'])
                compareValue = YTDPnlandBalValues(sheet, metric.key, currentYearNonMonthlyYTDPeers, calcs[year]['avg'])
                youAvg = YTDPnlandBalCommonSizeValue(youValue, sheet, calcs[year]['you'])
                compareAvg = YTDPnlandBalCommonSizeValue(compareValue, sheet, calcs[year]['avg'])
                deltaValue = Math.abs((youAvg * 100) - (compareAvg * 100))
                deltaCommonSize = formatValue(deltaValue, 'percent')
                impactValue = youTotalRevVal * (youAvg - compareAvg)
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                status = getRangeValue(youAvg * 100, compareAvg * 100, metric.key, metric.parentKey);
            }
            impactText = moneyText[metric.key] ? moneyText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord) : null
            copy = moneyText[metric.key] ? moneyText[metric.key]['copy'] : null
        }

        if (sheet === 'balance_sheet' || sheet === 'cash_flow' && !specialCashFlowKeys.includes(metric.key)) {
            // Balance Sheet use 'you' total_assets * (you commonsize % - avg or compare commonsize %) = impactValue
            if (displayColumnsBy === 'Years' && reportPeriod !== 'Year to Date') {
                const youTotalAssetsVal = calcs['you']['total_assets'][year]
                youAvg = calcs['you'][metric.key + '_common_size_assets'] ? calcs['you'][metric.key + '_common_size_assets'][year] : null;
                compareAvg = compareAvgAnnual[metric.key + '_common_size_assets'] ? compareAvgAnnual[metric.key + '_common_size_assets'][year] : null;
                deltaValue = Math.abs((youAvg * 100) - (compareAvg * 100))
                deltaCommonSize = formatValue(deltaValue, metric.format);
                impactValue = youTotalAssetsVal * (youAvg - compareAvg)
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                status = getRangeValue(youAvg * 100, compareAvg * 100, metric.key, metric.parentKey);
            }

            if (displayColumnsBy === 'Months') {
                let monthsAvailable = 0
                let useableMonths = Object.values(calcs['you']['cash'])
                useableMonths.forEach(thing => thing !== null ? monthsAvailable += 1 : null)
                const you = calcs['you'] ? calcs['you'][metric.key] : undefined;
                const avg = calcs['avg'] ? calcs['avg'][metric.key] : undefined;
                const yoy = yoy_calcs['you'] ? yoy_calcs['you'][metric.key] : undefined;
                const youTotalAssetsVal = calcs['you']['total_assets'][monthsAvailable]
                let youValArr = MonthlyPNLandBalValues(monthsAvailable, sheet, metric.key, you, calcs['you'])
                youAvg = MonthlyPNLandBalCommonSized(sheet, youValArr, calcs['you'])
                let compareValArr = null;

                if (compareWith === 'Peers' || compareWith === 'Default:Peers') {
                    compareValArr = MonthlyPNLandBalValues(monthsAvailable, sheet, metric.key, avg, calcs['avg'])
                    compareAvg = MonthlyPNLandBalCommonSized(sheet, compareValArr, calcs['avg'])
                } else if (compareWith === 'YOY' || compareWith === 'Default:YOY') {
                    compareValArr =  MonthlyPNLandBalValues(monthsAvailable, sheet, metric.key, yoy, yoy_calcs['you'])
                    compareAvg = MonthlyPNLandBalCommonSized(sheet, compareValArr, yoy_calcs['you'])
                } else if (compareWith.includes('Forecast')) {
                    const forecast = forecastVals ? forecastVals[metric.key] : undefined;
                    compareValArr = MonthlyPNLandBalValues(monthsAvailable, sheet, metric.key, forecast, forecastVals)
                    compareAvg = MonthlyPNLandBalCommonSized(sheet, compareValArr, forecastVals)
                }
                deltaValue = Math.abs((youAvg * 100) - (compareAvg * 100))
                deltaCommonSize = formatValue(deltaValue, 'percent')
                impactValue = youTotalAssetsVal * (youAvg - compareAvg)
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                status = getRangeValue(youAvg * 100, compareAvg * 100, metric.key, metric.parentKey);
            }

            if (reportPeriod === 'Year to Date') {
                const currentYearNonMonthlyYTDYou = calcs[year] ? calcs[year]['you'][metric.key] : undefined;
                const currentYearNonMonthlyYTDPeers = calcs[year] ? calcs[year]['avg'][metric.key] : undefined;
                const youTotalAssetsVal = YTDPnlandBalValues(sheet, 'total_assets', currentYearNonMonthlyYTDYou, calcs[year]['you'])
                youValue = YTDPnlandBalValues(sheet, metric.key, currentYearNonMonthlyYTDYou, calcs[year]['you'])
                compareValue = YTDPnlandBalValues(sheet, metric.key, currentYearNonMonthlyYTDPeers, calcs[year]['avg'])
                youAvg = YTDPnlandBalCommonSizeValue(youValue, sheet, calcs[year]['you'])
                compareAvg = YTDPnlandBalCommonSizeValue(compareValue, sheet, calcs[year]['avg'])
                deltaValue = Math.abs((youAvg * 100) - (compareAvg * 100))
                deltaCommonSize = formatValue(deltaValue, 'percent')
                impactValue = youTotalAssetsVal * (youAvg - compareAvg)
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                status = getRangeValue(youAvg * 100, compareAvg * 100, metric.key, metric.parentKey);
            }
            impactText = moneyText[metric.key] ? moneyText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord) : null
            copy = moneyText[metric.key] ? moneyText[metric.key]['copy'] : null
        }

        if (sheet === 'cash_flow' && specialCashFlowKeys.includes(metric.key)) {
            function commonSizeValue(props) {
                let finalValue = null;
                let finalIndex = 0
                let totalRevenue = null
        
                if (props.numOfMonths) {
                    totalRevenue = Object.values(props.passedCalcs['total_revenue']).slice(0, props.numOfMonths).reduce((a, b) => a + b, 0)
                } else {
                    totalRevenue = Object.values(props.passedCalcs['total_revenue']).reduce((a, b) => a + b, 0)
                }

                if (Object.values(props.passedCalcs).length > 0) {
                    const val = props.value;
                    finalValue = (val / totalRevenue)
                }
                return finalValue
            }

            if (displayColumnsBy === 'Years' && reportPeriod !== 'Year to Date') {
                let cashVals = AnnualCashFlowValues(calcs, calcsAvgType, yearRange, metric.key);
                youValue = cashVals[year]
                youAvg = cashVals['you' + year]
                compareAvg = cashVals['avg' + year]
                deltaValue = Math.abs((youAvg * 100) - (compareAvg * 100))
                impactValue = youValue * (youAvg - compareAvg)
                impactValueFormatted = parseMoney(Math.abs(impactValue))
                deltaCommonSize = formatValue(deltaValue, metric.format)
                status = getRangeValue(youAvg * 100, compareAvg * 100, metric.key)
            }

            if (displayColumnsBy === 'Months') {
                const calcsYou = calcs.you;
                const calcsPeers = calcs.avg;
                const previousYearCalcsYou = previousYearCalcs.you;
                const previousYearCalcsPeers = previousYearCalcs.avg;
                const calcsYOYYou = yoy_calcs.you;
                const previousYOYYou = previous_yoy_calcs.you;
                let compareTotalValue, youTotalValue;
                let youValues, compareValues = null;
                let numOfMonths = Object.values(calcsYou['total_revenue']).filter(val => _.isNumber(val));

                if (compareWith === 'Peers' || compareWith === 'Default:Peers') {
                    let cashFlowVals = MonthlyCashFlowValues(metric.key, calcsYou, previousYearCalcsYou, calcsPeers, previousYearCalcsPeers)
                    youValues = cashFlowVals.youValues
                    compareValues = cashFlowVals.compareValues
                } else if (compareWith === 'YOY' || compareWith === 'Default:YOY') {
                    let cashFlowVals = MonthlyCashFlowValues(metric.key, calcsYou, previousYearCalcsYou, calcsYOYYou, previousYOYYou)
                    youValues = cashFlowVals.youValues
                    compareValues = cashFlowVals.compareValues
                } else if (compareWith.includes('Forecast')) {
                    let cashFlowVals = MonthlyCashFlowValues(metric.key, calcsYou, previousYearCalcsYou, forecastVals, previousYearCalcsYou)
                    youValues = cashFlowVals.youValues
                    compareValues = cashFlowVals.compareValues
                }

                if (metric.key === 'cash_beginning_period') {
                    compareTotalValue = compareValues ? compareValues[1] : null;
                    youTotalValue = youValues ? youValues[1] : null;
                } else if (metric.key === 'cash_end_period') {
                    let getAllValues = youValues ? Object.values(youValues) : 0;
                    let indexOfNullValue = getAllValues.indexOf(null);
                    compareTotalValue = compareValues && indexOfNullValue >= 0 ? compareValues[indexOfNullValue] : compareValues && compareValues.hasOwnProperty(12) ? compareValues[12] : null;
                    youTotalValue = youValues && indexOfNullValue >= 0 ? youValues[indexOfNullValue] : youValues && youValues.hasOwnProperty(12) ? youValues[12] : null;
                } else {
                    let numberOfKeys = youValues ? Object.keys(youValues).length : 0;
                    if (numberOfKeys) {
                        compareTotalValue = compareValues ? Object.values(compareValues).slice(0, numOfMonths.length).reduce((a, b) => a + b, 0) : null;
                        youTotalValue = youValues ? Object.values(youValues).reduce((a, b) => a + b, 0) : null;
                    }
                }
                youAvg = commonSizeValue({ value: youTotalValue, passedCalcs: calcsYou })

                if (compareWith === 'Peers' || compareWith === 'Default:Peers') {
                    compareAvg = commonSizeValue({ value: compareTotalValue, passedCalcs: calcsPeers })
                } else if (compareWith === 'YOY' || compareWith === 'Default:YOY') {
                    compareAvg = commonSizeValue({ value: compareTotalValue, passedCalcs: previousYearCalcsYou })
                } else if (compareWith.includes('Forecast')) {
                    compareAvg = commonSizeValue({ value: compareTotalValue, passedCalcs: forecastVals, numOfMonths: numOfMonths.length })
                }

                deltaValue = Math.abs((youAvg * 100) - (compareAvg * 100))
                impactValue = youTotalValue * (youAvg - compareAvg)
                impactValueFormatted = parseMoney(Math.abs(impactValue))

                deltaCommonSize = formatValue(deltaValue, metric.format, sheet, metric.key)
                status = getRangeValue(youAvg * 100, compareAvg * 100, metric.key)
            }

            if (reportPeriod === 'Year to Date') {
                const calcYearKeys = Object.keys(calcs);
                const previousYearCalcKeys = Object.keys(previousYearCalcs['monthly_full_year']);
                let oldYear, newYear;
                let previousOldFullYear = previousYearCalcKeys[0];
                let previousNewFullYear = previousYearCalcKeys[1];
                if (Number(calcYearKeys[1]) > Number(calcYearKeys[0])) {
                    newYear = calcYearKeys[1];
                    oldYear = calcYearKeys[0];
                } else {
                    newYear = calcYearKeys[0];
                    oldYear = calcYearKeys[1];
                }
                const calcsYou = calcs[newYear].you;
                const calcsPeers = calcs[newYear].avg;
                const previousYearCalcsYou = previousYearCalcs['monthly_full_year'][previousNewFullYear].you;
                const previousYearCalcsPeers = previousYearCalcs['monthly_full_year'][previousNewFullYear].avg;
                let newerYearVals = YTDCashFlowValues(metric.key, calcsYou, previousYearCalcsYou, calcsPeers, previousYearCalcsPeers);
                let newerYearYouVal = newerYearVals['youValue'];
                let newerYearCompareVal = newerYearVals['compareValue'];
                youAvg = commonSizeValue({ value: newerYearYouVal, passedCalcs: calcsYou })
                compareAvg = commonSizeValue({ value: newerYearCompareVal, passedCalcs: calcsPeers })
                deltaValue = Math.abs((youAvg * 100) - (compareAvg * 100))
                impactValue = youValue * (youAvg - compareAvg)
                impactValueFormatted = parseMoney(Math.abs(impactValue))

                deltaCommonSize = formatValue(deltaValue, metric.format, sheet, metric.key)
                status = getRangeValue(youAvg * 100, compareAvg * 100, metric.key)
            }
            impactText = moneyText[metric.key] ? moneyText[metric.key].texts(status, impactValueFormatted, deltaCommonSize, compareWord) : null
            copy = moneyText[metric.key] ? moneyText[metric.key]['copy'] : null
        }

        if (sheet === 'kfis') {
            let key = metric.key;

            if (key === 'ar_days') {
                key = 'accounts_receivable'
            }
            if (key === 'ap_days') {
                key = 'accounts_payable'
            }

            if (displayColumnsBy === 'Years' && reportPeriod !== 'Year to Date') {
                let calcsAvgType = 'avg';
                if (compareWith === 'Practice') {
                    calcsAvgType = 'practice_avg'
                } else if (compareWith === 'NSCHBC') {
                    calcsAvgType = 'nschbc'
                }
                let kfiVal = AnnualKFIsValues(calcs, calcsAvgType, yearRange, metric.key);
                youValue = formatValue(kfiVal[year], metric.format, sheet, metric.key)
                compareAvg = kfiVal['avg' + year]
                impactValueFormatted = youValue
                deltaCommonSize = formatValue(kfiVal[year] - compareAvg, format, sheet, metric.key)
                status = getRangeValueKFI(kfiVal[year], compareAvg, metric.key)
            }

            if (displayColumnsBy === 'Months') {
                let numberOfMonths = 0
                let useableMonths = Object.values(calcs['you']['cash']);
                useableMonths.forEach(thing => thing !== null ? numberOfMonths += 1 : null)
                let { youValues, compareValues, ytdYou, ytdCompare } = MonthlyKFIsValues(metric, numberOfMonths, compareWith, calcs, previousYearCalcs, previous_yoy_calcs, forecastVals)
                youValue = formatValue(ytdYou, metric.format, sheet, metric.key)
                compareValue = ytdCompare
                impactValueFormatted = youValue
                deltaCommonSize = formatValue(ytdYou - compareValue, format, sheet, metric.key)
                status = getRangeValueKFI(ytdYou, ytdCompare, metric.key)
            }

            if (reportPeriod === 'Year to Date') {
                let youValueInitial = calculateKFIMetric(metric.key, calcs[year]['you'], previousYearCalcs['monthly'])
                youValue = formatValue(youValueInitial, metric.format, sheet, metric.key)
                compareValue = calculateKFIMetric(metric.key, calcs[year]['avg'], previousYearCalcs['monthly'])
                impactValueFormatted = youValue
                deltaCommonSize = formatValue(youValueInitial - compareValue, format, sheet, metric.key)
                status = getRangeValueKFI(youValueInitial, compareValue, metric.key)
            }
            impactText = moneyText[key] ? moneyText[key].texts(status, impactValueFormatted, deltaCommonSize, compareWord) : null;
            copy = moneyText[key] ? moneyText[key]['copy'] : null;
        }
    }

    return { copy, impactText, status, deltaCommonSize, impactValueFormatted };
}

const impactBoxStatusColor = (status) => {
    // we calculate red and green
    switch (status) {
        case 'worst':
            return 'impact-box-worst'
        case 'worse':
            return 'impact-box-worse'
        case 'maintain':
            return 'impact-box-maintain'
        case 'better':
            return 'impact-box-better'
        case 'best':
            return 'impact-box-best'
        default:
            return 'impact-box-maintain'
    }
}

const noSpecificTextMaker = (copy, status, impactValue, deltaValue, compareWith = 'Peers', monthlyCompareWithTitle = null) => {
    let compareWord = '';
    if (compareWith === 'Peers' || monthlyCompareWithTitle === 'Peers') {
        compareWord = 'peers'
    } else if (compareWith === 'Practice') {
        compareWord = 'practice'
    } else if (compareWith === 'NSCHBC') {
        compareWord = 'NSCHBC peers'
    } else if (compareWith === 'YOY') {
        compareWord = 'YOY'
    } else if (monthlyCompareWithTitle) {
        compareWord = monthlyCompareWithTitle
    }

    let modifyStatus = status;
    let finalText = ''
    if (modifyStatus === 'n/a') {
        return {
            'text1': `No values present.`,
            'text2': '',
            'textNotesSection': ''
        }
    }
    if (modifyStatus === 'worst') {
        modifyStatus = 'much worse'
    }

    if (modifyStatus === 'best') {
        modifyStatus = 'much better'
    }

    if (modifyStatus === 'maintain') {
        finalText = 'Maintain Progress.'
    } else {
        finalText = `Your ${copy} is ${impactValue} which is potentially ${modifyStatus} compared to your ${compareWord}.`
    }

    return {
        'text1': `Your ${copy} is ${deltaValue} different from your ${compareWord}.`,
        'text2': finalText,
        'textNotesSection': finalText
    }
}
export { moneyText, buildImpactBox, impactBoxStatusColor, noSpecificTextMaker };