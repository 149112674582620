import React, { useState, useRef, useEffect } from "react";
import peerviewLogoCircle from '../../assets/images/peerview-data-logo-white-small.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faBell, faDiagramProject, faGear, faCaretDown, faMagnifyingGlass, faMap, faCircleArrowRight, faCircleArrowLeft, faBook, faChartPie, faFileLines, faIndustry, faShield, faUser } from "@fortawesome/free-solid-svg-icons";
import VideoModal from "./VideoModal";
import Modal from 'react-modal';
import NewUserForm from './NewUserForm';
import workflows from "./workflows/workflow_data";

const TopNavbar = (props) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [displayUserMenu, setDisplayUserMenu] = useState(false)
    const [searchResults, setSearchResults] = useState(
        {
            display: false,
            isLoading: false,
            results: [],
            admin_results: []
        }
    )
    const [addNew, setAddNew] = useState(false)
    const [activePopover, setActivePopover] = useState(null);
    const [expandNavbar, setExpandNavbar] = useState(false)
    const [modalReport, setModalReport] = useState("money");
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [newUserModalOpen, setNewUserModalOpen] = useState(false);
    const [newUserFormData, setNewUserFormData] = useState({
        all_companies: [],
        email_ends: [],
        filter_options_obj: {},
    })
    const modalRef = useRef(null);
    const buttonRef = useRef(null);


    const openVideoModal = (reportVal) => {
        setModalReport(reportVal)
        setVideoModalOpen(true);
    }

    const closeVideoModal = () => {
        console.log('close?')
        setVideoModalOpen(false);
    }

    const hrefBuilder = (subdomain, path) => {
        const domainSplit = document.location.host.split('.');
        domainSplit[0] = subdomain;
        return location.protocol + '//' + domainSplit.join('.') + path;
    };

    const toggleNavbar = () => {
        setExpandNavbar(!expandNavbar);
    };

    const handleMouseEnter = (item) => {
        setActivePopover(item);
    };

    const handleMouseLeave = (item) => {
        if (item === 'leave') {
            setActivePopover(null);
        }
    };

    useEffect(() => {
        // Define the click handler
        const handleClickAnywhere = (event) => {

            if (
                modalRef.current &&
                !modalRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setDisplayUserMenu(false);
            }
        };

        // Add event listener to document
        document.addEventListener('click', handleClickAnywhere);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClickAnywhere);
        };
    }, []);


    useEffect(() => {
        // Set up the debouncer with a delay (e.g., 500ms)
        const handler = setTimeout(() => {
            setDebouncedQuery(searchQuery);
        }, 1000); // Adjust the delay as needed

        // Clear the timeout if searchQuery changes (user is still typing)
        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery]); // Run the effect when searchQuery changes

    useEffect(() => {
        // Define the async function to perform the search
        const fetchSearchData = async () => {
            if (debouncedQuery) {
                setSearchResults(prevState => {
                    return {
                        ...prevState,
                        isLoading: true,
                    }
                })

                try {
                    const response = await fetch(`/api/v1/company_search/?term=${debouncedQuery}`);

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const data = await response.json();

                    setSearchResults(prevState => {
                        return {
                            ...prevState,
                            isLoading: false,
                            results: data.firm_results,
                            admin_results: data.admin_results
                        }
                    })
                    // Handle the results (e.g., update state with the results)
                } catch (error) {
                    console.error('There was a problem', error);
                }
            }
        };

        // Call the async function
        fetchSearchData();
    }, [debouncedQuery]); // Run the effect when debouncedQuery changes

    const handleDelete = () => {

        fetch('/users/sign_out', {
            method: 'DELETE', // Specify the HTTP method as DELETE
        })
            .then(response => {
                if (response.ok) {
                    window.location.reload();
                } else {
                    console.error('Failed to delete.');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleNewUserFormData = async () => {
        try {
            const response = await fetch(`/user_permissions_info`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Search results:', data);
            setNewUserFormData(data)
            setNewUserModalOpen(true)
            // Handle the results (e.g., update state with the results)
        } catch (error) {
            console.error('There was a problem', error);
        }
    }

    const openSearchResults = () => {
        setSearchResults(prevState => {
            return {
                ...prevState,
                display: true
            }
        })
    }

    const closeSearchResults = () => {
        setSearchResults(prevState => {
            return {
                ...prevState,
                display: false,
                isLoading: false
            }
        })
    }

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    }
    // let newUserLink = hrefBuilder(props.parent_subdomain, '/clients/new')
    // Check for different Users
    let newCompanyLink = hrefBuilder(props.parent_subdomain, '/admin/companies/new')

    let userDashboardLink = props.isAdminView ? hrefBuilder('app', '') : hrefBuilder(props.parent_subdomain, '/dashboard')
    let manageFirmLink = hrefBuilder(props.parent_subdomain, '/manage_firm')
    let notificationLink = hrefBuilder(props.parent_subdomain, '/notifications')
    let profileLink = hrefBuilder(props.parent_subdomain, '/users/edit')
    let exportsLink = hrefBuilder(props.child_subdomain, '/exports')
    let manageSubscriptionsLink = hrefBuilder(props.parent_subdomain, '/subscriptions')
    let controlPanelLink = hrefBuilder(props.parent_subdomain, '/admin/control_panel')
    let signOutLink = hrefBuilder('app', '/users/sign_out')

    let moneyLink = hrefBuilder(props.child_subdomain, '/reports/money')
    let peopleLink = hrefBuilder(props.child_subdomain, '/reports/people')
    let customersLink = hrefBuilder(props.child_subdomain, '/reports/customers')
    let forecastLink = hrefBuilder(props.child_subdomain, '/reports/monthly/forecast')
    let mappingLink = hrefBuilder(props.child_subdomain, '/mapping_approvals/new')

    let superAdminLink = hrefBuilder('app', '')
    let superCompaniesLink = hrefBuilder('app', '/admin/companies')
    let superParentCompaniesLink = hrefBuilder('app', '/admin/parent_companies')
    let superNaicsLink = hrefBuilder('app', '/admin/naicses')
    let superOpsLink = hrefBuilder('app', '/admin/ops')

    let parentAdminLink = hrefBuilder(props.parent_subdomain, '/clients')
    let childAdminLink = hrefBuilder(props.child_subdomain, '/clients')

    let manageClientsLink = hrefBuilder(props.parent_subdomain, '/companies')
    let resourcesLink = 'https://www.peerviewdata.com/resources-page'
    let practiceLink = hrefBuilder(props.parent_subdomain, '/group_reports/practice')
    let industryLink = hrefBuilder(props.parent_subdomain, '/reports/industry')

    const reportLinkBuilder = (currentReport, planAccess) => {
        let reportName = currentReport === 'Audit' ? 'Audit (Beta)' : currentReport
        let reportNameLowerCase = currentReport.toLowerCase().replace(/\s/g, "")
        let reportLink = currentReport === 'Forecast' ? `/reports/monthly/${reportNameLowerCase}` : `/reports/${reportNameLowerCase}`

        if (currentReport === 'Practice') {

            if (props.user_type === 'Admin' || planAccess) {
                return (
                    <a href={practiceLink}><FontAwesomeIcon icon={faChartPie} className='menu-icon' /><span className='left-menu-text'>Practice</span></a>
                )
            } else {
                return (
                    <a onClick={() => openVideoModal(reportNameLowerCase, planAccess)}><FontAwesomeIcon icon={faChartPie} className='menu-icon' /><span className='left-menu-text'>Practice</span></a>
                )
            }
        }

        if (currentReport === 'Industry') {

            if (props.user_type === 'Admin' || planAccess) {
                return (
                    <a href={industryLink}><FontAwesomeIcon icon={faIndustry} className='menu-icon' /><span className='left-menu-text'>Industry</span></a>
                )
            } else {
                return (
                    <a onClick={() => openVideoModal(reportNameLowerCase, planAccess)}><FontAwesomeIcon icon={faIndustry} className='menu-icon' /><span className='left-menu-text'>Industry</span></a>
                )
            }
        }

        // Freemium can only access Money report and can't use report credits
        if (props.user_type !== 'Admin' && !planAccess || (props.user_type !== 'Admin' && planAccess && !props.reports_unlocked)) {
            return (
                <a onClick={() => openVideoModal(reportNameLowerCase, planAccess)}>{reportName}</a>
            )
        }

        // Let Admin access everything OR non-Admin, non-free plans, and unlocked reports
        if (props.user_type === 'Admin' || (props.user_type !== 'Admin' && planAccess && props.reports_unlocked)) {
            return (
                <a href={reportLink}>{reportName}</a>
            )
        }
    }

    const resultsTable = (firmResults = true) => {
        let sResults = firmResults ? searchResults.results : searchResults.admin_results

        return (
            <table className='results-table'>
                <thead>
                    <tr>
                        <td>Client ID</td>
                        <td>Client Name</td>
                        <td>Map File</td>
                        <td>NAICS</td>
                        <td>Partner</td>
                        <td>Office</td>
                        <td>State</td>
                        <td>Status</td>
                    </tr>
                </thead>
                <tbody>
                    {sResults.map(r => {
                        let reportLink = firmResults ? hrefBuilder(r.subdomain, '/reports') : hrefBuilder(r.subdomain, '/companies')
                        let mapLink = r.parent_id ? hrefBuilder(r.subdomain, '/mapping_approvals/new') : null
                        return (
                            <tr>
                                <td>
                                    <a href={reportLink}>{r.name}</a>
                                </td>
                                <td>
                                    {r.real_name}
                                </td>
                                <td>
                                    {mapLink ? <a href={mapLink}><FontAwesomeIcon icon={faMap} /></a> : null}
                                </td>
                                <td>
                                    {r.naics_code}
                                </td>
                                <td>
                                    {r.partners}
                                </td>
                                <td>
                                    {r.office}
                                </td>
                                <td>
                                    {r.state}
                                </td>
                                <td>
                                    {r.status}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    const closeNewUserModal = () => {
        setNewUserModalOpen(false)
    }

    return (
        <>
            {props.isAdminView
                ? null
                : (
                    <VideoModal
                        isOpen={videoModalOpen}
                        onRequestClose={closeVideoModal}
                        modalReport={modalReport}
                        planAccess={props.plan_type_access}
                        parentSubdomain={props.parent_subdomain}
                        unlockReportsURL={props.unlock_reports_url}
                        reportCredits={props.report_credit}
                        userType={props.user_type}
                        reportsUnlocked={props.reports_unlocked}
                    />
                )
            }
            <Modal
                className={{
                    base: 'client-modal-content',
                    afterOpen: 'client-modal-content_after-open',
                    beforeClose: 'client-modal-content_before-close',
                }}
                overlayClassName={{
                    base: 'overlay-base',
                    afterOpen: 'overlay-base_after-open',
                    beforeClose: 'overlay-base_before-close',
                }}
                isOpen={newUserModalOpen}
                onRequestClose={closeNewUserModal}
                shouldCloseOnOverlayClick={true}
                closeTimeoutMS={0}
                contentLabel='New User Create Form Modal'
            >
                <header className='company-modal-header'>
                    <button
                        type='button'
                        className='btn btn-dark modal-close-btn'
                        onClick={closeNewUserModal}
                    >
                        <i className='fa fa-times'></i>
                    </button>
                    <h1 className='client-company-name'>New User</h1>
                </header>
                <NewUserForm using_navbar={true} email_ends={newUserFormData.email_ends} filter_options_obj={newUserFormData.filter_options_obj} all_companies={newUserFormData.all_companies} />
            </Modal>
            <div className='top-navbar-container'>
                <div className='peerview-logo-container'>
                    <a className='peerview-logo-link' href={userDashboardLink}>
                        <img className='peerview-logo-circle' src={peerviewLogoCircle} alt="Peerview Data Logo" />
                    </a>
                    {!props.single_company_client_user
                        ? (
                            <div className='search-bar-container'>
                                <div className='search-bar-div'>
                                    <FontAwesomeIcon className='search-icon' icon={faMagnifyingGlass} />
                                    <input
                                        className='nav-search-bar'
                                        type="text"
                                        placeholder="Search Peerview Data"
                                        value={searchQuery}
                                        onClick={() => openSearchResults()}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className='nav-add-new-container'>
                                    {props.isAdminView ? null : <button className='nav-add-new-btn' onClick={() => setAddNew(!addNew)}>+</button>}
                                    {
                                        addNew && (<div className='nav-add-new-div'>
                                            <ul>
                                                <li onClick={() => handleNewUserFormData()}>
                                                    <a>New User</a>
                                                </li>
                                                <li>
                                                    <a href={newCompanyLink}>New Client</a>
                                                </li>
                                            </ul>
                                        </div>)
                                    }
                                </div>
                            </div>
                        )
                        : null
                    }
                </div>
                {props.isAdminView
                    ? <div>Super Admin Portal</div>
                    : <div className='navbar-client-name'>{props.company_type === 'client' && props.company_real_name ? `Viewing Client: ${props.company_name}, ${props.company_real_name}` : props.company_type === 'client' ? `Viewing Client: ${props.company_name}` : ''}</div>
                }
                <nav className='right-side-links'>
                    <ul className='right-icons'>
                        {!props.isAdminView && !props.single_company_client_user
                            ?
                            (<li>
                                <a className='right-link' href={manageFirmLink}>
                                    <FontAwesomeIcon icon={faGear} />
                                </a>

                            </li>)
                            : null
                        }
                        {!props.isAdminView && !props.single_company_client_user
                            ?
                            (<li>
                                <a className='right-link' href={notificationLink}>
                                    <FontAwesomeIcon icon={faBell} />
                                </a>
                            </li>)
                            : null
                        }
                    </ul>
                    <div className='vertical-divider'></div>
                    <div className='user-menu-btn'
                        ref={buttonRef}
                        onClick={() => setDisplayUserMenu(!displayUserMenu)}
                    >
                        <span>{props.isAdminView ? props.user_name : props.parent_company_name}</span> <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                    {
                        displayUserMenu && (
                            <div className='user-menu-container' ref={modalRef}>
                                <div className='user-info-and-links'>
                                    <span className='user-name'>{props.user_name}</span>
                                    <a href={profileLink}>Edit Your Profile</a>
                                </div>
                                <ul className='user-menu-links'>
                                    {!props.single_company_client_user
                                        ? (<li>
                                            <a href={exportsLink}>Exports</a>
                                        </li>)
                                        : null}
                                    {!props.isAdminView && !props.single_company_client_user
                                        ? (<li>
                                            <a href={manageFirmLink}>Manage Firm</a>
                                        </li>)
                                        : null}
                                    {!props.isAdminView && !props.single_company_client_user
                                        ? (<li>
                                            <a href={manageSubscriptionsLink}>Manage Subscription</a>
                                        </li>) : null}
                                    {!props.isAdminView && !props.single_company_client_user
                                        ? (<li>
                                            Plan: {props.plan_type}
                                        </li>) : null}
                                    {!props.isAdminView && !props.single_company_client_user
                                        ? (<li>
                                            <a href={notificationLink}>Notifications</a>
                                        </li>)
                                        : null}
                                    {props.isAdminView
                                        ? (<li>
                                            <a href={controlPanelLink}>Control Panel</a>
                                        </li>)
                                        : null}
                                    <li className='sign-out-link'>
                                        <a onClick={() => handleDelete()}>Sign Out</a>
                                    </li>
                                </ul>
                            </div>
                        )
                    }
                </nav>
                {
                    searchResults.display && (<div className='search-results-container'>
                        <div className='close-search-btn'><button onClick={() => closeSearchResults()}>X</button></div>
                        <div className='search-results-div'>
                            <span className='search-title'>Search Your Clients</span>
                            <div className='results-table-container'>
                                {props.user_type === 'Admin' && searchResults.admin_results.length > 0
                                    ? <div className='admin-results-container'>
                                        <span className='search-title'>Admin Results</span>
                                        {resultsTable(false)}
                                    </div>
                                    : null
                                }
                                {
                                    searchResults.results.length > 0
                                        ?
                                        <div>
                                            <span className='search-title'>Results</span>
                                            {resultsTable(true)}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>)
                }
            </div>
            <div className={`left-navbar-container ${expandNavbar ? 'expanded' : 'collapsed'}`}>
                <nav>
                    <ul>
                        {!props.isAdminView && props.company_type === 'client' && <li className='left-menu-item'>
                            <div
                                className='left-menu-div'
                                onMouseEnter={() => handleMouseEnter('reports')}
                                onMouseLeave={() => handleMouseLeave(null)}
                            >
                                <FontAwesomeIcon icon={faFileLines} className='menu-icon' />
                                <span className='left-menu-text'>Reports</span>
                                {activePopover === 'reports' && (
                                    <div className={expandNavbar ? "left-nav-popover-expanded" : "left-nav-popover"}
                                        onMouseEnter={() => handleMouseEnter('reports')}
                                        onMouseLeave={() => handleMouseLeave('leave')}
                                    >
                                        <span className='popover-title'>{props.company_real_name ? `Reports for Client: ${props.company_name}, ${props.company_real_name}` : `Reports for Client: ${props.company_name}`}</span>
                                        <a href={moneyLink}>Money</a>
                                        {reportLinkBuilder('People', props.plan_type_access.people)}
                                        {reportLinkBuilder('Customers', props.plan_type_access.customers)}
                                        {reportLinkBuilder('Forecast', props.plan_type_access.forecast)}
                                        {!props.single_company_client_user ? <a href={mappingLink} style={{ 'borderTop': '1px solid #908f8f' }}>Map File</a> : null}
                                    </div>
                                )}
                            </div>
                        </li>}
                        {props.isAdminView
                            ? (<li className='left-menu-item'>
                                <div
                                    className='left-menu-div'
                                    onMouseEnter={() => handleMouseEnter('admin-links')}
                                    onMouseLeave={() => handleMouseLeave(null)}
                                >
                                    <FontAwesomeIcon icon={faShield} className='menu-icon' />
                                    <span className='left-menu-text'>Admin Links</span>
                                    {activePopover === 'admin-links' && (
                                        <div className={expandNavbar ? "left-nav-popover-expanded" : "left-nav-popover"}
                                            onMouseEnter={() => handleMouseEnter('admin-links')}
                                            onMouseLeave={() => handleMouseLeave('leave')}
                                        >
                                            {props.user_type === 'Admin' ? <a href={superAdminLink}>Super Admin Portal</a> : null}
                                            <a href={superCompaniesLink} style={{ 'borderTop': '1px solid #908f8f' }}>All Companies</a>
                                            <a href={superParentCompaniesLink}>Parent Companies</a>
                                            <a href={superNaicsLink}>NAICS</a>
                                            <a href={superOpsLink}>Ops View</a>
                                        </div>
                                    )}
                                </div>
                            </li>)
                            : null
                        }
                        {!props.isAdminView && !props.single_company_client_user
                            ?
                            (<li className='left-menu-item'>
                                <div
                                    className='left-menu-div'
                                    onMouseEnter={() => handleMouseEnter('admin-portals')}
                                    onMouseLeave={() => handleMouseLeave(null)}
                                >
                                    <FontAwesomeIcon icon={faShield} className='menu-icon' />
                                    <span className='left-menu-text'>Admin Portals</span>
                                    {activePopover === 'admin-portals' && (
                                        <div className={expandNavbar ? "left-nav-popover-expanded" : "left-nav-popover"}
                                            onMouseEnter={() => handleMouseEnter('admin-portals')}
                                            onMouseLeave={() => handleMouseLeave('leave')}
                                        >
                                            {props.user_type === 'Admin' ? <a href={superAdminLink}>Super Admin Portal</a> : null}
                                            <a href={parentAdminLink} style={props.user_type === 'Admin' ? { 'borderTop': '1px solid #908f8f' } : null}>{props.parent_company_name} Admin Portal</a>
                                            {props.company_type === 'client' && <a href={childAdminLink}>{props.company_name} Admin Portal</a>}
                                        </div>
                                    )}
                                </div>
                            </li>)
                            : null}
                        {!props.single_company_client_user ? <hr className='left-nav-divider' /> : null}
                        {
                            !props.isAdminView
                                ? <>
                                    {!props.single_company_client_user
                                        ? (<li className='left-menu-item'>
                                            <div
                                                className='left-menu-div'
                                                onMouseEnter={() => handleMouseEnter('Manage Clients')}
                                                onMouseLeave={() => handleMouseLeave('leave')}
                                            >
                                                <a href={manageClientsLink}><FontAwesomeIcon icon={faUser} className='menu-icon' />
                                                    <span className='left-menu-text'>Manage Clients</span></a>
                                                {activePopover === 'Manage Clients' && !expandNavbar && (
                                                    <div className="left-nav-popover">
                                                        <span>Manage Clients</span>
                                                    </div>
                                                )}
                                            </div>
                                        </li>)
                                        : null}
                                    {!props.single_company_client_user
                                        ? (<li className='left-menu-item'>
                                            <div
                                                className='left-menu-div'
                                                onMouseEnter={() => handleMouseEnter('Practice')}
                                                onMouseLeave={() => handleMouseLeave('leave')}
                                            >
                                                {reportLinkBuilder('Practice', props.plan_type_access.practice)}
                                                {activePopover === 'Practice' && !expandNavbar && (
                                                    <div className="left-nav-popover">
                                                        <span>Practice Report</span>
                                                    </div>
                                                )}
                                            </div>
                                        </li>)
                                        : null}
                                    {!props.single_company_client_user
                                        ? (
                                            <li className='left-menu-item'>
                                                <div
                                                    className='left-menu-div'
                                                    onMouseEnter={() => handleMouseEnter('Industry')}
                                                    onMouseLeave={() => handleMouseLeave('leave')}
                                                >
                                                    {reportLinkBuilder('Industry', props.plan_type_access.industry)}
                                                    {activePopover === 'Industry' && !expandNavbar && (
                                                        <div className="left-nav-popover">
                                                            <span>Industry Report</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </li>
                                        )
                                        : null}
                                </>
                                : null
                        }
                        <li className='left-menu-item'>
                            <div
                                className='left-menu-div'
                                onMouseEnter={() => handleMouseEnter('Resources')}
                                onMouseLeave={() => handleMouseLeave('leave')}
                            >
                                <a href={resourcesLink} target='_blank'><FontAwesomeIcon icon={faBook} className='menu-icon' />
                                    <span className='left-menu-text'>Resources</span></a>
                                {activePopover === 'Resources' && !expandNavbar && (
                                    <div className="left-nav-popover">
                                        <span>Resources</span>
                                    </div>
                                )}
                            </div>
                        </li>
                        {!props.isAdminView && <li className='left-menu-item'>
                            <div
                                className='left-menu-div'
                                onMouseEnter={() => handleMouseEnter('Workflows')}
                                onMouseLeave={() => handleMouseLeave(null)}
                            >
                                <FontAwesomeIcon icon={faDiagramProject} className='menu-icon' />
                                <span className='left-menu-text'>Workflows</span>
                                {activePopover === 'Workflows' && (
                                    <div className={expandNavbar ? "left-nav-popover-expanded" : "left-nav-popover"}
                                        onMouseEnter={() => handleMouseEnter('Workflows')}
                                        onMouseLeave={() => handleMouseLeave('leave')}
                                    >
                                        <span className='popover-title'>Workflows</span>
                                        {workflows.map(w => {
                                            let workflowLink = hrefBuilder(props.parent_subdomain, `/workflows/${w.id}`)
                                            return <a className='workflow-link' href={workflowLink} target='_blank'>{w.title}</a>
                                        })}
                                    </div>
                                )}
                            </div>
                        </li>}
                        <li className='left-menu-item'>
                            <div className={expandNavbar ? 'left-menu-div-expand' : 'left-menu-div-collapse'}>
                                <FontAwesomeIcon icon={expandNavbar ? faCircleArrowLeft : faCircleArrowRight} onClick={() => toggleNavbar()} className='menu-icon' />
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default TopNavbar