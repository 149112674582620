import React, { useState, useEffect, useRef, Fragment } from 'react';
import DropdownFilter from '../utils/DropdownFilter';
import LoaderGraphic from '../utils/LoaderGraphic';
import { Data, Filters } from './contexts';
import { capitalize, parseMoney } from '../utils';
import AccountTree from '../utils/AccountTree';
import CustomReportPDFandPrint from './CustomReportPDFandPrint';
import { ToastContainer, toast } from 'react-toastify';
import ahoy from 'ahoy.js';
import Modal from 'react-modal';
import Highcharts from 'highcharts/highcharts.js';
import HighchartsReact from 'highcharts-react-official';
import { pnlRows } from './NAICSFocusedReport/NAICSFocusedReport';
import { CommonSizeArrows } from './report_components/MoneyHelpers';
import { CheckMark, XMark } from '../utils/SVGIcons';

const CustomReport = (props) => {
    const componentRefs = useRef();
    const [state, setState] = useState({
        report: 'customreport',
        pageTitle: '',
        clientName: props.client_name || '',
        calendarYearEnd: props.calendarYearEnd,
        fiscalYearStartMonth: props.fiscalYearStartMonth || 1,
        peerviewMetricsYears: props.peerviewMetricsYears || {},
        calcs: props.calcs,
        monthly_calcs: props.monthly_calcs,
        previousYearCalcs: {},
        yoy_calcs: {},
        previous_yoy_calcs: {},
        calcs_3_years_back: {},
        baseCalc: {},
        monthlyForecastCalcs: {},
        fetch: !props.calcs,
        loading: false,
        filters: props.filters || {},
        filterOptions: props.filterOptions || {},
        currentNewFilters: props.current_new_filters || {},
        newFilterOptions: props.new_filter_options || {},
        youAnnualOptions: {},
        youMonthlyOptions: {},
        peerAnnualOptions: {},
        peerMonthlyOptions: {},
        monthlyCompareWithOptions: [],
        tree: props.tree,
        yearRange: props.yearRange || [],
        n: props.n,
        docs_n: 0,
        forecast_scenarios: [],
        insufficient_data: false,
        insufficient_peer_data: false,
        reportSpecific: props.reportSpecific || {},
        isMonthlyAvailable: props.isMonthlyAvailable,
        fiscalYearEnd: props.fiscal_year_end,
        companyYTDDate: null,
        aggregateYTDDate: null,
        pnlTree: {},
        balTree: {},
    })
    // fetch calculations on load if not present in props
    useEffect(() => {
        if (state.fetch) {
            updateState();
            if (props.most_recent_fdu && props.most_recent_fdu.state === 'mappered') {
                toast(`New accounts need to be mapped for this client! Please map your recent file, ${props.most_recent_fdu.document}, in the Map Files page. Then click the Generate Reports button to update your client's reports.`)
            }
        };
    }, []);


    // Fetch Dashboard layouts based on report type, years vs monthly/ytd, displaying which charts on dashboard
    const fetchAllCalcs = async (args, { filters, currentNewFilters, report, ...prevState }) => {
        // save any checked metrics and dashboard changes
        // if (reportMetrics.editMetrics) { handleEditMetrics() }
        let previousFilterOptions, previousNaicsCode, previousRegion, previousRevenueBand, previousYear;
        let survey = null;
        let surveyCharts = [];
        if (Object.keys(prevState.filterOptions).length > 0) {
            previousFilterOptions = prevState.filterOptions;
            previousNaicsCode = previousFilterOptions.code;
            previousRegion = previousFilterOptions.region;
            previousRevenueBand = previousFilterOptions.revenue_band;
            previousYear = previousFilterOptions.year;
        }

        try {
            const calculations = await fetch('/api/v1/calculations/all_calcs', {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    company_id: props.id,
                    filters,
                    currentNewFilters,
                    report,
                    ...args // over write current state with new args
                }),
            });
            const calcsRes = await calculations.json();
            const { reportSpecific, filterOptions, ...newState } = calcsRes
            const finalFilterOptions = {};
            if (filterOptions && filterOptions['number_of_pros']) { finalFilterOptions['number_of_pros'] = filterOptions['number_of_pros'] }
            let finalNaicsCode, finalRegion, finalRevenueBand, finalYear;
            let naicsCode = filterOptions.code;
            let region = filterOptions.region;
            let revenueBand = filterOptions.revenue_band;
            let year = filterOptions.year;

            if (Object.keys(prevState.filterOptions).length > 0) {
                let mergedNaicsCodes = [...previousNaicsCode, ...naicsCode]
                let previousCodeNumbers = previousNaicsCode.map((n) => n[0])
                let newCodeNumbers = naicsCode.map((n) => n[0])
                let allNaicsCodeNumbers = previousCodeNumbers.concat(newCodeNumbers.filter((n) => !previousCodeNumbers.includes(n)))
                let sortedNaicsCodes = allNaicsCodeNumbers.sort()
                finalNaicsCode = sortedNaicsCodes.map((n) => {
                    let naicsCode = mergedNaicsCodes.find((mc) => mc[0] === n)
                    return naicsCode
                })
                finalRegion = previousRegion.concat(region.filter((r) => !previousRegion.includes(r)))
                finalRevenueBand = previousRevenueBand.concat(revenueBand.filter((rb) => !previousRevenueBand.includes(rb)))
                finalYear = previousYear.concat(year.filter((y) => !previousYear.includes(y)))
                finalFilterOptions.code = finalNaicsCode
                finalFilterOptions.region = finalRegion;
                finalFilterOptions.revenue_band = finalRevenueBand;
                finalFilterOptions.year = finalYear;
            } else {
                finalFilterOptions.code = naicsCode;
                finalFilterOptions.region = region;
                finalFilterOptions.revenue_band = revenueBand;
                finalFilterOptions.year = year;
            }

            setState(prevState => {
                return {
                    ...prevState,
                    ...newState,
                    filterOptions: finalFilterOptions,
                    reportSpecific,
                    loading: false,
                }
            });
        } catch (error) {
            console.log(error, 'error')
            setState(prevState => {
                return {
                    ...prevState,
                    loading: false
                }
            });
            toast.error('Sorry something went wrong. Please try again later.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }


    const updateState = async (args = {}) => {
        setState(prevState => {
            if (args.currentNewFilters) {
                fetchAllCalcs(args, prevState);
            } else {
                fetchAllCalcs(args, prevState);
            }

            if (args.report) {
                return {
                    ...prevState, report: args.report, loading: true
                }
            } else {
                return {
                    ...prevState, loading: true
                }
            }
        });
    };

    async function submitFilters(event, filters, currentNewFilters) {
        event.preventDefault();
        let allFilters = {
            filters: {
                code: filters.code,
                number_of_pros: filters.number_of_pros,
                region: filters.region,
                revenue_band: filters.revenue_band,
                year: filters.year,
                datatype: filters.datatype
            },
            currentNewFilters: currentNewFilters
        }
        updateState(allFilters);
    }

    const { loading, report, pageTitle, calendarYearEnd, fiscalYearStartMonth, peerviewMetricsYears, calcs, monthly_calcs, previousYearCalcs, yoy_calcs, previous_yoy_calcs, calcs_3_years_back, baseCalc, monthlyForecastCalcs, tree, pnlTree, balTree, yearRange, n, docs_n, filters, filterOptions, currentNewFilters, newFilterOptions, insufficient_data, insufficient_peer_data, reportSpecific, isMonthlyAvailable, fiscalYearEnd, companyYTDDate, aggregateYTDDate, companyReportSurvey, surveyTaken, surveyResults, force_rank } = state;

    const monthsObj = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec'
    }

    const revenueDisplay = (month, year) => {
        let title = ''
        let paranthesisTitle = ''
        let endYear = String(year).slice(-2)
        let value = ''
        let displayDifference = false;
        let diffVal = ''
        let diffText1, diffText2 = ''

        if (month === 'currentMonth') {
            displayDifference = true
            title = 'CURR. MONTH REVENUE'
            let totalRevenue = Object.values(state.monthly_calcs.you.total_revenue)
            let currentMonth = totalRevenue ? totalRevenue.indexOf(null) : 0
            if (currentMonth > 0) {
                paranthesisTitle = ` (${monthsObj[currentMonth]} ${endYear})`
                value = parseMoney(totalRevenue[currentMonth - 1])
                // ((Current Month Revenue - Last Month Revenue) / Last Month Revenue) * 100
                diffVal = ((totalRevenue[currentMonth - 1] - totalRevenue[currentMonth - 2]) / totalRevenue[currentMonth - 2]) * 100
                diffText1 = diffVal > 0 ? <span className='good-arrow'>&#9650; {diffVal.toFixed(2) + '%'}</span> : <span className='bad-arrow'>&#9660; {diffVal.toFixed(2) + '%'}</span>
                diffText2 = ' from last month'
            } else {
                paranthesisTitle = `(Dec ${endYear})`
            }
        }

        if (month === 'lastMonth') {
            title = 'LAST MONTH REVENUE'
            let totalRevenue = Object.values(state.monthly_calcs.you.total_revenue)
            let currentMonth = totalRevenue ? totalRevenue.indexOf(null) : 0
            if (currentMonth - 1 > 0) {
                paranthesisTitle = ` (${monthsObj[currentMonth - 1]} ${endYear})`
                value = parseMoney(totalRevenue[currentMonth - 2])
            }
        }

        if (month === 'ytd') {
            displayDifference = true
            title = 'THIS YEAR TOTAL REVENUE'
            paranthesisTitle = `(${year} YTD)`
            let totalRevenue = Object.values(state.monthly_calcs.you.total_revenue)
            let currentMonth = totalRevenue ? totalRevenue.indexOf(null) : 0
            let ytd = Object.values(state.monthly_calcs.you.total_revenue).reduce((a, b) => a + b)
            let previousYTD = Object.values(state.previousYearCalcs.you.total_revenue).slice(0, currentMonth).reduce((a, b) => a + b)

            diffVal = ((ytd - previousYTD) / previousYTD) * 100
            diffText1 = diffVal > 0 ? <span className='good-arrow'>&#9650; {diffVal.toFixed(2) + '%'}</span> : <span className='bad-arrow'>&#9660; {diffVal.toFixed(2) + '%'}</span>
            diffText2 = ' from last year (YTD)'
            value = parseMoney(ytd)
        }

        return (
            <div className='analysis-value-container'>
                <div className='analysis-value-title'><span className='analysis-value-first-title'>{title}</span><span className='analysis-value-second-title'>{paranthesisTitle}</span></div>
                <hr className='custom-report-line' />
                <span className='analysis-value'>{value}</span>
                {displayDifference ? <div className='difference-text'>{diffText1}<span>{diffText2}</span></div> : null}
            </div>
        )
    }

    const expensesDisplay = (month, year) => {
        let title = ''
        let paranthesisTitle = ''
        let endYear = String(year).slice(-2)
        let value = ''
        let displayDifference = false;
        let diffVal = ''
        let diffText1, diffText2 = ''

        if (month === 'currentMonth') {
            displayDifference = true
            title = 'CURR. MONTH EXPENSES'
            let totalOpEx = Object.values(state.monthly_calcs.you.total_operating_expenses)

            let currentMonth = totalOpEx ? totalOpEx.indexOf(null) : 0
            if (currentMonth > 0) {
                paranthesisTitle = ` (${monthsObj[currentMonth]} ${endYear})`
                value = parseMoney(totalOpEx[currentMonth - 1])
                // ((Current Month Revenue - Last Month Revenue) / Last Month Revenue) * 100
                diffVal = ((totalOpEx[currentMonth - 1] - totalOpEx[currentMonth - 2]) / totalOpEx[currentMonth - 2]) * 100
                diffText1 = diffVal > 0 ? <span className='bad-arrow'>&#9650; {diffVal.toFixed(2) + '%'}</span> : <span className='good-arrow'>&#9660; {diffVal.toFixed(2) + '%'}</span>
                diffText2 = ' from last month'
            } else {
                paranthesisTitle = `(Dec ${endYear})`
            }
        }

        if (month === 'lastMonth') {
            title = 'LAST MONTH EXPENSES'
            let totalOpEx = Object.values(state.monthly_calcs.you.total_operating_expenses)
            let currentMonth = totalOpEx ? totalOpEx.indexOf(null) : 0
            if (currentMonth - 1 > 0) {
                paranthesisTitle = ` (${monthsObj[currentMonth - 1]} ${endYear})`
                value = parseMoney(totalOpEx[currentMonth - 2])
            }
        }

        if (month === 'ytd') {
            displayDifference = true
            title = 'THIS YEAR TOTAL EXPENSES'
            paranthesisTitle = `(${year} YTD)`
            let totalOpEx = Object.values(state.monthly_calcs.you.total_operating_expenses)
            let currentMonth = totalOpEx ? totalOpEx.indexOf(null) : 0
            let ytd = Object.values(state.monthly_calcs.you.total_revenue).reduce((a, b) => a + b)
            let previousYTD = Object.values(state.previousYearCalcs.you.total_revenue).slice(0, currentMonth).reduce((a, b) => a + b)

            diffVal = ((ytd - previousYTD) / previousYTD) * 100
            diffText1 = diffVal > 0 ? <span className='bad-arrow'>&#9650; {diffVal.toFixed(2) + '%'}</span> : <span className='good-arrow'>&#9660; {diffVal.toFixed(2) + '%'}</span>
            diffText2 = ' from last year (YTD)'
            value = parseMoney(ytd)
        }

        return (
            <div className='analysis-value-container'>
                <div className='analysis-value-title'><span className='analysis-value-first-title'>{title}</span><span className='analysis-value-second-title'>{paranthesisTitle}</span></div>
                <hr className='custom-report-line' />
                <span className='analysis-value'>{value}</span>
                {displayDifference ? <div className='difference-text'>{diffText1}<span>{diffText2}</span></div> : null}
            </div>
        )
    }

    const axisFormat = (format, num) => {
        switch (format) {
            case 'percent':
                return `${Highcharts.numberFormat((num * 100), 0, '', ',')}%`;
            case 'decimal':
                return Highcharts.numberFormat(num, 0);
            case 'ratio':
                return `${Highcharts.numberFormat(num, 0, '.', ',')}:1`;
            case 'money':
                return num >= 0 ? `$${Highcharts.numberFormat(num / 1000, 0)}K` : `-$${Highcharts.numberFormat(Math.abs(num / 1000), 0)}K`;
            case 'wholeNumber':
                return Math.round(num)
        }
    }

    let revenueVsExpenseChartOptions = () => {
        let endYear = String(state.filters.year).slice(-2)
        let xAxisCats = Object.values(monthsObj).map(m => `${m} ${endYear}`)
        let totalRevenue = Object.values(state.monthly_calcs.you.total_revenue)
        let totalOpEx = Object.values(state.monthly_calcs.you.total_operating_expenses)

        return {
            accessibility: {
                description: 'Revenue vs Expense'
            },
            title: {
                align: 'left',
                text: 'Revenue vs Expense',
                style: {
                    color: '#909190'
                }
            },
            legend: {
                align: 'right',
                verticalAlign: 'top',
                x: -10,
                y: 0,
                floating: true,
                symbolRadius: 0,
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            xAxis: {
                categories: xAxisCats,
                gridLineDashStyle: 'ShortDot',
                gridLineWidth: 1,
                startOnTick: true,
                tickPosition: 'inside',
                tickInterval: 2,
                tickLength: 5,
                tickWidth: 1,
            },
            yAxis: {
                title: {
                    text: null
                },
                gridLineWidth: 1,
                labels: {
                    formatter: function () {
                        if (this.value) {
                            return axisFormat('money', this.value)
                        } else {
                            return 0
                        }
                    },
                },
            },
            plotOptions: {
                series: {
                    borderRadius: {
                        radius: 0
                    },
                    borderWidth: 0,
                }
            },
            series: [
                {
                    color: '#69B144',
                    name: 'Revenue',
                    data: totalRevenue,
                    type: 'column',
                    pointWidth: 7,
                    legendSymbol: 'rectangle',
                },
                {
                    color: '#dc5e42',
                    name: 'Expense',
                    data: totalOpEx,
                    type: 'column',
                    pointWidth: 7,
                    legendSymbol: 'rectangle',
                },
            ]
        };
    }

    let totalRevenueAreaChartOptions = (metric) => {
        let endYear = String(state.filters.year).slice(-2)
        let xAxisCats = Object.values(monthsObj).map(m => `${m} ${endYear}`)
        let totalRevenue = Object.values(state.monthly_calcs.you[metric])
        let finalTitle = metric === 'total_revenue' ? 'Total Revenue This Year' : 'Total Expenses This Year'
        let lengendLabel = metric === 'total_revenue' ? 'Total Revenue' : 'Total Expenses'
        let lineColor = metric === 'total_revenue' ? '#69B144' : '#e46b50'
        let areaColor = metric === 'total_revenue' ? '#d7e5ce' : '#fbddcc'

        return {
            chart: {
                type: 'area'
            },
            accessibility: {
                description: finalTitle
            },
            title: {
                align: 'left',
                text: finalTitle,
                style: {
                    color: '#909190'
                }
            },
            legend: {
                align: 'right',
                verticalAlign: 'top',
                x: -10,
                y: 0,
                floating: true,
                symbolRadius: 0
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            xAxis: {
                categories: xAxisCats,
                gridLineDashStyle: 'ShortDot',
                gridLineWidth: 1,
                startOnTick: true,
                tickPosition: 'inside',
                tickInterval: 2,
                tickLength: 5,
                tickWidth: 1,
            },
            yAxis: {
                title: {
                    text: null
                },
                gridLineWidth: 1,
                labels: {
                    formatter: function () {
                        if (this.value) {
                            return axisFormat('money', this.value)
                        } else {
                            return 0
                        }
                    },
                },
            },
            plotOptions: {
                area: {
                    fillColor: areaColor,
                    marker: {
                        enabled: true,
                        fillColor: '#FFFFFF',
                        lineWidth: 1,
                        radius: 2,
                        lineColor: null,
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    }
                }
            },
            series: [{
                color: lineColor,
                name: lengendLabel,
                data: totalRevenue
            }]
        };
    }

    let totalRevenueLineChartOptions = (metric) => {
        let endYear = String(state.filters.year).slice(-2)
        let xAxisCats = Object.values(monthsObj).map(m => `${m} ${endYear}`)
        let firstSeries = Object.values(state.monthly_calcs.you[metric])
        let secondSeries = Object.values(state.previousYearCalcs.you[metric])
        let firstSeriesName = metric === 'total_revenue' ? 'Revenue' : 'Expenses'
        let lineColor = metric === 'total_revenue' ? '#69B144' : '#e46b50'

        return {
            accessibility: {
                description: 'This year vs last year'
            },
            title: {
                align: 'left',
                text: 'This year vs last year',
                style: {
                    color: '#909190'
                }
            },
            legend: {
                align: 'right',
                verticalAlign: 'top',
                x: -10,
                y: 0,
                floating: true,
                symbolRadius: 0,
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            xAxis: {
                categories: xAxisCats,
                gridLineDashStyle: 'ShortDot',
                gridLineWidth: 1,
                startOnTick: true,
                tickPosition: 'inside',
                tickInterval: 2,
                tickLength: 5,
                tickWidth: 1,
            },
            yAxis: {
                title: {
                    text: null
                },
                gridLineWidth: 1,
                labels: {
                    formatter: function () {
                        if (this.value) {
                            return axisFormat('money', this.value)
                        } else {
                            return 0
                        }
                    },
                },
            },
            plotOptions: {
                line: {
                    marker: {
                        enabled: true,
                        fillColor: '#FFFFFF',
                        lineWidth: 1,
                        radius: 2,
                        lineColor: null,
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    }
                }
            },
            series: [
                {
                    color: lineColor,
                    name: firstSeriesName,
                    data: firstSeries,
                    type: 'line',
                    legendSymbol: 'rectangle',
                },
                {
                    color: '#d0cfcb',
                    name: 'Last Year',
                    data: secondSeries,
                    type: 'line',
                    dashStyle: 'LongDash',
                    legendSymbol: 'rectangle',
                },
            ]
        };
    }

    const revenueTop10Chart = () => {
        let totalRevenue = Object.values(state.monthly_calcs.you.total_revenue)
        let currentMonth = totalRevenue ? totalRevenue.indexOf(null) : 0

        return (
            <div className='top10-chart-container'>
                <div className='analysis-chart-title'>Revenue Mix - Top 10 Accounts</div>
                <div className='top10-chart-item'>
                    <div className='top10-details'><span>Total Revenue</span> <span>{parseMoney(totalRevenue[currentMonth - 1])}</span></div>
                    <div className='top10-green-background'></div>
                </div>
            </div>
        )
    }

    const getChildrenKeys = (opexTree) => {
        let totalRevenue = Object.values(state.monthly_calcs.you.total_revenue)
        let currentMonth = totalRevenue ? totalRevenue.indexOf(null) : 0

        if (opexTree && opexTree.children) {
            let childKeys = Object.keys(opexTree.children)
            let childObj = []

            if (childKeys.length > 0) {
                childKeys.forEach(child => {
                    opexTree.children[child].key = child
                    opexTree.children[child].value = state.monthly_calcs.you[child] ? state.monthly_calcs.you[child][currentMonth] : 0
                    childObj.push(opexTree.children[child])

                    if (opexTree.children[child].children) {
                        let moreChildren = getChildrenKeys(opexTree.children[child])
                        childObj = [...childObj, ...moreChildren]
                    }
                })
            }
            return childObj
        }
    }

    const expensesTop10Chart = () => {
        let totalRevenue = Object.values(state.monthly_calcs.you.total_revenue)
        let currentMonth = totalRevenue ? totalRevenue.indexOf(null) : 0
        let opExChildren = state.tree.pnl.children.total_operating_expenses
        let allOpexKeys = getChildrenKeys(opExChildren)
        let filteredExpenses = allOpexKeys.filter(a => !['total_people_costs', 'total_customers_costs', 'total_operations', 'total_compensation'].includes(a.key))
        let sortedExpenses = filteredExpenses.sort((a, b) => b.value - a.value)
        let top10Expenses = sortedExpenses.slice(0, 10)
        let top10WithPercents = top10Expenses.map(t => {
            let topExpense = top10Expenses[0]
            t.percent = (t.value / topExpense.value) * 100
            return t
        })

        return (
            <div className='top10-chart-container'>
                <div className='analysis-chart-title'>Top 10 Expense Accounts</div>
                {top10WithPercents.map(t => {
                    let barStyle = { 'width': t.percent + '%' }
                    return (
                        <div className='top10-chart-item'>
                            <div className='top10-details'><span>{t.copy}</span> <span>{parseMoney(t.value)}</span></div>
                            <div className='top10-red-background' style={barStyle}></div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const monthlyKPITable = () => {
        const rowMetrics = [
            {
                letter: 'A',
                color: 'light-blue',
                copy: 'INCOME',
                header: true,
                children: [
                    {
                        copy: 'Total Revenue',
                        key: 'total_revenue',
                        format: 'money'
                    }
                ],
            },
            {
                letter: 'B',
                color: 'light-green',
                copy: 'LAB & SUPPLIES',
                header: true,
                children: [
                    {
                        copy: 'Laboratory Fees',
                        key: 'laboratory_fees',
                        asterisk: true,
                        format: 'percentOfRevenue'
                    }
                ],
            },
            {
                letter: 'C',
                color: 'light-orange',
                copy: 'PAYROLL EXPENSE',
                header: true,
                children: [
                    {
                        copy: 'Salaries - Office',
                        key: 'office',
                        focus: 'dental',
                        asterisk: true,
                        format: 'percentOfRevenue'
                    },
                    {
                        copy: 'Salaries - Hygiene',
                        key: 'hygienist',
                        focus: 'dental',
                        asterisk: true,
                        format: 'percentOfRevenue'
                    },
                    {
                        copy: 'Salaries - Chairside/Other',
                        key: 'assistant',
                        focus: 'dental',
                        asterisk: true,
                        format: 'percentOfRevenue'
                    },
                    {
                        copy: 'Salaries - Office',
                        key: 'total_compensation_office',
                        focus: 'doctor',
                        asterisk: true,
                        format: 'percentOfRevenue'
                    },
                    {
                        copy: 'Salaries - Nurse',
                        key: 'total_compensation_nurse',
                        focus: 'doctor',
                        asterisk: true,
                        format: 'percentOfRevenue'
                    },
                    {
                        copy: 'Salaries - Other',
                        key: 'total_compensation_assistant',
                        focus: 'doctor',
                        asterisk: true,
                        format: 'percentOfRevenue'
                    }
                ],
            },
            {
                letter: 'D',
                color: 'purple',
                copy: 'DOCTOR PAY (W2)',
                header: true,
                children: [
                    {
                        copy: 'Dentist',
                        key: 'dentist',
                        focus: 'dental',
                        asterisk: true,
                        format: 'percentOfRevenue'
                    },
                    {
                        copy: 'Doctor',
                        key: 'total_compensation_doctor',
                        focus: 'doctor',
                        asterisk: true,
                        format: 'percentOfRevenue'
                    }
                ],
            },
            {
                letter: 'E',
                color: 'apple-red',
                copy: 'OVERHEAD COSTS',
                header: true,
                children: [
                    {
                        copy: 'Total Fixed Expenses',
                        key: 'total_administrative_costs',
                        asterisk: true,
                        format: 'percentOfRevenue'
                    }
                ],
            },
            {
                letter: 'F',
                color: 'lemon-yellow',
                copy: 'MARKETING',
                header: true,
                children: [
                    {
                        copy: 'Sales & Marketing',
                        key: 'total_customer_costs_sales_& marketing',
                        asterisk: true,
                        format: 'percentOfRevenue'
                    }
                ],
            },
            {
                letter: 'G',
                color: 'navy-blue',
                copy: 'RENT',
                header: true,
                children: [
                    {
                        copy: 'Rent & Facilities Costs',
                        key: 'rent_and_facilities_costs',
                        asterisk: true,
                        format: 'percentOfRevenue'
                    }
                ],
            },
            {
                letter: 'H',
                color: 'grass-green',
                copy: 'EBITDA',
                header: true,
                children: [
                    {
                        copy: 'EBITDA',
                        key: 'ebitda',
                        format: 'percentOfRevenue'
                    },
                    {
                        copy: 'EBITDA Dollar Figure',
                        key: 'ebitda',
                        format: 'money'
                    }
                ],
            },
            {
                letter: 'I',
                color: 'violet',
                copy: 'DOCTOR MARGIN',
                header: true,
                children: [
                    {
                        copy: 'Net Income (Before Non-Operating & Doctor Costs)',
                        key: 'operating_expenses_net_income',
                        format: 'percentOfRevenue'
                    }
                ],
            },
        ]

        const mappedTotal = (annualOrMonthly, row, youCalcs, compareCalcs, key, month = 1, years = []) => {
            let totalObj = {}
            totalObj['key'] = key


            // let youTotalRevenue = youCalcs['total_revenue'][month]
            // let compareTotalRevenue = compareCalcs['total_revenue'] ? compareCalcs['total_revenue'][month] : 0
            let youSum = 0;
            let compareSum = 0
            row.map_keys.forEach((c) => {
                youSum += youCalcs[c] ? youCalcs[c][month] : 0
                compareSum += compareCalcs[c] ? compareCalcs[c][month] : 0
            })
            totalObj['youVal'] = youSum
            totalObj['compareVal'] = compareSum
            // let yearStatusKey = 'status' + year
            // totalObj[yearStatusKey] = CommonSizeArrows(totalObj['you' + year], totalObj['avg' + year], key)


            // years.forEach(year => {
            //     let youTotalRevenue = you['total_revenue'][year]
            //     let compareTotalRevenue = avg['total_revenue'] ? avg['total_revenue'][year] : 0
            //     let youSum = 0;
            //     let compareSum = 0
            //     row.map_keys.forEach((c) => {
            //       youSum += you[c] ? you[c][year] : 0
            //       compareSum += avg[c] ? avg[c][year] : 0
            //     })
            //     totalObj[year] = youSum;
            //     totalObj['you' + year] = (youSum / youTotalRevenue) * 100;
            //     totalObj['avgVal' + year] = compareSum;
            //     totalObj['avg' + year] = (compareSum / compareTotalRevenue) * 100;
            //     let yearStatusKey = 'status' + year
            //     totalObj[yearStatusKey] = CommonSizeArrows(totalObj['you' + year], totalObj['avg' + year], key)
            // })
            return totalObj
        }

        const totalNAICSFocused = (annualOrMonthly, rows, youCalcs, compareCalcs, key, month = 1, years = []) => {
            let childrenObjs = rows.filter(r => r.parentKey === key)
            let totalObj = {}
            let childrenValues = []
            childrenObjs.forEach((c) => {
                childrenValues.push(mappedTotal(annualOrMonthly, c, youCalcs, compareCalcs, c.key, month))
            })


            let youTotalRevenue = youCalcs['total_revenue'][month]
            let compareTotalRevenue = compareCalcs['total_revenue'] ? compareCalcs['total_revenue'][month] : 0
            let youSum = 0;
            let compareSum = 0
            childrenValues.forEach((c) => {
                youSum += c['youVal'] ? c['youVal'] : 0
                compareSum += c['compareVal'] ? c['compareVal'] : 0
            })

            totalObj['youSum'] = youSum
            totalObj['youVal'] = (youSum / youTotalRevenue) * 100;
            totalObj['compareSum'] = compareSum
            totalObj['compareVal'] = (compareSum / compareTotalRevenue) * 100;
            totalObj['deltaVal'] = totalObj['youVal'] - totalObj['compareVal']
            // let yearStatusKey = 'status' + year
            // totalObj[yearStatusKey] = CommonSizeArrows(totalObj['you' + year], totalObj['avg' + year], key)


            // years.forEach(year => {
            //     let youTotalRevenue = you['total_revenue'][year]
            //     let compareTotalRevenue = avg['total_revenue'] ? avg['total_revenue'][year] : 0
            //     let youSum = 0;
            //     let compareSum = 0
            //     childrenValues.forEach((c) => {
            //       youSum += c[year] ? c[year] : 0
            //       compareSum += c['compareVal' + year] ? c['compareVal' + year] : 0
            //     })
            //     totalObj[year] = youSum;
            //     totalObj['you' + year] = (youSum / youTotalRevenue) * 100;
            //     totalObj['compareVal' + year] = compareSum;
            //     totalObj['avg' + year] = (compareSum / compareTotalRevenue) * 100;
            //     let yearStatusKey = 'status' + year
            //     totalObj[yearStatusKey] = CommonSizeArrows(totalObj['you' + year], totalObj['avg' + year], key)
            //   })
            return totalObj
        }

        const buildRows = () => {
            if (state.isMonthlyAvailable) {
                let totalRevenue = Object.values(state.monthly_calcs.you.total_revenue)
                let currentMonth = totalRevenue ? totalRevenue.indexOf(null) : 0
                let youRevenue = totalRevenue[currentMonth - 1]
                let compareRevenue = state.monthly_calcs.avg.total_revenue[currentMonth]
                let youCalcs = state.monthly_calcs.you
                let compareCalcs = state.monthly_calcs.avg
                let doctorCompareCalcs = state.monthly_calcs.docs_avg
                let allRows = []
                let naicsFocus = props.code.startsWith('6212') ? 'dental' : 'doctor'
                let doctorPNLRows = pnlRows(props.code)

                rowMetrics.forEach(row => {
                    allRows.push(row)
                    row.children.forEach(child => {
                        if ((child.focus && child.focus === naicsFocus) || !child.focus) {
                            if (child.format === 'money') {
                                child.youVal = state.monthly_calcs.you[child.key] ? state.monthly_calcs.you[child.key][currentMonth] : 0
                                child.compareVal = state.monthly_calcs.avg[child.key] ? state.monthly_calcs.avg[child.key][currentMonth] : 0
                                child.deltaVal = ((child.youVal - child.compareVal) / child.compareVal) * 100
                                child.status = CommonSizeArrows(child.deltaVal, 0, child.key)
                                child.arrow = 'goodUp'
                            }

                            if (child.format === 'percentOfRevenue') {
                                if (child.key === 'total_administrative_costs') {
                                    let rowObj = totalNAICSFocused('monthly', doctorPNLRows, youCalcs, doctorCompareCalcs, 'total_administrative_costs', currentMonth)
                                    child.arrow = 'goodDown'
                                    child = { ...child, ...rowObj }
                                } else if (child.key === 'operating_expenses_net_income') {
                                    let totalDirectCosts = totalNAICSFocused('monthly', doctorPNLRows, youCalcs, doctorCompareCalcs, 'total_direct_costs', currentMonth)
                                    let totalAdminCosts = totalNAICSFocused('monthly', doctorPNLRows, youCalcs, doctorCompareCalcs, 'total_administrative_costs', currentMonth)
                                    compareRevenue = state.monthly_calcs.docs_avg.total_revenue[currentMonth]

                                    child.youVal = ((youRevenue - totalDirectCosts['youSum'] - totalAdminCosts['youSum']) / youRevenue) * 100
                                    child.compareVal = ((compareRevenue - totalDirectCosts['compareSum'] - totalAdminCosts['compareSum']) / compareRevenue) * 100
                                    child.deltaVal = child.youVal - child.compareVal
                                    child.arrow = 'goodUp'
                                } else {
                                    let youVal = state.monthly_calcs.you[child.key] ? state.monthly_calcs.you[child.key][currentMonth] : 0
                                    let compareVal = state.monthly_calcs.avg[child.key] ? state.monthly_calcs.avg[child.key][currentMonth] : 0
                                    
                                    if (['office', 'hygienist', 'assistant', 'total_compensation_office', 'total_compensation_nurse', 'total_compensation_assistant', 'dentist', 'doctor'].includes(child.key)) {
                                        compareVal = state.monthly_calcs.docs_avg[child.key] ? state.monthly_calcs.docs_avg[child.key][currentMonth] : 0
                                        compareRevenue = state.monthly_calcs.docs_avg.total_revenue[currentMonth]
                                    }
                                    child.youVal = (youVal / youRevenue) * 100
                                    child.compareVal = (compareVal / compareRevenue) * 100
                                    child.deltaVal = child.youVal - child.compareVal
                                    child.arrow = 'goodDown'
                                }
                                child.status = CommonSizeArrows(child.youVal, child.compareVal, child.key)
                            }
                            allRows.push(child)
                        }
                    })
                })

                return (
                    allRows.map((row, i) => {
                        let you, compare, delta = ''
                        if (row.format === 'money') {
                            you = parseMoney(row.youVal)
                            compare = parseMoney(row.compareVal)
                            delta = row.deltaVal.toFixed(2) + '%'
                        } else if (row.format === 'percentOfRevenue') {
                            you = row.youVal.toFixed(2) + '%'
                            compare = row.compareVal.toFixed(2) + '%'
                            delta = row.deltaVal.toFixed(2) + '%'
                        }

                        let checkOrX = ['best', 'better', 'maintain'].includes(row.status) ? <div className='good-check'><CheckMark /></div> : <div className='bad-x'><XMark /></div>
                        let finalDelta = ''

                        if (['best', 'better', 'maintain'].includes(row.status) && row.arrow === 'goodUp') {
                            finalDelta = <div className='kpi-arrow good-arrow'>&#9650;</div>
                        } else if (['worst', 'worse'].includes(row.status) && row.arrow === 'goodUp') {
                            finalDelta = <div className='kpi-arrow bad-arrow'>&#9660;</div>
                        } else if (['best', 'better', 'maintain'].includes(row.status) && row.arrow === 'goodDown') {
                            finalDelta = <div className='kpi-arrow good-arrow'>&#9660;</div>
                        } else if (['worst', 'worse'].includes(row.status) && row.arrow === 'goodDown') {
                            finalDelta = <div className='kpi-arrow bad-arrow'>&#9650;</div>
                        }

                        let headerStyles = row.color ? row.color + ' kpi-letter-box' : ''


                        if (row.copy === 'INCOME') {
                            let monthText = monthsObj[currentMonth].toUpperCase() + ' ' + String(state.filters.year)
                            return (
                                <tr className='kpi-header-row'>
                                    <td className='kpi-header-cell'><div className='kpi-header-title'><div className={headerStyles}>{row.letter}</div><span className='kpi-header-title-metric'>{row.copy}</span></div></td>
                                    <td className='kpi-bold kpi-normal-left-border-cell'>{monthText}</td>
                                    <td colSpan='2'></td>
                                    <td className='kpi-bold kpi-normal-right-border-cell'>vs TARGET</td>
                                </tr>
                            )
                        } else if (row.header) {
                            return (
                                <tr className='kpi-header-row'>
                                    <td className='kpi-header-cell'><div className='kpi-header-title'><div className={headerStyles}>{row.letter}</div><span className='kpi-header-title-metric'>{row.copy}</span></div></td>
                                    <td className='kpi-normal-left-border-cell'></td>
                                    <td className='kpi-normal-cell'></td>
                                    <td className='kpi-checkorx-cell'></td>
                                    <td className='kpi-normal-right-border-cell'></td>
                                </tr>
                            )
                        } else {
                            return (
                                <tr className='kpi-normal-row'>
                                    <td className='kpi-header-cell'><span className='kpi-copy-cell'>{row.copy}</span>{row.asterisk ? <span>&#42;</span> : null}</td>
                                    <td className='kpi-normal-left-border-cell'>{you}</td>
                                    <td className='kpi-normal-cell'>{compare}</td>
                                    <td className='kpi-checkorx-cell'>{checkOrX}</td>
                                    <td className='kpi-normal-right-border-cell'><div className='vs-target-container'>{finalDelta} <div className='kpi-delta-val'>{delta}</div><div className='kpi-arrow'></div></div></td>
                                </tr>
                            )
                        }
                    })
                )
            } else {
                return <div>You must have Monthly data available to view this page.</div>
            }
        }
        return (
            <div>
                <table className='kpi-results-table'>
                    <thead>
                        <tr>
                            <td></td>
                            <td className='kpi-normal-left-border-cell'>RESULT</td>
                            <td className='kpi-normal-cell' colSpan='2'>TARGET</td>
                            <td className='kpi-normal-right-border-cell'>TREND</td>
                        </tr>
                    </thead>
                    <tbody>
                        {buildRows()}
                    </tbody>
                </table>
                <div className='asterisk-note'>&#42; For this metric, a result below target is favorable</div>
            </div>
        )
    }

    return (
        <div className={`money-content`} style={{ opacity: (state.loading ? 0.6 : 1) }} >
            <div id='sticky-side-btns'>
                <CustomReportPDFandPrint
                    page={'customereport'}
                    componentRefs={componentRefs}
                />
            </div>
            <div className='money-dropdown-container'>
                <ToastContainer
                    position='top-right'
                    autoClose={30000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    theme='colored'
                    pauseOnHover
                />
                <DropdownFilter
                    clas={'money-dropdown-filters'}
                    calendarYearEnd={state.calendarYearEnd}
                    current={state.filters}
                    currentNewFilters={state.currentNewFilters}
                    monthlyCompareWithOptions={state.monthlyCompareWithOptions}
                    naicsCode={props.code}
                    newFilterOptions={state.newFilterOptions}
                    options={state.filterOptions}
                    youAnnualOptions={state.youAnnualOptions}
                    youMonthlyOptions={state.youMonthlyOptions}
                    peerAnnualOptions={state.peerAnnualOptions}
                    peerMonthlyOptions={state.peerMonthlyOptions}
                    onClick={submitFilters}
                    report={state.report}
                />
            </div>
            {calcs ?
                (<Data.Provider value={{ calcs, monthly_calcs }}>
                    <Filters.Provider value={{ filters, filterOptions, currentNewFilters, newFilterOptions }} >
                        <div ref={componentRefs} style={{ 'marginTop': '30px'} }>
                            <div className='custom-report-pdf-portrait'>
                                <h1 className='analysis-page-title'>KPI Results - Monthly Analysis</h1>
                                <div>
                                    {monthlyKPITable()}
                                </div>
                            </div>
                            <div className='custom-report-pdf-portrait'>
                                <div>
                                    <h1 className='analysis-page-title'>Revenue Analysis</h1>
                                    <hr className='custom-report-line' />
                                </div>
                                {state.isMonthlyAvailable
                                    ? (
                                        <>
                                            <div className='analysis-values'>
                                                {revenueDisplay('currentMonth', filters.year)}
                                                {revenueDisplay('lastMonth', filters.year)}
                                                {revenueDisplay('ytd', filters.year)}
                                            </div>
                                            <div className='analysis-page-charts'>
                                                {revenueTop10Chart()}
                                                <div>
                                                    <HighchartsReact highcharts={Highcharts} containerProps={{ style: { width: '370px', minHeight: '370px' } }} options={revenueVsExpenseChartOptions()} />
                                                </div>
                                                <div>
                                                    <HighchartsReact highcharts={Highcharts} containerProps={{ style: { width: '370px', minHeight: '370px' } }} options={totalRevenueAreaChartOptions('total_revenue')} />
                                                </div>
                                                <div>
                                                    <HighchartsReact highcharts={Highcharts} containerProps={{ style: { width: '370px', minHeight: '370px' } }} options={totalRevenueLineChartOptions('total_revenue')} />
                                                </div>
                                            </div>
                                        </>
                                    )
                                    : <div>You must have Monthly data available to view this page.</div>}
                            </div>
                            <div className='custom-report-pdf-portrait'>
                                <div>
                                    <h1 className='analysis-page-title'>Operating Expense Analysis</h1>
                                    <hr className='custom-report-line' />
                                </div>
                                {state.isMonthlyAvailable
                                    ? (
                                        <>
                                            <div className='analysis-values'>
                                                {expensesDisplay('currentMonth', filters.year)}
                                                {expensesDisplay('lastMonth', filters.year)}
                                                {expensesDisplay('ytd', filters.year)}
                                            </div>
                                            <div className='analysis-page-charts'>
                                                {expensesTop10Chart()}
                                                <div>
                                                    <HighchartsReact highcharts={Highcharts} containerProps={{ style: { width: '370px', minHeight: '370px' } }} options={revenueVsExpenseChartOptions()} />
                                                </div>
                                                <div>
                                                    <HighchartsReact highcharts={Highcharts} containerProps={{ style: { width: '370px', minHeight: '370px' } }} options={totalRevenueAreaChartOptions('total_operating_expenses')} />
                                                </div>
                                                <div>
                                                    <HighchartsReact highcharts={Highcharts} containerProps={{ style: { width: '370px', minHeight: '370px' } }} options={totalRevenueLineChartOptions('total_operating_expenses')} />
                                                </div>
                                            </div>
                                        </>
                                    )
                                    : <div>You must have Monthly data available to view this page.</div>}
                            </div>
                        </div>
                    </Filters.Provider>
                </Data.Provider>)
                : (<div style={{ marginBottom: '400px' }} >
                    <LoaderGraphic />
                </div>)}
        </div >
    )
}

export default CustomReport;