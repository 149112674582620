import React, { useContext } from 'react';
import { Data, Filters } from '../contexts';
import { parseMoney } from '../../utils';
import { KFIsCommonSizeArrows, MonthlyKFIsValues } from '../report_components/MoneyHelpers';

const MoneyMonthlyKFI = (props) => {
    const { calcs, previousYearCalcs, previous_yoy_calcs } = useContext(Data);
    const allFilters = useContext(Filters);
    const { currentNewFilters } = useContext(Filters);
    let compareWith = currentNewFilters.compare_with;
    const calcsYou = calcs['you'];
    const previousYearCalcsYou = previousYearCalcs['you'];
    const calcsPeers = compareWith === 'Default:None' ? null : calcs['avg'];
    const previousYearCalcsPeers = compareWith === 'Default:None' ? null : previousYearCalcs['avg'];
    const previousYOYYou = previous_yoy_calcs['you'];
    let numberOfMonths = 0
    let useableMonths = Object.values(calcs['you']['cash']);
    useableMonths.forEach(thing => thing !== null ? numberOfMonths += 1 : null)
    const monthNames = Object.values(props.monthsObj)
    let arrowCellClass = props.pdf ? 'common-size-arrow-pdf' : 'common-size-arrow';
    let commonSizeValCellClass = props.pdf ? 'common-size-cell-pdf' : 'common-size-cell';

    let numberCompare;
    if (currentNewFilters && compareWith === 'Default:YOY') {
        numberCompare = 'n = 1'
    } else {
        numberCompare = `n = ${props.n}`
    }

    function formatPercent(value) {
        if (value) {
            return (Math.round(value * 10000) / 100) + '%';
        } else {
            return '-'
        }
    }

    function formatDays(value) {
        if (value) {
            // We want to check if zero has a negative sign or not. If it does we want to get rid of the negative sign.
            // We don't want to see -0.0 days.
            let checkPositiveValue = value.toFixed(1)
            if (checkPositiveValue == 0) {
                return `${Math.abs(checkPositiveValue)} days`;
            } else {
                return `${checkPositiveValue} days`
            }
        } else {
            return '-'
        }
    }

    function formatTwoDecimal(value) {
        if (value) {
            return value.toFixed(2);
        } else {
            return '-'
        }
    }

    function undefinedToNull(value) {
        if (typeof value == 'undefined') {
            return value = null
        } else {
            return value
        }
    }

    function rowFormatter(amount, key) {
        // Glenn wanted to add Taxes - Federal & State to the Analysis table
        const roundNumber = ['number_of_holes_on_golf_course'];
        const ratio = ['current_ratio', 'net_working_capital_ratio', 'debt_to_equity', 'senior_debt_to_ebitda', 'ebitda_to_annual_interest']
        const days = ['ar_days', 'ap_days']
        const money = ['net_working_capital', 'operating_expenses_net_income', 'interest_and_other_expenses', 'total_other_costs_taxes_- federal & state', 'depreciation_and_amortization', 'ebitda', 'maintenace_per_golf_hole']
        const twoDecimal = ['asset_turnover_ratio', 'inventory_turnover_ratio']
        const percent = ['roa', 'roe', 'roce', 'gross_profit_common_size_revenue', 'operating_profit_common_size_revenue']

        if (roundNumber.includes(key)) {
            return _.isNumber(amount) ? Math.round(amount) : '-'
        } else if (ratio.includes(key)) {
            return formatTwoDecimal(amount)
        } else if (days.includes(key)) {
            return formatDays(amount)
        } else if (money.includes(key)) {
            return parseMoney(amount);
        } else if (twoDecimal.includes(key)) {
            return formatTwoDecimal(amount)
        } else if (percent.includes(key)) {
            return formatPercent(amount, key)
        } else {
            return _.isNumber(amount) ? '-' : ''
        };
    }

    function CommonSizeArrowsHelper(props) {

        if (props.valueYou) {
            let arrow = KFIsCommonSizeArrows(props.valueYou, props.valueCompareTo, props.keyArrBuilder)
            if (arrow === 'better' || arrow === 'best') {
                arrow = <p className='common-size-arrow-green' style={{ margin: 0, color: 'green' }}>&#9650;</p>
            } else if (arrow === 'worse' || arrow === 'worst') {
                arrow = <p className='common-size-arrow-red' style={{ margin: 0, color: 'red' }}>&#9660;</p>
            } else {
                arrow = ''
            }

            return (<td className={arrowCellClass}>
                {arrow}
            </td>)
        } else {
            return (<td className={arrowCellClass}></td>)
        };
    }


    let monthlyRows = []
    const buildRows = () => {
        props.rows.forEach((row, i) => {
            let paddingLeft = { paddingLeft: row.paddingLeft }
            let paddingTop = props.pdf ? { paddingTop: '8px' } : { paddingTop: '20px' }
            let borderBottom = { borderBottom: '2px solid #000000' }
            let titleStyle = row['parentKey'] === 'parent' ? { ...paddingLeft, ...borderBottom, ...paddingTop } : {}
            let hideRow = '';
            let zeroYears = [];

            let rowChecked = row.displayChart && row.checked && props.editMetrics ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''

            if (row.key.includes('title')) {
                monthlyRows.push(
                    <tr key={`kfi-${row.key}`} className='kfi-analysis-tr' onClick={() => props.editMetrics ? props.handleSelectAndLayout('money', 'kfis', row.relatedCheckKey) : null}>
                        {!props.pdf ? <td>{rowChecked}</td> : null}
                        <th style={row.copy === 'Liquidity' ? {} : paddingTop}>
                            {row.copy}
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <td className={commonSizeValCellClass}></td>
                        {compareWith === 'Default:None' ? null : <td className={arrowCellClass}></td>}
                        {compareWith === 'Default:None' ? null : <td className={commonSizeValCellClass}></td>}
                    </tr>
                )
            } else {
                // let key = entry[0]; // Ex. Current Ratio
                // let value = entry[1]; // Ex. current_ratio
                // We need to get the sum of 12 trailing/previous month values for each of these keys.
                // looks like { current_ratio: { 1: 100, 2: 300, ... }, roa: { 1: 0, 2: 10, ...} }
                const trailing12MonthsKeys = ['Return on Assets (ROA)', 'Return on Equity (ROE)', 'Return on Capital Employed (ROCE)', 'Asset Turnover Ratio', 'Inventory Turnover Ratio', 'Accounts Receivable Days', 'Accounts Payable Days', 'Senior Debt / EBITDA', 'EBITDA / Annual Interest'];
                const trailing12MonthsKey = trailing12MonthsKeys.includes(row.copy);
                let youVals, compareVals, ytdRowYou, ytdRowComparison;

                if (Object.values(previousYearCalcsYou).length > 0) {
                    let { youValues, compareValues, ytdYou, ytdCompare } = MonthlyKFIsValues(row, numberOfMonths, compareWith, calcs, previousYearCalcs, previous_yoy_calcs, props.forecastVals)
                    youVals = youValues;
                    compareVals = compareValues;
                    ytdRowYou = ytdYou;
                    ytdRowComparison = ytdCompare;
                }

                const buildRow = (row, trailing12MonthsKey, youValues, compareValues, ytdYou, ytdCompare) => {
                    let valuesArr = [];
                    let valuesArrCompare = [];
                    let monthArr = Object.keys(props.monthsObj);

                    if (youValues) {
                        monthArr.forEach(month => {
                            valuesArr.push(undefinedToNull(youValues[month]))
                            valuesArrCompare.push(compareValues ? undefinedToNull(compareValues[month]) : null)
                        })
                    }

                    let hideRow = '';
                    let zeroMonths = [];
                    let comparisonZM = false
                    let youZM = false
                    ytdCompare === 0 || ytdCompare === undefined ? comparisonZM = true : null

                    monthArr.forEach(m => {
                        valuesArr[m] === 0 || valuesArr[m] === null ? youZM = true : null
                        comparisonZM && youZM ? zeroMonths.push(m) : null
                    })

                    zeroMonths.length === 12 ? hideRow = row.copy : null

                    let rowStyle, dataStyle = {}
                    if (row.copy === 'EBITDA') {
                        rowStyle = { borderTop: '1px solid black' }
                        dataStyle = { textAlign: 'right', paddingLeft: '0px' }
                    } else if (row.copy === hideRow) {
                        rowStyle = { ...rowStyle, display: 'none' }
                    } else {
                        dataStyle = { textAlign: 'right' }
                    }

                    let rowChecked = row.displayChart && row.checked && props.editMetrics ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''
                    // Glenn wants to add (T12) to row labels in order to show trailing 12 months calculations are being used in the table.
                    return (
                        <React.Fragment key={Math.random(89)}>
                            <tr key={`kfi-${row.key}`} style={rowStyle} onClick={() => props.editMetrics ? props.handleSelectAndLayout('money', 'kfis', row.relatedCheckedKey) : null}>
                                {!props.pdf ? <td>{rowChecked}</td> : null}
                                <td
                                    className={row.copy === 'EBITDA' ? 'table-copy' : 'non-bold-table-copy'}
                                    style={dataStyle}
                                >
                                    {trailing12MonthsKey ? `${row.copy} (T12)` : row.copy}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {rowFormatter(valuesArr[0], row.key)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {rowFormatter(valuesArr[1], row.key)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {rowFormatter(valuesArr[2], row.key)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {rowFormatter(valuesArr[3], row.key)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {rowFormatter(valuesArr[4], row.key)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {rowFormatter(valuesArr[5], row.key)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {rowFormatter(valuesArr[6], row.key)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {rowFormatter(valuesArr[7], row.key)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {rowFormatter(valuesArr[8], row.key)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {rowFormatter(valuesArr[9], row.key)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {rowFormatter(valuesArr[10], row.key)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {rowFormatter(valuesArr[11], row.key)}
                                </td>
                                {props.page === 'money' ?
                                    <>
                                        <td className={commonSizeValCellClass} style={{ textAlign: 'right' }}>
                                            {rowFormatter(ytdYou, row.key)}
                                        </td>
                                        {compareWith !== 'Default:None' ? <CommonSizeArrowsHelper
                                            valueYou={ytdYou}
                                            valueCompareTo={ytdCompare}
                                            style={''}
                                            keyArrBuilder={row.copy}
                                            showMeComparisonArray={valuesArrCompare}
                                        /> : null}
                                        {compareWith !== 'Default:None' ? <td className={commonSizeValCellClass} style={{ textAlign: 'right' }}>{rowFormatter(ytdCompare, row.key)}</td> : null}
                                    </>
                                    : null}
                            </tr>
                        </React.Fragment>
                    )
                };

                monthlyRows.push(buildRow(row, trailing12MonthsKey, youVals, compareVals, ytdRowYou, ytdRowComparison))
            }
        })
        return monthlyRows;
    }

    let YOYyearDisplay = props.calendarYearEnd ? props.year - 1 : props.year
    let firstTotal = currentNewFilters.report_period === 'Trailing 12' ? `T12 ${props.year}` : 'You YTD'
    let secondTotal = null
    if (compareWith.includes('Forecast') && currentNewFilters.report_period !== 'Trailing 12') {
        secondTotal = props.monthlyCompareWithTitle
    } else if (compareWith === 'Default:Peers' && currentNewFilters.report_period !== 'Trailing 12') {
        secondTotal = 'Peers Avg'
    } else if (compareWith === 'Default:YOY' && currentNewFilters.report_period !== 'Trailing 12') {
        secondTotal = `YOY ${YOYyearDisplay}`
    } else if (currentNewFilters.report_period === 'Trailing 12' && compareWith !== 'Default:None') {
        secondTotal = `T12 ${props.year - 1}`
    }

    const buildMonthlyKFITable = () => {
        if (props.pdf) {
            let pageViewClass = 'landscape-view'

            return (
                <div className={pageViewClass}>
                    <div className='report-pdf-landscape'>
                        <div>
                            <h1 className='report-table-title'>{`Key Financial Indicators - ${props.clientName}`}</h1>
                            <div className='money-pdf-table-div'>
                                <table className='money-monthly-pdf-table'>
                                    <thead>
                                        <tr className='kfi-report-thead'>
                                            <td></td>
                                            <td style={{ textAlign: 'center' }}>{monthNames[0]}</td>
                                            <td style={{ textAlign: 'center' }}>{monthNames[1]}</td>
                                            <td style={{ textAlign: 'center' }}>{monthNames[2]}</td>
                                            <td style={{ textAlign: 'center' }}>{monthNames[3]}</td>
                                            <td style={{ textAlign: 'center' }}>{monthNames[4]}</td>
                                            <td style={{ textAlign: 'center' }}>{monthNames[5]}</td>
                                            <td style={{ textAlign: 'center' }}>{monthNames[6]}</td>
                                            <td style={{ textAlign: 'center' }}>{monthNames[7]}</td>
                                            <td style={{ textAlign: 'center' }}>{monthNames[8]}</td>
                                            <td style={{ textAlign: 'center' }}>{monthNames[9]}</td>
                                            <td style={{ textAlign: 'center' }}>{monthNames[10]}</td>
                                            <td style={{ textAlign: 'center' }}>{monthNames[11]}</td>
                                            <td className={commonSizeValCellClass}>{firstTotal}</td>
                                            {compareWith === 'Default:None' ? null : <td className={arrowCellClass}></td>}
                                            {secondTotal
                                                ? <td className={commonSizeValCellClass}>{secondTotal}</td>
                                                : null
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {buildRows()}
                                        <tr><td className='footer-cell'><i>{numberCompare}</i></td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <>
                    <div className={props.page === 'money' ? 'money-report-card monthly-sheet' : ''}>
                        <div className={props.page === 'forecast' ? 'big-table-title report-card monthly-sheet' : 'money-report-container'}>
                            <table className={props.page === 'forecast' ? 'sheets' : 'monthly-report-table sheets'} id={`monthly-kfi-table-${props.year}`}>
                                <thead>
                                    <tr className='kfi-report-thead'>
                                        <td className='edit-metrics-cell' onClick={() => props.handleEditMetrics()}>{props.editMetrics ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td>
                                        <td></td>
                                        <td style={{ textAlign: 'center' }}>{monthNames[0]}</td>
                                        <td style={{ textAlign: 'center' }}>{monthNames[1]}</td>
                                        <td style={{ textAlign: 'center' }}>{monthNames[2]}</td>
                                        <td style={{ textAlign: 'center' }}>{monthNames[3]}</td>
                                        <td style={{ textAlign: 'center' }}>{monthNames[4]}</td>
                                        <td style={{ textAlign: 'center' }}>{monthNames[5]}</td>
                                        <td style={{ textAlign: 'center' }}>{monthNames[6]}</td>
                                        <td style={{ textAlign: 'center' }}>{monthNames[7]}</td>
                                        <td style={{ textAlign: 'center' }}>{monthNames[8]}</td>
                                        <td style={{ textAlign: 'center' }}>{monthNames[9]}</td>
                                        <td style={{ textAlign: 'center' }}>{monthNames[10]}</td>
                                        <td style={{ textAlign: 'center' }}>{monthNames[11]}</td>
                                        <td className='common-size-cell'>{firstTotal}</td>
                                        {compareWith === 'Default:None' ? null : <td className='common-size-arrow'></td>}
                                        {secondTotal
                                            ? <td className='common-size-cell'>{secondTotal}</td>
                                            : null
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {buildRows()}
                                </tbody>
                                <tfoot>
                                    <tr><td><i>{numberCompare}</i></td></tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </>
            )
        }
    }


    return buildMonthlyKFITable()
};

export default MoneyMonthlyKFI;
