import React, { useState, useContext } from 'react';
import { Data, Filters } from './contexts';
import MoneyReportSheets from './report_components/MoneyReportSheets';
import FunctionalExpenses from './FunctionalExpenses';
import { PnlValues, BalanceSheetValues } from './monthly/forecast/ForecastHelpers';
import MoneyCashFlow from './report_components/MoneyCashFlow';
import MoneyKFI from './report_components/MoneyKFI';
import { dentalPNLFormat, doctorPNLFormat } from './NAICSFocusedReport/NAICSFocusedReport';
import MonthlyHistoricTable from './monthly/MonthlyHistoricTable';
import MonthlyHistoricCashFlow from './monthly/MonthlyHistoricCashFlow';
import MoneyMonthlyKFI from './monthly/MoneyMonthlyKFI';
import MoneyYTD from './monthly/MoneyYTD';
import MoneyYTDCashFlow from './monthly/MoneyYTDCashFlow';
import MoneyYTDKFI from './monthly/MoneyYTDKFI';
import SurveyResponses from './SurveyResponses';
import ParentDashboard from './ParentDashboard';
import EditParentChildDashboard from './utils/EditParentChildDashboard';
import DashboardViewControls from './utils/DashboardViewControls';
import { getDashBoardPagesPDF } from './PDFDashboardHelper';
import Modal from 'react-modal';
import moment from 'moment';

const naicsPNLTitle = (naicsCode) => {
  if (naicsCode.startsWith('6212')) {
    return 'Dental P&L'
  } else if (naicsCode.startsWith('6211') || naicsCode.startsWith('6213')) {
    return 'Doctor P&L'
  } else if (naicsCode.startsWith('813')) {
    return 'Functional Expenses'
  } else {
    return null
  }
}

const monthsObjBuilder = (fiscalYearStartMonth = 1, reportPeriod = 'Trailing 12', year = null) => {
  const monthsObj = {
    1: 'JAN',
    2: 'FEB',
    3: 'MAR',
    4: 'APR',
    5: 'MAY',
    6: 'JUN',
    7: 'JUL',
    8: 'AUG',
    9: 'SEP',
    10: 'OCT',
    11: 'NOV',
    12: 'DEC'
  }
  if (reportPeriod === 'Trailing 12') {
    let months = Object.values(monthsObj)
    const d = new Date();
    let monthIndex = d.getMonth();
    let startMonths = months.splice(monthIndex)
    let nextMonth = 1
  
    startMonths.forEach((f, i) => {
      monthsObj[i + 1] = f + ' ' + Number(year - 1)
      nextMonth++
    })

    months.forEach((f, i) => {
      monthsObj[nextMonth] = f + ' ' + year
      nextMonth++
    })

  } else {
    if (fiscalYearStartMonth !== 1) {
      let months = Object.values(monthsObj)
      let startMonths = months.splice(fiscalYearStartMonth - 1)
      let fiscalMonths = [...startMonths, ...months]
      fiscalMonths.forEach((f, i) => {
        monthsObj[i + 1] = f
      })
    }
  }

  return monthsObj
}

const compareWithRowKeys = {
  'None': null,
  'Practice': 'practice_avg',
  'Peers': 'avg',
  'NSCHBC': 'nschbc'
}

const createCompareWithTitle = (compareWith) => {
  let colonIndex = compareWith.indexOf(':')
  return compareWith.slice(colonIndex + 1)
}

const MoneyReport = React.forwardRef((props, ref) => {
  const { calcs } = useContext(Data);
  const allFilters = useContext(Filters);
  const [activeTab, setActiveTab] = useState('pnl');
  const [activeModal, setActiveModal] = useState('');
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const navbar = window.navbar.navbar;
  const currentNewFilters = allFilters.currentNewFilters;
  const compareWith = allFilters.currentNewFilters.compare_with;
  const calcsAvgType = compareWithRowKeys[compareWith]
  const forecastVals = {}
  let monthlyCompareWithTitle = createCompareWithTitle(compareWith)

  let dashboardLayoutType = currentNewFilters.display_columns_by === 'Years' && currentNewFilters.report_period !== 'Year to Date' ? 'Years'
    : currentNewFilters.display_columns_by === 'Years' && currentNewFilters.report_period === 'Year to Date' ? 'Year to Date'
      : 'Months';

  const naicsFocusedReport = naicsPNLTitle(props.naicsCode)
  const pnlRows = props.naicsCode.startsWith('6212') ? dentalPNLFormat : props.naicsCode.startsWith('6211') || props.naicsCode.startsWith('6213') ? doctorPNLFormat : null;
  const firmName = props.name ? props.name.slice(0, props.name.indexOf('/') - 1) : ''
  const monthsObj = monthsObjBuilder(props.fiscalYearStartMonth, currentNewFilters.report_period, allFilters.filters.year)
  let monthLabels = props.calendarYearEnd ? ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'] : ['Month 1', 'Month 2', 'Month 3', 'Month 4', 'Month 5', 'Month 6', 'Month 7', 'Month 8', 'Month 9', 'Month 10', 'Month 11', 'Month 12']
  if (currentNewFilters.report_period === 'Trailing 12') {
    monthLabels = Object.values(monthsObj)
  }
  const openVideoModal = (reportVal) => {
    setActiveModal(reportVal);
    setVideoModalOpen(true);
  }

  const closeVideoModal = () => {
    setVideoModalOpen(false);
  }

  const VideoModal = () => {
    let modalPic = ''
    let modalTitle = ''
    let videoLink = null

    if (activeModal === 'dashboard') {
      modalTitle = !props.planAccess.money.dashboard ? 'Upgrade to Access the Dashboard' : 'Unlock Report to Access the Dashboard'
      videoLink = 'https://www.youtube.com/embed/F-iBTc4462k?autoplay=1&mute=1'
    }

    if (activeModal === 'kfis') {
      modalTitle = !props.planAccess.money.kfis ? "Upgrade to Access the KFI's" : "Unlock Report to Access the KFI's"
      modalPic = require('../../../assets/images/PremiumKFIs.jpg')
    }

    const hrefBuilder = (subdomain, path) => {
      const domainSplit = document.location.host.split('.');
      domainSplit[0] = subdomain;
      return location.protocol + '//' + domainSplit.join('.') + path;
    };
    const manageSubscriptionsURL = hrefBuilder(navbar.subdomain, '/subscriptions')
    const unlockReportsURL = hrefBuilder(navbar.subdomain, navbar.unlock_reports_url)
    const linkText = navbar.report_credits ? "Click Here to Go Unlock Reports" : "Click Here to Buy More Report Credits"
    const purchaseText = "Buy more report credits."

    return (
      <Modal
        className={{
          base: 'video-modal-content hide-on-print',
          afterOpen: 'video-modal-content_after-open hide-on-print',
          beforeClose: 'video-modal-content_before-close hide-on-print',
        }}
        overlayClassName={{
          base: 'overlay-base hide-on-print',
          afterOpen: 'overlay-base_after-open hide-on-print',
          beforeClose: 'overlay-base_before-close hide-on-print',
        }}
        isOpen={videoModalOpen}
        onRequestClose={closeVideoModal}
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={0}
        contentLabel='Video About Report Features'
        ariaHideApp={false}
      >
        <div>
          <h1>{modalTitle}</h1>
          {videoLink
            ? <iframe width="560" height="315" src={videoLink} title="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            : <img className='premium-pic-popup' src={modalPic}></img>
          }
          {(props.planAccess.money.dashboard && !navbar.reports_unlocked || props.planAccess.money.kfis && !navbar.reports_unlocked)
            ? (<div className='unlock-report-container'>
              <h2>Unlock this Client's Reports?</h2>
              <p>{`You have ${navbar.report_credits} Report Credits left.`} <a href={unlockReportsURL}>{linkText}</a></p>
            </div>)
            : null}
          <a className='manage-subscription-link-popup' href={manageSubscriptionsURL}>Click Here to View Plans &#38; Manage Your Subscription</a>
          <p>Click anywhere outside to close this pop-up.</p>
        </div>
      </Modal>
    )
  }

  const handleActiveTab = (section) => {
    switch (section) {
      // Open a modal if survey has not been completed
      // Just display charts if survey is completed
      case 'kfis':
        if (navbar.user_type === 'Admin' || navbar.reports_unlocked && props.planAccess.money.kfis) {
          setActiveTab('kfis')
        } else if (!props.planAccess.money.kfis && navbar.user_type !== 'Admin') {
          openVideoModal('kfis')
        } else if (!navbar.reports_unlocked && props.planAccess.money.kfis && navbar.user_type !== 'Admin') {
          openVideoModal('kfis')
        }
        break;
      case 'survey':
        setActiveTab('survey')
        !props.surveyTaken ? props.handleSurveyModal(true) : props.handleSurveyModal(false)
        break;
      case 'dashboard':
        let planType = navbar.plan_type;
        if (navbar.user_type === 'Admin' || navbar.reports_unlocked && props.planAccess.money.dashboard) {
          setActiveTab('dashboard')
        } else if (!props.planAccess.money.dashboard && navbar.user_type !== 'Admin') {
          openVideoModal('dashboard')
        } else if (!navbar.reports_unlocked && props.planAccess.money.dashboard && navbar.user_type !== 'Admin') {
          openVideoModal('dashboard')
        }
        break;
      default:
        setActiveTab(section)
        break;
    }
  }

  const closeSurveyModal = () => {
    props.setSurveyModalOpen(false);
  };

  if (compareWith.includes('Forecast') && currentNewFilters.display_columns_by === 'Months') {
    let pnlValueSet = []
    let balValueSet = []
    // Follow same logic used in buildSheetData in MonthlyForecast
    let forecastScenarios = calcs['forecast_scenario']
    let baseForecast = calcs['forecast_scenario'][0]
    let balanceSheetBase = new BalanceSheetValues(
      baseForecast.state.balance_sheet,
      baseForecast.state.balance_sheet.children.net_worth.operating_expenses_net_income,
      baseForecast.state.pnl.children.total_other_costs.depreciation_and_amortization
    );
    let originalDepreciation = baseForecast.state.balance_sheet.children.total_fixed_assets.less_accumulated_depreciation

    forecastScenarios.forEach((forecast, index) => {
      let pnlVals = new PnlValues(forecast, 0, null, false, false)
      pnlValueSet.push(pnlVals);
      const balVals = new BalanceSheetValues(
        forecast.state.balance_sheet,
        pnlVals.operating_expenses_net_income,
        pnlVals.depreciation_and_amortization,
        balanceSheetBase,
        forecast.month,
        originalDepreciation,
        null,
        'year1'
      );
      balValueSet.push(balVals);
      balanceSheetBase = balVals;
    });

    // Build forecastVals into object with metric objects of monthls like calcs.you, calcs.avg, etc.
    // Special case for Straightline scenario: PNL's net income should be the same for each month
    let straightLinePnlNetIncome = 0;
    pnlValueSet.forEach((forecastObj, index) => {
      Object.keys(forecastObj).forEach(metric => {
        if (forecastVals[metric]) {
          forecastVals[metric][index + 1] = forecastObj[metric]
        } else {
          forecastVals[metric] = {}
          forecastVals[metric][1] = forecastObj[metric]
        }
      })
    })

    balValueSet.forEach((forecastObj, index) => {
      Object.keys(forecastObj).forEach(metric => {
        if (metric === 'operating_expenses_net_income') {
          return
        } else {
          if (forecastVals[metric]) {
            forecastVals[metric][index + 1] = forecastObj[metric]
          } else {
            forecastVals[metric] = {}
            forecastVals[metric][1] = forecastObj[metric]
          }
        }
      })
    })
  }

  const getNAICSFocusedReport = (pdf = false, format = "") => {
    // Doctors and Dentists
    if (props.naicsCode.startsWith('621')) {
      if (format === 'annual') {
        return (
          <MoneyReportSheets
            key={pdf ? 'naics_focused-pdf' : 'naics_focused'}
            aggregateYTDDate={props.aggregateYTDDate}
            clientName={props.clientName}
            commonKey='revenue'
            companyYTDDate={props.companyYTDDate}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            firmName={firmName}
            n={props.docs_n}
            page={pdf ? 'naics_focused-moneypdf' : 'naics_focused'}
            pnlTree={props.pnlTree}
            rows={pnlRows}
            sheet='pnl'
            title={naicsFocusedReport}
            year={props.year}
            yearRange={props.yearRange}
          />
        )
      }
      if (format === 'monthly') {
        return (
          <MonthlyHistoricTable
            key={pdf ? 'naics_focused-pdf' : 'naics_focused'}
            allFilters={allFilters}
            clientName={props.clientName}
            calendarYearEnd={props.calendarYearEnd}
            firmName={firmName}
            monthlyCompareWithTitle={monthlyCompareWithTitle}
            forecastVals={forecastVals}
            monthsObj={monthsObj}
            n={props.docs_n}
            page='naics_focused'
            pdf={pdf}
            rows={pnlRows}
            sheet='pnl'
            title={naicsFocusedReport}
            year={props.year}
          />
        )
      }
      if (format === 'ytd') {
        return (
          <MoneyYTD
            clientName={props.clientName}
            firmName={firmName}
            n={props.docs_n}
            page='naics_focused'
            pdf={pdf}
            rows={pnlRows}
            sheet='pnl'
            title={naicsFocusedReport}
            year={props.year}
          />
        )
      }
    } else if (props.naicsCode.startsWith('813')) {
      if (format === 'annual') {
        return (
          <FunctionalExpenses
            key={pdf ? 'naics_focused-pdf' : 'naics_focused'}
            aggregateYTDDate={props.aggregateYTDDate}
            calcs={calcs}
            clientName={props.clientName}
            companyYTDDate={props.companyYTDDate}
            compareWith={currentNewFilters.compare_with}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            firmName={firmName}
            n={props.n}
            page={pdf ? 'naics_focused-moneypdf' : 'naics_focused'}
            pdf={pdf}
            tree={props.tree}
            sheet='pnl'
            title={naicsFocusedReport}
            year={props.year}
          />
        )
      } else {
        return (
          <div className='money-report-card'>
            <div className='money-report-pdf'>
              <div className='money-report-container'>
                <p>This report will only display for Years view.</p>
              </div>
            </div>
          </div>
        )
      }
    } else {
      return null
    }
  }

  const renderAnnualSheets = () => {
    return (
      <>
        <div className={activeTab === 'pnl' ? 'show-section' : 'hide-section'}>
          <MoneyReportSheets
            key='money-pnl'
            aggregateYTDDate={props.aggregateYTDDate}
            clientName={props.clientName}
            commonKey='revenue'
            companyYTDDate={props.companyYTDDate}
            editMetrics={props.reportMetrics['editMetrics']}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            n={props.n}
            page={'money'}
            rows={props.reportMetrics['pnl']}
            sheet='pnl'
            title='Profit &#38; Loss'
            tree={props.pnlTree}
            year={props.year}
            yearRange={props.yearRange}
          />
        </div>
        <div className='page-break' />
        {naicsFocusedReport
          ? (<div className={activeTab === 'naics_focused' ? 'show-section' : 'hide-section'}>
            {getNAICSFocusedReport(false, 'annual')}
          </div>)
          : null}
        {naicsFocusedReport ? <div className='page-break' /> : null}
        <div className={activeTab === 'balance-sheet' ? 'show-section' : 'hide-section'}>
          <MoneyReportSheets
            aggregateYTDDate={props.aggregateYTDDate}
            clientName={props.clientName}
            commonKey='assets'
            companyYTDDate={props.companyYTDDate}
            editMetrics={props.reportMetrics['editMetrics']}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            n={props.n}
            page={props.page}
            rows={props.reportMetrics['balance_sheet']}
            sheet='balance_sheet'
            title='Balance Sheet'
            tree={props.balTree}
            year={props.year}
            yearRange={props.yearRange}
          />
        </div>
        <div className='page-break' />
        <div className={activeTab === 'cash-flow' ? 'show-section' : 'hide-section'}>
          <MoneyCashFlow
            aggregateYTDDate={props.aggregateYTDDate}
            clientName={props.clientName}
            companyYTDDate={props.companyYTDDate}
            editMetrics={props.reportMetrics['editMetrics']}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            n={props.n}
            pdf={false}
            title='Cash Flow'
            rows={props.reportMetrics['cash_flow']}
            year={props.year}
            yearRange={props.yearRange}
          />
        </div>
        <div className='page-break' />
        <div className={activeTab === 'kfis' ? 'show-section' : 'hide-section'}>
          <MoneyKFI
            aggregateYTDDate={props.aggregateYTDDate}
            clientName={props.clientName}
            companyYTDDate={props.companyYTDDate}
            editMetrics={props.reportMetrics['editMetrics']}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            naicsCode={props.naicsCode}
            n={props.n}
            pdf={false}
            rows={props.reportMetrics['kfis']}
            title={`KFI's`}
            year={props.year}
            yearRange={props.yearRange}
          />
        </div>
        <div className='page-break' />
      </>
    )
  };

  const renderMonthlySheets = () => {
    return (
      <>
        <div className={activeTab === 'pnl' ? 'show-section' : 'hide-section'}>
          <MonthlyHistoricTable
            allFilters={allFilters}
            calendarYearEnd={props.calendarYearEnd}
            clientName={props.clientName}
            editMetrics={props.reportMetrics['editMetrics']}
            monthlyCompareWithTitle={monthlyCompareWithTitle}
            forecastVals={forecastVals}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            monthsObj={monthsObj}
            n={props.n}
            page='money'
            sheet='pnl'
            title='Profit &#38; Loss'
            rows={props.reportMetrics['pnl']}
            year={props.year}
          />
        </div>
        <div className='page-break' />
        {naicsFocusedReport
          ? (<div className={activeTab === 'naics_focused' ? 'show-section' : 'hide-section'}>
            {getNAICSFocusedReport(false, 'monthly')}
          </div>)
          : null}
        {naicsFocusedReport ? <div className='page-break' /> : null}
        <div className={activeTab === 'balance-sheet' ? 'show-section' : 'hide-section'}>
          <MonthlyHistoricTable
            allFilters={allFilters}
            calendarYearEnd={props.calendarYearEnd}
            clientName={props.clientName}
            monthlyCompareWithTitle={monthlyCompareWithTitle}
            forecastVals={forecastVals}
            editMetrics={props.reportMetrics['editMetrics']}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            monthsObj={monthsObj}
            n={props.n}
            page='money'
            sheet='balance_sheet'
            title='Balance Sheet'
            rows={props.reportMetrics['balance_sheet']}
            year={props.year}
          />
        </div>
        <div className='page-break' />
        <div className={activeTab === 'cash-flow' ? 'show-section' : 'hide-section'}>
          <MonthlyHistoricCashFlow
            calendarYearEnd={props.calendarYearEnd}
            clientName={props.clientName}
            compareWith={currentNewFilters.compare_with}
            editMetrics={props.reportMetrics['editMetrics']}
            monthlyCompareWithTitle={monthlyCompareWithTitle}
            forecastVals={forecastVals}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            monthsObj={monthsObj}
            n={props.n}
            page={props.page}
            rows={props.reportMetrics['cash_flow']}
            year={props.year}
          />
        </div>
        <div className='page-break' />
        <div className={activeTab === 'kfis' ? 'show-section' : 'hide-section'}>
          <MoneyMonthlyKFI
            calendarYearEnd={props.calendarYearEnd}
            clientName={props.clientName}
            editMetrics={props.reportMetrics['editMetrics']}
            monthlyCompareWithTitle={monthlyCompareWithTitle}
            forecastVals={forecastVals}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            monthsObj={monthsObj}
            n={props.n}
            page={props.page}
            rows={props.reportMetrics['kfis']}
            year={props.year}
          />
        </div>
      </>
    )
  };

  const renderYTDSheets = () => {
    // Only two years being used for YTD tables.
    // Each month with values should have total revenue. Use to check how many months with values are available and label the YTD columns with the month/year.
    const previousYearYTDYouTotalRevenue = calcs[props.year - 1] ? calcs[props.year - 1]['you']['total_revenue'] : undefined;
    const currentYearYTDYouTotalRevenue = calcs[props.year] ? calcs[props.year]['you']['total_revenue'] : undefined;
    let firstYearMonth = previousYearYTDYouTotalRevenue ? Object.values(previousYearYTDYouTotalRevenue).filter(value => _.isNumber(value)).length : '';
    let secondYearMonth = currentYearYTDYouTotalRevenue ? Object.values(currentYearYTDYouTotalRevenue).filter(value => _.isNumber(value)).length : '';
    let formattedFirstYearMonth = firstYearMonth < 10 ? '0' + firstYearMonth.toString() : firstYearMonth;
    let formattedSecondYearMonth = secondYearMonth < 10 ? '0' + secondYearMonth.toString() : secondYearMonth;

    return (
      <>
        <div className={activeTab === 'pnl' ? 'show-section' : 'hide-section'}>
          <MoneyYTD
            clientName={props.clientName}
            editMetrics={props.reportMetrics['editMetrics']}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            n={props.n}
            page='money'
            rows={props.reportMetrics['pnl']}
            sheet='pnl'
            title='Profit &#38; Loss'
            year={props.year}
          />
        </div>
        <div className='page-break' />
        {naicsFocusedReport
          ? (<div className={activeTab === 'naics_focused' ? 'show-section' : 'hide-section'}>
            {getNAICSFocusedReport(false, 'ytd')}
          </div>)
          : null}
        {naicsFocusedReport ? <div className='page-break' /> : null}
        <div className={activeTab === 'balance-sheet' ? 'show-section' : 'hide-section'}>
          <MoneyYTD
            clientName={props.clientName}
            editMetrics={props.reportMetrics['editMetrics']}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            n={props.n}
            page='money'
            rows={props.reportMetrics['balance_sheet']}
            sheet='balance_sheet'
            title='Balance Sheet'
            year={props.year}
          />
        </div>
        <div className='page-break' />
        <div className={activeTab === 'cash-flow' ? 'show-section' : 'hide-section'}>
          <MoneyYTDCashFlow
            clientName={props.clientName}
            editMetrics={props.reportMetrics['editMetrics']}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            n={props.n}
            rows={props.reportMetrics['cash_flow']}
            year={props.year}
          />
        </div>
        <div className='page-break' />
        <div className={activeTab === 'kfis' ? 'show-section' : 'hide-section'}>
          <MoneyYTDKFI
            clientName={props.clientName}
            editMetrics={props.reportMetrics['editMetrics']}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            n={props.n}
            rows={props.reportMetrics['kfis']}
            year={props.year}
            yearRange={[props.year - 1, props.year]}
          />
        </div>
      </>
    )
  };

  const renderMoneySheets = () => {
    let displayColumnsBy = currentNewFilters.display_columns_by;
    let reportPeriod = currentNewFilters.report_period;
    switch (displayColumnsBy) {
      case 'Months':
        return renderMonthlySheets();
      case 'Years':
        if (reportPeriod === 'Year to Date') {
          return renderYTDSheets();
        } else {
          return renderAnnualSheets();
        }
      default:
        return renderAnnualSheets();
    }
  }

  const renderAnnualPDFs = () => {
    return (
      <>
        {props.displayPages['pnl']['checked']
          ? <MoneyReportSheets
            key='money-pnl-pdf'
            aggregateYTDDate={props.aggregateYTDDate}
            clientName={props.clientName}
            commonKey='revenue'
            companyYTDDate={props.companyYTDDate}
            editMetrics={props.reportMetrics['editMetrics']}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            firmName={firmName}
            n={props.n}
            page='moneypdf'
            rows={props.reportMetrics['pnl']}
            sheet='pnl'
            title='Profit &#38; Loss'
            tree={props.pnlTree}
            year={props.year}
            yearRange={props.yearRange}
          /> : null}
        {props.displayPages['naics_focused_report']['checked'] && naicsFocusedReport
          ? getNAICSFocusedReport(true, 'annual') : null}
        {props.displayPages['balance_sheet']['checked']
          ? <MoneyReportSheets
            aggregateYTDDate={props.aggregateYTDDate}
            clientName={props.clientName}
            commonKey='assets'
            companyYTDDate={props.companyYTDDate}
            editMetrics={props.reportMetrics['editMetrics']}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            firmName={firmName}
            n={props.n}
            page='moneypdf'
            rows={props.reportMetrics['balance_sheet']}
            sheet='balance_sheet'
            title='Balance Sheet'
            tree={props.balTree}
            year={props.year}
            yearRange={props.yearRange}
          /> : null}
        {props.displayPages['cash_flow']['checked']
          ? <MoneyCashFlow
            aggregateYTDDate={props.aggregateYTDDate}
            clientName={props.clientName}
            companyYTDDate={props.companyYTDDate}
            editMetrics={props.reportMetrics['editMetrics']}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            firmName={firmName}
            n={props.n}
            pdf={true}
            title='Cash Flow'
            rows={props.reportMetrics['cash_flow']}
            year={props.year}
            yearRange={props.yearRange}
          /> : null}
        {props.displayPages['kfis']['checked']
          ? <MoneyKFI
            aggregateYTDDate={props.aggregateYTDDate}
            clientName={props.clientName}
            companyYTDDate={props.companyYTDDate}
            editMetrics={props.reportMetrics['editMetrics']}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            firmName={firmName}
            naicsCode={props.naicsCode}
            n={props.n}
            pdf={true}
            rows={props.reportMetrics['kfis']}
            title={`KFI's`}
            year={props.year}
            yearRange={props.yearRange}
          /> : null}
      </>
    )
  }

  const renderMonthlyPDFs = () => {
    return (
      <>
        {props.displayPages['pnl']['checked']
          ? <MonthlyHistoricTable
            allFilters={allFilters}
            calendarYearEnd={props.calendarYearEnd}
            clientName={props.clientName}
            editMetrics={props.reportMetrics['editMetrics']}
            firmName={firmName}
            monthlyCompareWithTitle={monthlyCompareWithTitle}
            forecastVals={forecastVals}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            monthsObj={monthsObj}
            n={props.n}
            page='money'
            pdf={true}
            sheet='pnl'
            title='Profit &#38; Loss'
            rows={props.reportMetrics['pnl']}
            year={props.year}
          /> : null}
        {props.displayPages['naics_focused_report']['checked'] && naicsFocusedReport
          ? getNAICSFocusedReport(true, 'monthly') : null}
        {props.displayPages['balance_sheet']['checked']
          ? <MonthlyHistoricTable
            allFilters={allFilters}
            calendarYearEnd={props.calendarYearEnd}
            clientName={props.clientName}
            editMetrics={props.reportMetrics['editMetrics']}
            firmName={firmName}
            monthlyCompareWithTitle={monthlyCompareWithTitle}
            forecastVals={forecastVals}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            monthsObj={monthsObj}
            n={props.n}
            page='money'
            pdf={true}
            sheet='balance_sheet'
            title='Balance Sheet'
            rows={props.reportMetrics['balance_sheet']}
            year={props.year}
          /> : null}
        {props.displayPages['cash_flow']['checked']
          ? <MonthlyHistoricCashFlow
            calendarYearEnd={props.calendarYearEnd}
            clientName={props.clientName}
            compareWith={currentNewFilters.compare_with}
            editMetrics={props.reportMetrics['editMetrics']}
            firmName={firmName}
            monthlyCompareWithTitle={monthlyCompareWithTitle}
            forecastVals={forecastVals}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            monthsObj={monthsObj}
            n={props.n}
            page={props.page}
            pdf={true}
            rows={props.reportMetrics['cash_flow']}
            year={props.year}
          /> : null}
        {props.displayPages['kfis']['checked']
          ? <MoneyMonthlyKFI
            calendarYearEnd={props.calendarYearEnd}
            clientName={props.clientName}
            editMetrics={props.reportMetrics['editMetrics']}
            firmName={firmName}
            monthlyCompareWithTitle={monthlyCompareWithTitle}
            forecastVals={forecastVals}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            monthsObj={monthsObj}
            n={props.n}
            page={props.page}
            pdf={true}
            rows={props.reportMetrics['kfis']}
            year={props.year}
          /> : null}
      </>
    )
  }

  const renderYTDPDFs = () => {
    // Only two years being used for YTD tables.
    // Each month with values should have total revenue. Use to check how many months with values are available and label the YTD columns with the month/year.
    const previousYearYTDYouTotalRevenue = calcs[props.year - 1] ? calcs[props.year - 1]['you']['total_revenue'] : undefined;
    const currentYearYTDYouTotalRevenue = calcs[props.year] ? calcs[props.year]['you']['total_revenue'] : undefined;
    let firstYearMonth = previousYearYTDYouTotalRevenue ? Object.values(previousYearYTDYouTotalRevenue).filter(value => _.isNumber(value)).length : '';
    let secondYearMonth = currentYearYTDYouTotalRevenue ? Object.values(currentYearYTDYouTotalRevenue).filter(value => _.isNumber(value)).length : '';
    let formattedFirstYearMonth = firstYearMonth < 10 ? '0' + firstYearMonth.toString() : firstYearMonth;
    let formattedSecondYearMonth = secondYearMonth < 10 ? '0' + secondYearMonth.toString() : secondYearMonth;

    return (
      <>
        {props.displayPages['pnl']['checked']
          ? <MoneyYTD
            clientName={props.clientName}
            editMetrics={props.reportMetrics['editMetrics']}
            firmName={firmName}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            n={props.n}
            page='money'
            pdf={true}
            rows={props.reportMetrics['pnl']}
            sheet='pnl'
            title='Profit &#38; Loss'
            year={props.year}
          /> : null}
        {props.displayPages['naics_focused_report']['checked'] && naicsFocusedReport
          ? getNAICSFocusedReport(true, 'ytd') : null}
        {props.displayPages['balance_sheet']['checked']
          ? <MoneyYTD
            clientName={props.clientName}
            editMetrics={props.reportMetrics['editMetrics']}
            firmName={firmName}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            n={props.n}
            page='money'
            pdf={true}
            rows={props.reportMetrics['balance_sheet']}
            sheet='balance_sheet'
            title='Balance Sheet'
            year={props.year}
          /> : null}
        {props.displayPages['cash_flow']['checked']
          ? <MoneyYTDCashFlow
            clientName={props.clientName}
            editMetrics={props.reportMetrics['editMetrics']}
            firmName={firmName}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            n={props.n}
            pdf={true}
            rows={props.reportMetrics['cash_flow']}
            year={props.year}
          /> : null}
        {props.displayPages['kfis']['checked']
          ? <MoneyYTDKFI
            clientName={props.clientName}
            editMetrics={props.reportMetrics['editMetrics']}
            firmName={firmName}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            n={props.n}
            pdf={true}
            rows={props.reportMetrics['kfis']}
            year={props.year}
            yearRange={[props.year - 1, props.year]}
          /> : null}
      </>
    )
  }

  const renderMoneyPDFSheets = () => {
    let displayColumnsBy = currentNewFilters.display_columns_by;
    let reportPeriod = currentNewFilters.report_period;
    switch (displayColumnsBy) {
      case 'Months':
        return renderMonthlyPDFs();
      case 'Years':
        if (reportPeriod === 'Year to Date') {
          return renderYTDPDFs();
        } else {
          return renderAnnualPDFs();
        }
      default:
        return renderAnnualPDFs();
    }
  }

  const renderEditDashboard = () => {
    if ((!props.planAccess.money.dashboard && navbar.user_type !== 'Admin') || (!navbar.reports_unlocked && props.planAccess.money.dashboard && navbar.user_type !== 'Admin')) {
      return null;
    }

    if (navbar.reports_unlocked && props.planAccess.money.dashboard || navbar.user_type === 'Admin') {
      return (
        <div className='edit-dashboard-section'>
          {props.reportMetrics.noChangesMade ? null : <button className='save-dashboard-btn' onClick={() => props.updateDashBoardView()}>Save Changes</button>}
          {activeTab === 'dashboard' ? <button className='save-dashboard-btn' onClick={() => props.handleDashboardViewModal('add_view')}>+ New View</button> : null}
          {activeTab === 'dashboard' ? <button className='save-dashboard-btn' onClick={() => props.handleDashboardViewModal('all_views')}>Edit My Views</button> : null}
          <EditParentChildDashboard
            allMetrics={props.reportMetrics}
            editDashboard={props.editDashboard}
            handleEditDashBoard={props.handleEditDashBoard}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            page={props.page}
          />
        </div>
      )
    }
  }

  if (props.pdf) {
    let dashboardPages = getDashBoardPagesPDF(props.reportMetrics.layout)
    let displayColumnsBy = currentNewFilters.display_columns_by;
    let coverPeriod = ''
    let reportPeriod = currentNewFilters.report_period;
    let reportTitle = 'Industry Benchmark Report'
    let NAICSfilter = allFilters.filters.code
    let numberOfDocs = naicsFocusedReport && allFilters.filters.number_of_pros === 0 ? 'All' : naicsFocusedReport ? allFilters.filters.number_of_pros : ''
    let todayDate = moment(Date.now()).format("LL")

    switch (displayColumnsBy) {
      case 'Months':
        coverPeriod = props.year
        break;
      case 'Years':
        if (reportPeriod === 'Year to Date') {
          coverPeriod = `${props.year - 1} - ${props.year}`
          break;
        } else {
          coverPeriod = `${props.yearRange[0]} - ${props.year}`
          break;
        }
      default:
        coverPeriod = `${props.yearRange[0]} - ${props.year}`
        break;
    }

    if (allFilters.filterOptions.code && allFilters.filterOptions.code.length > 1) {
      allFilters.filterOptions.code.forEach(filter => {
        if (NAICSfilter === filter[0])
          NAICSfilter = filter[0] + ' - ' + filter[1]
      })
    }

    const portraitView = {
      'backgroundColor': '#ffffff',
      'width': '215mm !important',
      'height': '279mm !important',
      'margin': '0 auto',
      'pageBreakInside': 'avoid',
      'pageBreakAfter': 'always'
    }

    return (
      <div ref={ref} className='pdf-print-container'>
        {props.displayPages['cover_page']['checked'] ?
          (<div className='portrait-view cover-page-pdf'>
            <div className='cover-page-top-section'></div>
            <div className='cover-page-info'>
              <div>
                <div className='img-title-page-print'>{props.logo ? <img src={props.logo} alt=''></img> : null}</div>
                <h1>{reportTitle}</h1>
                <div className='cp-report-info'>
                  <p>{props.name}</p>
                  <p>FOR THE PERIOD: {coverPeriod}</p>
                  <p>INDUSTRY: {props.industry}</p>
                  <p>NAICS: {props.naicsCode}</p>
                  <p>{`NUMBER OF COMPANIES: ${props.n}`}</p>
                  <p style={{ 'textTransform': 'uppercase' }}>{todayDate}</p>
                </div>
              </div>
              <div className='cp-filters-list'>
                <h2 className='cp-filter-title'>FILTERS USED IN THIS {props.page} REPORT</h2>
                <p>INDUSTRY: {NAICSfilter}</p>
                <div className='cp-filter-cols'>
                  <p>REVENUE: {allFilters.filters.revenue_band}</p>
                  <p>REGION: {allFilters.filters.region}</p>
                  {naicsFocusedReport ? <p># DOCTORS: {numberOfDocs}</p> : null}
                  <p>REPORT PERIOD: {currentNewFilters.report_period}</p>
                  <p>COMPARE WITH: {currentNewFilters.compare_with}</p>
                </div>
              </div>
            </div>
            <div className='cover-page-bottom-section'>
              <p>Powered by Peerview Data</p>
            </div>
          </div>) : null}
        {renderMoneyPDFSheets()}
        {props.displayPages['dashboard']['checked']
          ? (dashboardPages.map(dashboardItems => {
            return (
              <div className='portrait-view'>
                <div className='money-pdf-portrait'>
                  <div>
                    <h1 className='report-table-title'>{`Dashboard - ${props.clientName}`}</h1>
                    <ParentDashboard
                      aggregateYTDDate={props.aggregateYTDDate}
                      calcsAvgType={calcsAvgType}
                      clientName={props.clientName}
                      companyYTDDate={props.companyYTDDate}
                      compareWith={currentNewFilters.compare_with}
                      dashboardLayout={dashboardItems}
                      displayColumnsBy={currentNewFilters.display_columns_by}
                      monthlyCompareWithTitle={monthlyCompareWithTitle}
                      forecastVals={forecastVals}
                      myNotes={props.moneyNotes}
                      handleMetricNotes={props.handleMetricNotes}
                      handleMoneyNotes={props.handleMoneyNotes}
                      handleOnLayoutChange={props.handleOnLayoutChange}
                      handleToggleDraggable={props.handleToggleDraggable}
                      calendarYearEnd={props.calendarYearEnd}
                      fiscalYearEnd={props.fiscalYearEnd}
                      monthLabels={monthLabels}
                      naicsCode={props.naicsCode}
                      page={props.page}
                      pdf={true}
                      reportPeriod={currentNewFilters.report_period}
                      reportMetrics={props.reportMetrics}
                      year={props.year}
                      yearRange={props.yearRange}
                    />
                  </div>
                  <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
                </div>
              </div>
            )
          }))
          : null}
      </div>
    )
  } else {
    return (
      <div className='money-report-component money-content'>
        <VideoModal />
        <ul id='nav-tabs-money-report' className='report-tab-list' role='tablist' >
          <li id='pnl-tab' className={activeTab === 'pnl' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => handleActiveTab('pnl')}>
            Profit &#38; Loss
          </li>
          {naicsFocusedReport
            ? (
              <li id='naics_focused-tab' className={activeTab === 'naics_focused' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => handleActiveTab('naics_focused')}>
                {naicsFocusedReport}
              </li>
            )
            : null
          }
          <li id='balance-sheet-tab' className={activeTab === 'balance-sheet' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => handleActiveTab('balance-sheet')}>
            Balance Sheet
          </li>
          <li id='cash-flow-tab' className={activeTab === 'cash-flow' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => handleActiveTab('cash-flow')}>
            Cash Flow
          </li>
          <li id='kfis-tab' className={activeTab === 'kfis' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => handleActiveTab('kfis')}>
            KFI's
          </li>
          <li id='dashboard-tab' className={activeTab === 'dashboard' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => handleActiveTab('dashboard')}>
            Dashboard
          </li>
          <li id="survey-tab" className={activeTab === "survey" ? "report-tab-item active" : "report-tab-item"} role="presentation" onClick={() => handleActiveTab("survey")}>
            Survey
          </li>
          {renderEditDashboard()}
        </ul>
        {renderMoneySheets()}
        <div className={activeTab === 'dashboard' ? 'show-section' : 'hide-section'}>
          <DashboardViewControls
            report={'money'}
            dashboardViewModalState={props.dashboardViewModalState}
            getDashBoardView={props.getDashBoardView}
            handleDashboardViewModal={props.handleDashboardViewModal}
          />
        </div>
        {activeTab === 'dashboard' ?
          <ParentDashboard
            aggregateYTDDate={props.aggregateYTDDate}
            calcsAvgType={calcsAvgType}
            clientName={props.clientName}
            companyYTDDate={props.companyYTDDate}
            compareWith={currentNewFilters.compare_with}
            dashboardLayout={props.reportMetrics.layout}
            displayColumnsBy={currentNewFilters.display_columns_by}
            monthlyCompareWithTitle={monthlyCompareWithTitle}
            forecastVals={forecastVals}
            myNotes={props.moneyNotes}
            handleEditChartModal={props.handleEditChartModal}
            handleMetricNotes={props.handleMetricNotes}
            handleMoneyNotes={props.handleMoneyNotes}
            handleOnLayoutChange={props.handleOnLayoutChange}
            handleToggleDraggable={props.handleToggleDraggable}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            monthLabels={monthLabels}
            naicsCode={props.naicsCode}
            page={props.page}
            reportPeriod={currentNewFilters.report_period}
            reportMetrics={props.reportMetrics}
            year={props.year}
            yearRange={props.yearRange}
          /> : null}
        <div className='page-break' />
        <div className={activeTab === "survey" ? "show-section" : "hide-section"}>
          <SurveyResponses
            chartColors={props.surveyData.chartColors}
            surveyCharts={props.surveyData.surveyCharts}
            surveyTaken={props.surveyTaken}
            companyReportSurvey={props.companyReportSurvey}
            surveyResults={props.surveyResults}
            pageTitle={props.pageTitle}
            year={props.year}
          />
        </div>
      </div>
    )
  }
})

export default MoneyReport
