import React, { useState, useContext, useRef, useMemo } from 'react';
import { Data } from './contexts';
import { CommonSizeArrows, MonthlyPNLandBalValues, MonthlyPNLandBalCommonSized, YTDPnlandBalCommonSizeValue, AnnualCashFlowValues, MonthlyCashFlowValues, AnnualKFIsValues, YTDPnlandBalValues, YTDCashFlowValues, MonthlyKFIsValues, KFIsCommonSizeArrows, CashFlowCommonSizeArrows } from './report_components/MoneyHelpers';
import { buildImpactBox, impactBoxStatusColor, noSpecificTextMaker } from './report_components/ImpactBoxTextHelper';
import { ratioCalculations, ratioCalculationsMoney, calculateEBITDA, netWorkingCapitalCalculation, netWorkingCapitalRatioCalculation, trailing12MonthCalculations } from './monthly/monthy_analysis_table_trailing12_calculations';
import { objReducer, calculateKFIMetric, calculateSeniorDebtToEBITDA, calculateEBITDAtoAnnualInterest, monthlyTotalRevenueGRCalc, monthlyPercentOfRevenueCalc, ytdPercentOfRevenueCalc, ytdTotalRevenueGRCalc } from './report_components/MoneyHelpers';
import { peopleCustomersT12Metrics, peersAnalysisPerCustomerVals } from './report_components/PeopleCustomersHelpers';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { COPY } from './charts/chart_helpers';
import { moneyCharts, pageCharts } from './charts';
import { gaugeKeys, getIndustryLineChartOptions, getIndustryBarChartOptions, getMultiDataBarChartOptions, get3DBubbleChartOptions, getQuadrantOptions, getScatterPlotOptions, getGaugeOptions, buildDrilldownData, getPieOptions, getDonutOptions, format, axisFormat, getLineOrColOptions, getVerticalBarChartOptions, getHorizontalBarOptions, getStackedColumnOptions, getLineChartOptions } from './charts/DashBoardHighChartsHelper';
import WageDataScaffold from './tables/WageDataScaffold';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts.js';
import highchartsMore from 'highcharts/highcharts-more.js';
import highchartsMap from 'highcharts/modules/map';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import HC_exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import drilldown from 'highcharts/modules/drilldown';
import { capitalize, parseMoney } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpen, faLock, faComment, faCommentSlash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { SizeMe } from 'react-sizeme';
const ResponsiveGridLayout = WidthProvider(Responsive);
highchartsMore(Highcharts);
highchartsMap(Highcharts);
solidGauge(Highcharts);
HC_exporting(Highcharts);
offlineExporting(Highcharts);
drilldown(Highcharts);

const ChildDashboard = (props) => {
    const { peerviewMetricsYears, calcs, previousYearCalcs, yoy_calcs, previous_yoy_calcs, calcs_3_years_back } = useContext(Data);
    const [chartRefs, setChartRefs] = useState({})
    const [timer, setTimer] = useState(null)
    const [showChart, setShowChart] = useState(true)
    const [showImpactComment, setShowImpactComment] = useState(true)
    // Get an object of all the highcharts refs so that they reflow() or force resizing when user resizes them in the react-grid-layout
    const setNewRef = (ref) => {
        if (ref) {
            let itemId = ref.container.current.id;
            chartRefs[itemId] = ref;
            setChartRefs(prevState => {
                return {
                    ...prevState,
                    itemId: ref
                }
            })
        }
    };

    const titleSetter = (chart) => {
        if (props.displayColumnsBy === 'Months' && chart.key === 'total_revenue_growth_rate') {
            return 'Monthly Revenue % of Annual Total Revenue'
        } else if (chart.copy) {
            if (chart.key.includes('labor_cogs')) {
                return `Labor COGS - ${chart.copy}`
            } else {
                return chart.copy
            }
        } else {
            return (chart.title ? chart.title : COPY[chart.key])
        }
    }

    const buildIndustryLineData = (chart) => {
        let categories;
        const barColor = {
            industryMoney: '#69B144',
            industryPeople: '#00AEEF',
            industryCustomers: '#542667'
        }
        let key = chart.key;
        if (chart.key === 'profitability') {
            key = 'profit_margin'
        }
        let seriesData = ['avg'].map((category, i) => {
            categories = Object.keys(calcs[category][key]);
            return {
                name: capitalize(category),
                title: capitalize(category),
                data: Object.values(calcs[category][key]),
                showInLegend: false,
                type: 'line',
                color: barColor[props.page],
                _colorIndex: i,
                _symbolIndex: i,
            }
        })
        return { seriesData, categories }
    }

    const buildIndustryBarData = (chart) => {
        let categories;
        const barColor = {
            industryMoney: '#69B144',
            industryPeople: '#00AEEF',
            industryCustomers: '#542667'
        }
        let key = chart.key;
        let seriesData = ['avg'].map((category, i) => {
            categories = Object.keys(calcs[category][key]);
            let dataValues = [];
            if (key === 'senior_debt_to_ebitda') {
                dataValues = categories.map(year => {
                    let value;
                    let longTermDebt = calcs[category]['long_term_debt'][year];
                    let lineOfCredit = calcs[category]['line_of_credit'][year];
                    let currentPortionOfLTD = calcs[category]['current_portion_of_ltd'][year];
                    let netIncome = calcs[category]['operating_expenses_net_income'][year];
                    let interest = calcs[category]['interest_and_other_expenses'][year];
                    let taxes = calcs[category]['total_other_costs_taxes_- federal & state'][year];
                    let deprAmor = calcs[category]['depreciation_and_amortization'][year];
                    let ebitda = netIncome + interest + taxes + deprAmor;
                    return value = (longTermDebt + lineOfCredit + currentPortionOfLTD) / ebitda;
                })
            } else if (key === 'ebitda_to_annual_interest') {
                dataValues = categories.map(year => {
                    let value;
                    let netIncome = calcs[category]['operating_expenses_net_income'][year];
                    let interest = calcs[category]['interest_and_other_expenses'][year];
                    let taxes = calcs[category]['total_other_costs_taxes_- federal & state'][year];
                    let deprAmor = calcs[category]['depreciation_and_amortization'][year];
                    let ebitda = netIncome + interest + taxes + deprAmor;
                    return value = ebitda / interest;
                })
            } else {
                dataValues = Object.values(calcs[category][key]);
            }
            return {
                name: capitalize(category),
                title: capitalize(category),
                data: dataValues,
                showInLegend: false,
                type: 'column',
                color: barColor[props.page],
                _colorIndex: i % 2,
                _symbolIndex: i,
            }
        })
        return { seriesData, categories }
    }

    const buildHorizonalBarSeries = (chart) => {
        if (props.page === 'industryCustomers') {
            let vals = chart.keys.map((key) => {
                let val = calcs['avg'][key][props.year];
                return val ? (Math.round(val * 10) / 10) : null;
            })

            return [{
                data: vals,
                showInLegend: false,
                name: 'avg',
                color: '#542667',
            }]

        } else {
            if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
                return ['you', 'avg'].map((g, i) => {
                    let dataName;
                    if (g === 'you') {
                        dataName = 'You'
                    }
                    if (g === 'avg') {
                        dataName = 'Peers'
                    }
                    let vals = chart.keys.map((key) => {
                        let val = calcs[g][key][props.year];
                        return val ? (Math.round(val * 10) / 10) : null;
                    })

                    return {
                        data: vals,
                        showInLegend: true,
                        name: dataName,
                        color: dataName === 'You' ? '#542667' : '#ae6dc9',
                    }
                })
            }

            return ['you', 'avg'].map((g, i) => {
                let dataName;
                if (g === 'you') {
                    dataName = 'You'
                }
                if (g === 'avg') {
                    dataName = 'Peers'
                }

                return {
                    data: [0, 0],
                    showInLegend: true,
                    name: dataName,
                    color: dataName === 'You' ? '#542667' : '#ae6dc9',
                }
            })
        }
    }

    const buildLineSeries = (chart) => {
        if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
            return ['you', 'avg'].map((g, i) => {
                let dataName;
                if (g === 'you') {
                    dataName = 'You'
                }
                if (g === 'avg') {
                    dataName = 'Peers'
                }
                let vals = chart.keys.map((key) => {
                    // need latest year for cltv70, 80, 90
                    let val = calcs[g][key][props.year];
                    return val ? val : null;
                })

                return {
                    data: vals,
                    showInLegend: true,
                    name: dataName,
                    title: dataName,
                    color: dataName === 'You' ? '#542667' : '#ae6dc9',
                    _symbolIndex: i,
                }
            })
        }
        return ['you', 'avg'].map((g, i) => {
            let dataName;
            if (g === 'you') {
                dataName = 'You'
            }
            if (g === 'avg') {
                dataName = 'Peers'
            }

            return {
                data: [0, 0],
                showInLegend: true,
                name: dataName,
                color: dataName === 'You' ? '#542667' : '#ae6dc9',
            }
        })
    }

    const buildVerticalBarData = (chart, set) => {
        let value = null;
        if (props.displayColumnsBy !== 'Months' && props.reportPeriod !== 'Year to Date') {
            if (set === 'you' && calcs['you']) {
                value = calcs['you'][chart.key][props.year];
            }
            if (set === 'Peers' && calcs['avg']) {
                value = calcs['avg'][chart.key][props.year];
            }
            if (set === 'YOY' && previousYearCalcs['you']) {
                value = previousYearCalcs['you'][chart.key][props.year];
            }
            if (set === 'Practice' && calcs['practice_avg']) {
                value = calcs['practice_avg'][chart.key][props.year];
            }
            if (set === 'NSCHBC' && calcs['nschbc']) {
                value = calcs['nschbc'][chart.key][props.year];
            }

        } else {
            let monthNumber = 0;
            let metrics = set === 'you' ? props.youMetrics : props.compareMetrics;
            let useableMonths;
            if (props.displayColumnsBy === 'Months') {
                useableMonths = Object.values(calcs['you']['roa']);
            } else if (props.reportPeriod === 'Year to Date') {
                useableMonths = Object.values(calcs[props.year]['you']['roa']);
            }
            useableMonths.forEach(thing => thing !== null ? monthNumber += 1 : null);
            if (metrics[chart.key]) {
                value = Math.round(metrics[chart.key][monthNumber]);
            }
        }

        return value;
    }

    // Used by AP and AR Aging Summary Reports
    const buildAPARAgingSeries = (chart) => {
        let returnSeries;
        let firstData = [];
        let secondData = [];
        let thirdData = [];
        let fourthData = [];
        let currentData = [];
        let apOrAr = chart.key === 'ap_aging_summary' ? 'ap' : 'ar';
        let agingKey91Over = apOrAr + '_91_and_over';
        let agingKey6191 = apOrAr + '_61_90';
        let agingKey3160 = apOrAr + '_31_60';
        let agingKey130 = apOrAr + '_1_30';
        let agingKeyCurrent = apOrAr + '_current';

        if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
            chart.years.forEach(year => {
                firstData.push(calcs['you'][agingKey91Over][year])
                secondData.push(calcs['you'][agingKey6191][year])
                thirdData.push(calcs['you'][agingKey3160][year])
                fourthData.push(calcs['you'][agingKey130][year])
                currentData.push(calcs['you'][agingKeyCurrent][year])
            })
            returnSeries = [
                {
                    name: '91 and over',
                    color: '#ffb74d',
                    data: firstData
                },
                {
                    name: '61-90',
                    color: '#999',
                    data: secondData
                },
                {
                    name: '31-60',
                    color: '#542667',
                    data: thirdData
                },
                {
                    name: '1-30',
                    color: '#00aeef',
                    data: fourthData
                },
                {
                    name: 'Current',
                    color: '#69b144',
                    data: currentData
                }
            ]
        }

        if (props.reportPeriod === 'Year to Date') {
            chart.years.forEach(year => {
                let firstVals = calcs[year]['you'][agingKey91Over] ? Object.values(calcs[year]['you'][agingKey91Over]) : [];
                let secondVals = calcs[year]['you'][agingKey6191] ? Object.values(calcs[year]['you'][agingKey6191]) : [];
                let thirdVals = calcs[year]['you'][agingKey3160] ? Object.values(calcs[year]['you'][agingKey3160]) : [];
                let fourthVals = calcs[year]['you'][agingKey130] ? Object.values(calcs[year]['you'][agingKey130]) : [];
                let currentVals = calcs[year]['you'][agingKeyCurrent] ? Object.values(calcs[year]['you'][agingKeyCurrent]) : [];
                firstData.push(firstVals[firstVals.length - 1])
                secondData.push(secondVals[secondVals.length - 1])
                thirdData.push(thirdVals[thirdVals.length - 1])
                fourthData.push(fourthVals[fourthVals.length - 1])
                currentData.push(currentVals[currentVals.length - 1])
            })
            returnSeries = [
                {
                    name: '91 and over',
                    color: '#ffb74d',
                    data: firstData
                },
                {
                    name: '61-90',
                    color: '#999',
                    data: secondData
                },
                {
                    name: '31-60',
                    color: '#542667',
                    data: thirdData
                },
                {
                    name: '1-30',
                    color: '#00aeef',
                    data: fourthData
                },
                {
                    name: 'Current',
                    color: '#69b144',
                    data: currentData
                }
            ]
        }

        if (props.displayColumnsBy === 'Months') {
            returnSeries = [
                {
                    name: '91 and over',
                    color: '#ffb74d',
                    data: Object.values(calcs['you'][agingKey91Over])
                },
                {
                    name: '61-90',
                    color: '#999',
                    data: Object.values(calcs['you'][agingKey6191])
                },
                {
                    name: '31-60',
                    color: '#542667',
                    data: Object.values(calcs['you'][agingKey3160])
                },
                {
                    name: '1-30',
                    color: '#00aeef',
                    data: Object.values(calcs['you'][agingKey130])
                },
                {
                    name: 'Current',
                    color: '#69b144',
                    data: Object.values(calcs['you'][agingKeyCurrent])
                }
            ]
        }
        return returnSeries;
    }

    const peoplePercentKeys = ['sum_compensation_growth_rate', 'sum_taxes_and_benefits_growth_rate', 'sum_subcontractors_growth_rate', 'turnover_rate']
    const peopleKpiT12 = ['total_revenue_per_employee', 'operating_expenses_net_income_per_employee', 'average_wages_and_salaries', 'average_benefits', 'cost_of_turnover', 'number_of_employees_who_left', 'sum_compensation_growth_rate', 'sum_compensation_per_employee', 'sum_health_insurance_per_employee', 'sum_payroll_per_employee', 'sum_retirement_per_employee', 'sum_subcontractors_growth_rate', 'sum_taxes_and_benefits_growth_rate', 'sum_taxes_and_benefits_per_employee', 'total_number_of_employees', 'training_per_employee', 'turnover_rate']
    const customerKPIKeys = ['revenue_per_customer', 'gross_profit_per_customer', 'operating_expenses_net_income_per_customer', 'total_customer_costs_common_size_revenue', 'number_of_new_customers_needed_to_maintain_growth']
    const customerPercentKeys = ['percent_of_sales_from_the_top_10_percent', 'percent_of_sales_from_the_top_25_percent', 'percent_of_sales_from_the_top_50_percent', 'customer_roi']
    const buildYouLineOrColData = (chart, sheet = '') => {
        const key = chart.key;
        let dataMap = [];

        if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
            dataMap = chart.years.map(year => {
                let value;
                try {
                    if (key === 'net_working_capital') {
                        let totalCurrentAssets = calcs['you']['total_current_assets'][year];
                        let totalCurrentLiabilities = calcs['you']['total_current_liabilities'][year];
                        value = totalCurrentAssets - totalCurrentLiabilities;
                    } else if (key === 'senior_debt_to_ebitda') {
                        let longTermDebt = calcs['you']['long_term_debt'][year];
                        let lineOfCredit = calcs['you']['line_of_credit'][year];
                        let currentPortionOfLTD = calcs['you']['current_portion_of_ltd'][year];
                        let netIncome = calcs['you']['operating_expenses_net_income'][year];
                        let interest = calcs['you']['interest_and_other_expenses'][year];
                        let taxes = calcs['you']['total_other_costs_taxes_- federal & state'][year];
                        let deprAmor = calcs['you']['depreciation_and_amortization'][year];
                        let ebitda = netIncome + interest + taxes + deprAmor;
                        value = (longTermDebt + lineOfCredit + currentPortionOfLTD) / ebitda;
                    } else if (key === 'ebitda_to_annual_interest') {
                        let netIncome = calcs['you']['operating_expenses_net_income'][year];
                        let interest = calcs['you']['interest_and_other_expenses'][year];
                        let taxes = calcs['you']['total_other_costs_taxes_- federal & state'][year];
                        let deprAmor = calcs['you']['depreciation_and_amortization'][year];
                        let ebitda = netIncome + interest + taxes + deprAmor;
                        value = ebitda / interest;
                    } else if (customerKPIKeys.includes(key)) {
                        value = calcs['you'][key][year]
                    } else if (customerPercentKeys.includes(key) || peoplePercentKeys.includes(key)) {
                        value = calcs['you'][key][year] / 100
                    } else if (sheet === 'pnl' || sheet === 'financial_detail' || sheet === 'kpi') {
                        value = calcs['you'][key + '_common_size_revenue'][year]
                    } else if (sheet === 'balance_sheet') {
                        value = calcs['you'][key + '_common_size_assets'][year]
                    } else {
                        value = calcs.you[key][year];
                    }
                    return value ? value : null;
                } catch (e) {
                    return null;
                }
            })
        }

        if (props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date') {
            if (props.page === 'money' || props.page === 'people' || props.page === 'customers') {
                dataMap = chart.years.map(year => {
                    try {
                        if (chart.percentOfRevenueKey) {
                            let value = ytdPercentOfRevenueCalc(chart.percentOfRevenueKey, key, calcs[year]['you']);
                            return value;
                        } else if (key === 'senior_debt_to_ebitda') {
                            let value = calculateSeniorDebtToEBITDA(calcs[year]['you'])
                            return value;
                        } else if (key === 'ebitda_to_annual_interest') {
                            let value = calculateEBITDAtoAnnualInterest(calcs[year]['you'])
                            return value;
                        } else if (key === 'total_revenue_growth_rate') {
                            let totalRevenueGR = ytdTotalRevenueGRCalc(calcs[year]['you'], previousYearCalcs['monthly'][year - 1]['you'])
                            return totalRevenueGR;
                        } else if (peopleKpiT12.includes(key) || customerKPIKeys.includes(key) || key === 'customer_roi' || key === 'number_of_new_customers_needed_to_maintain_growth') {
                            let lastMonthNumber = 0;
                            let useableMonths = Object.values(calcs[year]['you']['roa']);
                            useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);
                            const calcsCurrentYearYTDYou = previousYearCalcs['monthly'][year]['you']
                            const previousYearCalcsYTDYou = previousYearCalcs['monthly'][year - 1]['you']
                            const previous2YearsBackYTDYou = previousYearCalcs['monthly'][year - 2]['you']
                            let t12Obj = peopleCustomersT12Metrics(props.page, key, calcsCurrentYearYTDYou, previousYearCalcsYTDYou, previous2YearsBackYTDYou)
                            return t12Obj[key][lastMonthNumber]
                        } else if (sheet === 'pnl' || sheet === 'balance_sheet' || sheet === 'financial_detail' || sheet === 'kpi') {
                            const yearVals = calcs[year] ? calcs[year]['you'][key] : undefined;
                            let value = YTDPnlandBalValues(sheet, key, yearVals, calcs[year]['you'])
                            let finalVal = YTDPnlandBalCommonSizeValue(value, sheet, calcs[year]['you'])
                            return finalVal;
                        } else {
                            let value = calculateKFIMetric(key, calcs[year]['you'], previousYearCalcs['monthly'][year - 1]['you'])
                            return value;
                        }
                    } catch (e) {
                        return null;
                    }
                })
            } else {
                let selectedYearVal, previousYearVal = null;
                let monthNumber = 0;
                let useableMonths = Object.values(calcs[props.year]['you']['roa']);
                useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthNumber += 1 : null);

                if (key === 'total_revenue' || key === 'gross_profit' || key === 'operating_expenses_net_income') {
                    selectedYearVal = objReducer(calcs[props.year]['you'][key])
                    previousYearVal = objReducer(calcs[props.year - 1]['you'][key])
                } else {
                    if (props.youMetrics[key]) {
                        selectedYearVal = props.youMetrics[key][monthNumber];
                    }
                    if (props.youPreviousYearMetricsYTD[key]) {
                        previousYearVal = props.youPreviousYearMetricsYTD[key][monthNumber];
                    }
                }
                dataMap = [previousYearVal, selectedYearVal]
            }
        }

        if (props.displayColumnsBy === 'Months') {
            let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            if (props.page === 'money' || props.page === 'people' || props.page === 'customers') {
                if (chart.trailing12Key) {
                    let trailing12Obj = trailing12MonthCalculations(chart.trailing12Key, calcs.you, previousYearCalcs.you);
                    dataMap = Object.values(trailing12Obj);
                } else if (chart.percentOfRevenueKey) {
                    let percentOfRevenueObj = monthlyPercentOfRevenueCalc(chart.percentOfRevenueKey, key, calcs.you);
                    dataMap = Object.values(percentOfRevenueObj);
                } else if (key === 'current_ratio') {
                    let currentRatioObj = ratioCalculations('total_current_assets', calcs.you)
                    dataMap = Object.values(currentRatioObj);
                } else if (key === 'net_working_capital_ratio') {
                    let netWorkingCapRatioObj = netWorkingCapitalRatioCalculation(calcs.you)
                    dataMap = Object.values(netWorkingCapRatioObj);
                } else if (key === 'net_working_capital') {
                    let netWorkingCapitalObj = netWorkingCapitalCalculation(calcs.you)
                    dataMap = Object.values(netWorkingCapitalObj)
                } else if (key === 'total_revenue_growth_rate') {
                    let totalRevenueGR = monthlyTotalRevenueGRCalc(calcs.you, previousYearCalcs.you)
                    dataMap = Object.values(totalRevenueGR);
                } else if (peopleKpiT12.includes(key) || customerKPIKeys.includes(key) || key === 'customer_roi' || key === 'number_of_new_customers_needed_to_maintain_growth') {
                    let t12Obj = peopleCustomersT12Metrics(props.page, key, calcs.you, previousYearCalcs.you, previous_yoy_calcs.you)
                    dataMap = Object.values(t12Obj[key])
                } else if (sheet === 'pnl' || sheet === 'balance_sheet' || sheet === 'financial_detail' || sheet === 'kpi') {
                    let monthsAvailable = 0;
                    let useableMonths = Object.values(calcs['you']['cash']);
                    useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthsAvailable += 1 : null);
                    const you = calcs['you'] ? calcs['you'][key] : undefined;
                    dataMap = MonthlyPNLandBalValues(monthsAvailable, sheet, key, you, calcs['you'])
                } else {
                    let returnObj = {};
                    let metricValues = _.pickBy(calcs.you[key], _.isNumber);
                    for (let month in metricValues) {
                        returnObj[month] = metricValues[month]
                    }
                    dataMap = Object.values(returnObj);
                }
            } else {
                let returnObj = {};
                let metricValues = _.pickBy(props.youMetrics[key], _.isNumber);
                for (let month in metricValues) {
                    returnObj[month] = metricValues[month]
                }
                dataMap = Object.values(returnObj);
            }
        }
        return dataMap;
    }

    const buildCompareLineOrColData = (chart, sheet = '') => {
        const key = chart.key;
        let analysisPerClientMetrics = ['revenue_per_customer', 'gross_profit_per_customer', 'operating_expenses_net_income_per_customer'];
        let dataMap = [];
        if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
            dataMap = chart.years.map(year => {
                let value;
                let compareAvg = {}

                if (props.compareWith === 'Peers') {
                    compareAvg = calcs['avg']
                } else if (props.compareWith === 'Practice') {
                    compareAvg = calcs['practice_avg']
                } else if (props.compareWith === 'NSCHBC') {
                    compareAvg = calcs['nschbc']
                }

                try {
                    if (key === 'net_working_capital') {
                        let totalCurrentAssets = compareAvg['total_current_assets'][year];
                        let totalCurrentLiabilities = compareAvg['total_current_liabilities'][year];
                        value = totalCurrentAssets - totalCurrentLiabilities;
                    } else if (key === 'senior_debt_to_ebitda') {
                        let longTermDebt = compareAvg['long_term_debt'][year];
                        let lineOfCredit = compareAvg['line_of_credit'][year];
                        let currentPortionOfLTD = compareAvg['current_portion_of_ltd'][year];
                        let netIncome = compareAvg['operating_expenses_net_income'][year];
                        let interest = compareAvg['interest_and_other_expenses'][year];
                        let taxes = compareAvg['total_other_costs_taxes_- federal & state'][year];
                        let deprAmor = compareAvg['depreciation_and_amortization'][year];
                        let ebitda = netIncome + interest + taxes + deprAmor;
                        value = (longTermDebt + lineOfCredit + currentPortionOfLTD) / ebitda;
                    } else if (key === 'ebitda_to_annual_interest') {
                        let netIncome = compareAvg['operating_expenses_net_income'][year];
                        let interest = compareAvg['interest_and_other_expenses'][year];
                        let taxes = compareAvg['total_other_costs_taxes_- federal & state'][year];
                        let deprAmor = compareAvg['depreciation_and_amortization'][year];
                        let ebitda = netIncome + interest + taxes + deprAmor;
                        value = ebitda / interest;
                    } else if (props.page === 'customers' && analysisPerClientMetrics.includes(key)) {
                        value = peersAnalysisPerCustomerVals(key, year, compareAvg)
                    } else if (customerKPIKeys.includes(key)) {
                        value = compareAvg[key][year]
                    } else if (customerPercentKeys.includes(key) || peoplePercentKeys.includes(key)) {
                        value = compareAvg[key][year] / 100
                    } else if (sheet === 'pnl' || sheet === 'financial_detail' || sheet === 'kpi') {
                        value = compareAvg[key + '_common_size_revenue'] ? compareAvg[key + '_common_size_revenue'][year] : null;
                    } else if (sheet === 'balance_sheet') {
                        value = compareAvg[key + '_common_size_assets'] ? compareAvg[key + '_common_size_assets'][year] : null;
                    } else {
                        value = compareAvg[key][year];
                    }
                    return value ? value : null;
                } catch (e) {
                    return null;
                }
            })
        }

        if (props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date') {
            if (props.page === 'money' || props.page === 'people' || props.page === 'customers') {
                dataMap = chart.years.map(year => {
                    try {
                        if (chart.percentOfRevenueKey) {
                            let value = ytdPercentOfRevenueCalc(chart.percentOfRevenueKey, key, calcs[year]['avg']);
                            return value;
                        } else if (key === 'senior_debt_to_ebitda') {
                            let value = calculateSeniorDebtToEBITDA(calcs[year]['avg'])
                            return value;
                        } else if (key === 'ebitda_to_annual_interest') {
                            let value = calculateEBITDAtoAnnualInterest(calcs[year]['avg'])
                            return value;
                        } else if (key === 'total_revenue_growth_rate') {
                            let totalRevenueGR = ytdTotalRevenueGRCalc(calcs[year]['avg'], previousYearCalcs['monthly'][year - 1]['avg'])
                            return totalRevenueGR;
                        } else if (peopleKpiT12.includes(key) || customerKPIKeys.includes(key) || key === 'customer_roi' || key === 'number_of_new_customers_needed_to_maintain_growth') {
                            const compareWithYTD = previousYearCalcs['monthly'][year]['avg']
                            const compareWithPreviousYearCalcsYTD = previousYearCalcs['monthly'][year - 1]['avg']
                            const compareWithPreviousYOYCalcsYTD = previousYearCalcs['monthly'][year - 2]['avg']
                            let lastMonthNumber = 0;
                            let useableMonths = Object.values(calcs[year]['you']['roa']);
                            useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);
                            let t12Obj = peopleCustomersT12Metrics(props.page, key, compareWithYTD, compareWithPreviousYearCalcsYTD, compareWithPreviousYOYCalcsYTD)
                            return t12Obj[key][lastMonthNumber]
                        } else if (sheet === 'pnl' || sheet === 'balance_sheet' || sheet === 'financial_detail' || sheet === 'kpi') {
                            const yearVals = calcs[year] ? calcs[year]['avg'][key] : undefined;
                            let value = YTDPnlandBalValues(sheet, key, yearVals, calcs[year]['avg'])
                            let finalVal = YTDPnlandBalCommonSizeValue(value, sheet, calcs[year]['avg'])
                            return finalVal
                        } else {
                            let value = calculateKFIMetric(key, calcs[year]['avg'], previousYearCalcs['monthly'][year - 1]['avg'])
                            return value;
                        }
                    } catch (e) {
                        return null;
                    }
                })
            } else {
                let selectedYearVal, previousYearVal = null;
                let monthNumber = 0;
                let useableMonths = Object.values(calcs[props.year]['you']['roa']);
                useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthNumber += 1 : null);

                if (key === 'total_revenue' || key === 'gross_profit' || key === 'operating_expenses_net_income') {
                    selectedYearVal = objReducer(calcs[props.year]['avg'][key])
                    previousYearVal = objReducer(calcs[props.year - 1]['avg'][key])
                } else {
                    if (props.compareMetrics[key]) {
                        selectedYearVal = props.compareMetrics[key][monthNumber];
                    }
                    if (props.comparePreviousYearMetricsYTD[key]) {
                        previousYearVal = props.comparePreviousYearMetricsYTD[key][monthNumber];
                    }
                }

                dataMap = [previousYearVal, selectedYearVal]
            }
        }

        if (props.displayColumnsBy === 'Months') {
            let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

            if (props.page === 'money' || props.page === 'people' || props.page === 'customers') {
                if (chart.compareWith === 'YOY' || chart.compareWith === 'Default:YOY') {
                    if (chart.trailing12Key) {
                        let trailing12Obj = trailing12MonthCalculations(chart.trailing12Key, previousYearCalcs.you, previous_yoy_calcs.you)
                        dataMap = Object.values(trailing12Obj);
                    } else if (chart.percentOfRevenueKey) {
                        let percentOfRevenueObj = monthlyPercentOfRevenueCalc(chart.percentOfRevenueKey, key, previousYearCalcs.you);
                        dataMap = Object.values(percentOfRevenueObj);
                    } else if (key === 'current_ratio') {
                        let currentRatioObj = ratioCalculations('total_current_assets', previousYearCalcs.you)
                        dataMap = Object.values(currentRatioObj);
                    } else if (key === 'net_working_capital_ratio') {
                        let netWorkingCapRatioObj = netWorkingCapitalRatioCalculation(previousYearCalcs.you)
                        dataMap = Object.values(netWorkingCapRatioObj);
                    } else if (key === 'net_working_capital') {
                        let netWorkingCapitalObj = netWorkingCapitalCalculation(previousYearCalcs.you)
                        dataMap = Object.values(netWorkingCapitalObj)
                    } else if (key === 'total_revenue_growth_rate') {
                        let totalRevenueGR = monthlyTotalRevenueGRCalc(previousYearCalcs.you, previous_yoy_calcs.you)
                        dataMap = Object.values(totalRevenueGR);
                    } else if (peopleKpiT12.includes(key) || customerKPIKeys.includes(key) || key === 'customer_roi' || key === 'number_of_new_customers_needed_to_maintain_growth') {
                        let t12Obj = peopleCustomersT12Metrics(props.page, key, previousYearCalcs.you, previous_yoy_calcs.you, calcs_3_years_back.you)
                        dataMap = Object.values(t12Obj[key])
                    } else if (sheet === 'pnl' || sheet === 'balance_sheet' || sheet === 'financial_detail' || sheet === 'kpi') {
                        const avg = calcs['avg'] ? calcs['avg'][key] : undefined;
                        const yoy = yoy_calcs['you'] ? yoy_calcs['you'][key] : undefined;
                        let monthsAvailable = 0;
                        let useableMonths = Object.values(calcs['you']['cash']);
                        useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthsAvailable += 1 : null);
                        dataMap = MonthlyPNLandBalValues(monthsAvailable, sheet, key, yoy, yoy_calcs['you'])
                    } else {
                        let returnObj = {};
                        let metricValues = _.pickBy(previousYearCalcs.you[key], _.isNumber);
                        for (let month in metricValues) {
                            returnObj[month] = metricValues[month]
                        }
                        dataMap = Object.values(returnObj);
                    }
                }

                if (chart.compareWith === 'Peers' || chart.compareWith === 'Default:Peers') {
                    if (chart.trailing12Key) {
                        let trailing12Obj = trailing12MonthCalculations(chart.trailing12Key, calcs.avg, previousYearCalcs.avg)
                        dataMap = Object.values(trailing12Obj);
                    } else if (chart.percentOfRevenueKey) {
                        let percentOfRevenueObj = monthlyPercentOfRevenueCalc(chart.percentOfRevenueKey, key, calcs.avg);
                        dataMap = Object.values(percentOfRevenueObj);
                    } else if (key === 'current_ratio') {
                        let currentRatioObj = ratioCalculations('total_current_assets', calcs.avg)
                        dataMap = Object.values(currentRatioObj);
                    } else if (key === 'net_working_capital_ratio') {
                        let netWorkingCapRatioObj = netWorkingCapitalRatioCalculation(calcs.avg)
                        dataMap = Object.values(netWorkingCapRatioObj);
                    } else if (key === 'net_working_capital') {
                        let netWorkingCapitalObj = netWorkingCapitalCalculation(calcs.avg)
                        dataMap = Object.values(netWorkingCapitalObj)
                    } else if (key === 'total_revenue_growth_rate') {
                        let totalRevenueGR = monthlyTotalRevenueGRCalc(calcs.avg, previousYearCalcs.avg)
                        dataMap = Object.values(totalRevenueGR);
                    } else if (peopleKpiT12.includes(key) || customerKPIKeys.includes(key) || key === 'customer_roi' || key === 'number_of_new_customers_needed_to_maintain_growth') {
                        let t12Obj = peopleCustomersT12Metrics(props.page, key, calcs.avg, previousYearCalcs.avg, previous_yoy_calcs.you)
                        dataMap = Object.values(t12Obj[key])
                    } else if (sheet === 'pnl' || sheet === 'balance_sheet' || sheet === 'financial_detail' || sheet === 'kpi') {
                        const avg = calcs['avg'] ? calcs['avg'][key] : undefined;
                        const yoy = yoy_calcs['you'] ? yoy_calcs['you'][key] : undefined;
                        let monthsAvailable = 0;
                        let useableMonths = Object.values(calcs['you']['cash']);
                        useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthsAvailable += 1 : null);
                        dataMap = MonthlyPNLandBalValues(monthsAvailable, sheet, key, avg, calcs['avg'])
                    } else {
                        let returnObj = {};
                        let metricValues = _.pickBy(calcs.avg[key], _.isNumber);
                        for (let month in metricValues) {
                            returnObj[month] = metricValues[month]
                        }
                        dataMap = Object.values(returnObj);
                    }
                }

                if (chart.compareWith.includes('Forecast')) {
                    if (chart.trailing12Key) {
                        let trailing12Obj = trailing12MonthCalculations(chart.trailing12Key, props.forecastVals, previousYearCalcs.you)
                        dataMap = Object.values(trailing12Obj);
                    } else if (chart.percentOfRevenueKey) {
                        let percentOfRevenueObj = monthlyPercentOfRevenueCalc(chart.percentOfRevenueKey, key, props.forecastVals);
                        dataMap = Object.values(percentOfRevenueObj);
                    } else if (key === 'current_ratio') {
                        let currentRatioObj = ratioCalculations('total_current_assets', props.forecastVals)
                        dataMap = Object.values(currentRatioObj);
                    } else if (key === 'net_working_capital_ratio') {
                        let netWorkingCapRatioObj = netWorkingCapitalRatioCalculation(props.forecastVals)
                        dataMap = Object.values(netWorkingCapRatioObj);
                    } else if (key === 'net_working_capital') {
                        let netWorkingCapitalObj = netWorkingCapitalCalculation(props.forecastVals)
                        dataMap = Object.values(netWorkingCapitalObj)
                    } else if (key === 'total_revenue_growth_rate') {
                        let totalRevenueGR = monthlyTotalRevenueGRCalc(props.forecastVals, previousYearCalcs.you)
                        dataMap = Object.values(totalRevenueGR);
                    } else if (sheet === 'pnl' || sheet === 'balance_sheet') {
                        const forecast = props.forecastVals ? props.forecastVals[key] : undefined;
                        let monthsAvailable = 0;
                        let useableMonths = Object.values(calcs['you']['cash']);
                        useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthsAvailable += 1 : null);
                        dataMap = MonthlyPNLandBalValues(monthsAvailable, sheet, key, forecast, props.forecastVals)
                    } else {
                        let returnObj = {};
                        let metricValues = _.pickBy(props.forecastVals[key], _.isNumber);
                        for (let month in metricValues) {
                            returnObj[month] = metricValues[month]
                        }
                        dataMap = Object.values(returnObj);
                    }
                }
            } else {
                let returnObj = {};
                let metricValues = _.pickBy(props.compareMetrics[key], _.isNumber);
                for (let month in metricValues) {
                    returnObj[month] = metricValues[month]
                }
                dataMap = Object.values(returnObj);
            }
        }
        return dataMap
    }

    const MetricNotes = ({ noteProps, parentNotes }) => {
        const [metricNotes, setMetricNotes] = useState('' || parentNotes)
        const [timer, setTimer] = useState(null)
        const [count, setCount] = useState(0);

        const handleNewNotes = (newNotes) => {
            let notes = newNotes.length >= 1100 ? newNotes.substring(0, 1100) : newNotes;
            setMetricNotes(notes)

            clearTimeout(timer)
            const newTimer = setTimeout(() => {
                props.handleMetricNotes(noteProps.metricRow.relatedCheckedKey, newNotes);
            }, 2000)
            setTimer(newTimer)
        }

        return (
            <>
                <h3 style={{ 'color': '#000000', 'fontSize': '18px', 'fontWeight': 'normal', 'margin': '5px', 'textAlign': 'center' }}>Notes</h3>
                {props.pdf
                    ? <div className='my-notes-dashboard'>{metricNotes}</div>
                    : <textarea className='my-notes-dashboard' value={metricNotes} onChange={(e) => handleNewNotes(e.target.value)} />
                }
            </>
        )
    }

    const costOfTurnover = () => {
        const lostProductivity = .2;
        const lostProductivityTime = .125;
        const lostWagesTime = .125;
        const employeeProductivityDecline = .5;
        const employeeProductivityDeclineTime = .185;

        // Cost of Turnover Formula
        // (Your Turnover Rate(year) − Peer Turnover Rate(year)) 
        // * (Number of Employees(year) 
        // * ((Revenue Per Employee(year) * Lost Productivity * Lost Productivity Time) 
        //   + (Average Wages and Salaries(year) * Lost Wages Time ) 
        //   + ((.25 * Training(year))/Number of Employees(year))
        //   + (Revenue Per Employee(year) * Employee Productivity Decline * Employee Productivity Decline Time)))

        if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
            if (calcs.you.turnover_rate[props.year] !== 0 && calcs.you.total_number_of_employees[props.year] !== 0 && calcs.you.revenue_per_employee[props.year] !== 0 && calcs.you.average_wages_and_salaries[props.year] !== 0) {
                return ((calcs.you.turnover_rate[props.year] - calcs.avg.turnover_rate[props.year])
                    * (calcs.you.total_number_of_employees[props.year]
                        * ((calcs.you.revenue_per_employee[props.year] * lostProductivity * lostProductivityTime)
                            + (calcs.you.average_wages_and_salaries[props.year] * lostWagesTime)
                            + ((calcs.you.training[props.year] * .25) / calcs.you.total_number_of_employees[props.year])
                            + (calcs.you.revenue_per_employee[props.year] * employeeProductivityDecline * employeeProductivityDeclineTime))));
            } else {
                return 0;
            };
        }

        return 0;
    }

    const buildRankingTable = (chartProps, rankingInfo) => {
        let top10 = rankingInfo[chartProps.metricKey].slice(0, 10);
        return (
            <div className='snapshot-table' key={chartProps.key}>
                <table>
                    <thead>
                        <tr>
                            <th className='snapshot-title snap-cell' colSpan='100%'>{chartProps.title}</th>
                        </tr>
                        <tr className='snapshot-header-cols'>
                            {
                                (chartProps.headerCols.map((h) => {
                                    return <th key={`${chartProps.title}-${h}`}><div className='snap-cell'><p>{h}</p></div></th>
                                }))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (top10.map((s, i) => {
                                let rowColor = i % 2 === 0 ? { backgroundColor: '#F5F5F5' } : {}
                                return (
                                    <tr key={`${chartProps.key}-row-${i}`} style={rowColor}>
                                        {chartProps.keys.map((k) => {
                                            if (Array.isArray(k)) {
                                                let key = k[0]
                                                return <td key={`snap-cell-${k}`}><div className='snap-cell'><p>{format(k[1], s[key])}</p></div></td>
                                            } else {
                                                return <td key={`snap-cell-${k}`}><div className='snap-cell'><p>{s[k]}</p></div></td>
                                            }
                                        })}
                                    </tr>
                                )
                            }))
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    const getPrintPositionStyles = (item) => {
        let setParentWidths = {
            "34mm": 127,
            "69mm": 127,
            "104mm": 127,
            "139mm": 128,
            "174mm": 128.5,
            "209mm": 129
        }
        let setChildWidth = setParentWidths[props.positionStyles['width']]

        const printStyles = { position: 'absolute', border: '1px solid black', borderRadius: '2px', backgroundColor: 'white' }
        let width = item.w > 1 ? (item.w * 127) + (item.w - 1) : item.w * setChildWidth
        let setHeightNum = item.h > 4 ? 4 : item.h
        let height = setHeightNum > 1 ? (setHeightNum * 127) + (setHeightNum - 1) : setHeightNum * 127
        let posX = (item.x * setChildWidth) + ((item.x + 1) * 2)
        let posY = (item.y * 127) + ((item.y + 1) * 2)

        printStyles.width = `${width}px`
        printStyles.height = `${height}px`
        printStyles.left = `${posX}px`
        printStyles.top = `${posY}px`
        return printStyles
    }

    // react-grid-layout recommends memoizing items in layout to optimize rendering
    let layoutItems = useMemo(() => {
        return props.dashboardLayout.map((l) => {
            let positionStyles = props.pdf ? getPrintPositionStyles(l) : {}

            if ((props.page === 'money' && l.i.includes('_bar_chart')) || (props.page === 'people' && l.i.includes('chart')) || (props.page === 'customers' && l.i.includes('chart'))) {
                let parentMetric = props.parentDashBoardInfo
                let chartKey = l.key
                let youData, compareData, chartOptions;

                let chartProps = {
                    i: l.i,
                    chartType: 'editableChart',
                    aggregateYTDDate: props.aggregateYTDDate || null,
                    companyYTDDate: props.companyYTDDate || null,
                    format: parentMetric.sheet !== 'kfis' && parentMetric.sheet !== 'financial_detail' && parentMetric.sheet !== 'kpi_people' && parentMetric.sheet !== 'kpi' && props.displayColumnsBy === 'Months' ? 'money' : parentMetric.format,
                    compareWith: props.compareWith || null,
                    compareWithTitle: props.monthlyCompareWithTitle || null,
                    displayColumnsBy: props.displayColumnsBy || null,
                    fiscalYearEnd: props.fiscalYearEnd || '12/31',
                    key: parentMetric.key,
                    months: props.monthLabels || null,
                    page: props.page,
                    parentKey: parentMetric.key,
                    percentOfRevenueKey: parentMetric.percentOfRevenueKey,
                    pdf: props.pdf,
                    reportPeriod: props.reportPeriod,
                    styles: props.parentStyles || { seriesOne: { type: 'column' }, seriesTwo: { type: 'column' } },
                    title: titleSetter(parentMetric),
                    years: props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date' ? [props.year - 1, props.year] : props.yearRange,
                }

                if (parentMetric.sheet === 'pnl' || parentMetric.sheet === 'balance_sheet' || parentMetric.sheet === 'financial_detail' || parentMetric.sheet === 'kpi_people' || parentMetric.sheet === 'kpi') {
                    youData = buildYouLineOrColData(chartProps, parentMetric.sheet);
                    compareData = props.compareWith !== 'None' && props.compareWith !== 'Default:None' ? buildCompareLineOrColData(chartProps, parentMetric.sheet) : null;
                }

                if (parentMetric.sheet === 'cash_flow') {
                    if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
                        let cashValues = AnnualCashFlowValues(calcs, props.calcsAvgType, props.yearRange, parentMetric.key)
                        youData = []
                        compareData = []
                        props.yearRange.forEach(year => {
                            if (cashValues['you' + year]) { youData.push(cashValues['you' + year]) }
                            if (props.compareWith !== 'None' && cashValues['avg' + year] && props.compareWith !== 'Default:None') { compareData.push(cashValues['avg' + year]) }
                        })
                    }

                    if (props.displayColumnsBy === 'Months') {
                        youData = []
                        compareData = []
                        const calcsYou = calcs.you;
                        const previousYearCalcsYou = previousYearCalcs.you;
                        let compareVals = null;
                        let prevYearCompareVals = null;
                        if (props.compareWith === 'Peers' || props.compareWith === 'Default:Peers') {
                            compareVals = calcs.avg;
                            prevYearCompareVals = previousYearCalcs.avg;
                        }
                        if (props.compareWith === 'YOY' || props.compareWith === 'Default:YOY') {
                            compareVals = previous_yoy_calcs.you;
                            prevYearCompareVals = yoy_calcs.you;
                        }
                        if (props.compareWith.includes('Forecast')) {
                            compareVals = props.forecastVals
                            prevYearCompareVals = previousYearCalcsYou;
                        }
                        let { youValues, compareValues } = MonthlyCashFlowValues(parentMetric.key, calcsYou, previousYearCalcsYou, compareVals, prevYearCompareVals)
                        Object.values(youValues).forEach(yv => {
                            youData.push(yv)
                        })
                        if (props.compareWith !== 'None' && props.compareWith !== 'Default:None') {
                            Object.values(compareValues).forEach(cv => {
                                compareData.push(cv)
                            })
                        }
                    }

                    if (props.reportPeriod === 'Year to Date') {
                        function commonSizeValue(props) {
                            let finalValue = null;
                            if (Object.values(props.passedCalcs).length > 0) {
                                const val = props.value;
                                const totalRevenue = Object.values(props.passedCalcs['total_revenue']).reduce((a, b) => a + b, 0)
                                finalValue = (val / totalRevenue)
                            }
                            return finalValue
                        }
                        const calcYearKeys = Object.keys(calcs);
                        const previousYearCalcKeys = Object.keys(previousYearCalcs['monthly_full_year']);
                        let oldYear, newYear;
                        let previousOldFullYear = previousYearCalcKeys[0];
                        let previousNewFullYear = previousYearCalcKeys[1];
                        if (Number(calcYearKeys[1]) > Number(calcYearKeys[0])) {
                            newYear = calcYearKeys[1];
                            oldYear = calcYearKeys[0];
                        } else {
                            newYear = calcYearKeys[0];
                            oldYear = calcYearKeys[1];
                        }
                        const calcsYou = calcs[newYear].you;
                        const calcsPeers = props.compareWith === 'Peers' ? calcs[newYear].avg : null;

                        const calcsYouOldYear = calcs[oldYear].you;
                        const calcsPeersOldYear = props.compareWith === 'Peers' ? calcs[oldYear].avg : null;

                        const previousYearCalcsYou = previousYearCalcs['monthly_full_year'][previousNewFullYear].you;
                        const previousYearCalcsPeers = props.compareWith === 'Peers' ? previousYearCalcs['monthly_full_year'][previousNewFullYear].avg : null;

                        const previousYearCalcsYouOldYear = previousYearCalcs['monthly_full_year'][previousOldFullYear].you;
                        const previousYearCalcsPeersOldYear = props.compareWith === 'Peers' ? previousYearCalcs['monthly_full_year'][previousOldFullYear].avg : null;

                        let newerYearVals = YTDCashFlowValues(parentMetric.key, calcsYou, previousYearCalcsYou, calcsPeers, previousYearCalcsPeers);
                        let newerYearYouVal = newerYearVals['youValue'];
                        let newerYearCompareVal = newerYearVals['compareValue'];
                        let olderYearVals = YTDCashFlowValues(parentMetric.key, calcsYouOldYear, previousYearCalcsYouOldYear, calcsPeersOldYear, previousYearCalcsPeersOldYear);
                        let olderYearYouVal = olderYearVals['youValue'];
                        let olderYearCompareVal = olderYearVals['compareValue'];

                        let valuePYMonthlyCommonSize = commonSizeValue({ value: olderYearYouVal, passedCalcs: calcsYouOldYear })
                        let valuePYPeersMonthlyCommonSize = props.compareWith === 'Peers' ? commonSizeValue({ value: olderYearCompareVal, passedCalcs: calcsPeersOldYear }) : null;
                        let valueCYMonthlyCommonSize = commonSizeValue({ value: newerYearYouVal, passedCalcs: calcsYou })
                        let valueCYPeersMonthlyCommonSize = props.compareWith === 'Peers' ? commonSizeValue({ value: newerYearCompareVal, passedCalcs: calcsPeers }) : null;

                        youData = [valuePYMonthlyCommonSize, valueCYMonthlyCommonSize]
                        compareData = props.compareWith === 'Peers' ? [valuePYPeersMonthlyCommonSize, valueCYPeersMonthlyCommonSize] : null;
                    }
                }

                if (parentMetric.sheet === 'kfis') {
                    if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
                        let kfisValues = AnnualKFIsValues(calcs, props.calcsAvgType, props.yearRange, parentMetric.key)
                        youData = []
                        compareData = []
                        props.yearRange.forEach(year => {
                            if (kfisValues[year]) { youData.push(kfisValues[year]) }
                            if (props.compareWith !== 'None' && kfisValues['avg' + year] && props.compareWith !== 'Default:None') { compareData.push(kfisValues['avg' + year]) }
                        })
                    }

                    if (props.displayColumnsBy === 'Months') {
                        youData = []
                        compareData = []
                        let numberOfMonths = 0
                        let useableMonths = Object.values(calcs['you']['cash']);
                        useableMonths.forEach(thing => thing !== null ? numberOfMonths += 1 : null)
                        let { youValues, compareValues } = MonthlyKFIsValues(parentMetric, numberOfMonths, props.compareWith, calcs, previousYearCalcs, previous_yoy_calcs, props.forecastVals)
                        Object.values(youValues).forEach(yv => {
                            youData.push(yv)
                        })
                        if (props.compareWith !== 'None' && props.compareWith !== 'Default:None') {
                            Object.values(compareValues).forEach(cv => {
                                compareData.push(cv)
                            })
                        }
                    }

                    if (props.reportPeriod === 'Year to Date') {
                        youData = []
                        compareData = []
                        chartProps.years.forEach(year => {
                            let youKfiMetric, peersKfiMetric = null
                            let youMetric = calculateKFIMetric(parentMetric.key, calcs[year]['you'], previousYearCalcs['monthly'])
                            youData.push(youMetric)
                            if (props.compareWith === 'Peers') {
                                let peersMetric = calculateKFIMetric(parentMetric.key, calcs[year]['avg'], previousYearCalcs['monthly'])
                                compareData.push(peersMetric)
                            }
                        })
                    }
                }
                chartOptions = getLineOrColOptions(chartProps, youData, compareData, props.fiscalYearEnd);

                return (
                    <div key={l.i} style={positionStyles}>
                        <button className='edit-dash-item-btn' onClick={() => props.handleEditChartModal('open', chartProps, chartOptions)}><FontAwesomeIcon icon={faPencil} /></button>
                        <HighchartsReact ref={setNewRef} containerProps={{ style: { width: '100%', height: '100%' }, key: l.i, id: l.i }} highcharts={Highcharts} options={chartOptions} />
                    </div>
                )
            } else if (l.i.includes('impact_box')) {
                let parentMetric = props.parentDashBoardInfo
                let finalCopy = parentMetric.copy
                let boxStyle = {
                    'backgroundColor': 'white',
                    'display': 'flex',
                    'flexDirection': 'column',
                    'justifyContent': 'space-evenly',
                    'alignItems': 'center',
                    'height': '100%',
                    'width': '100%',
                    'textAlign': 'center'
                }
                let statusTextColor = {
                    'fontSize': '24px',
                    'fontWeight': 'bold'
                }
                let allStyles = { ...boxStyle, ...positionStyles }
                statusTextColor['fontSize'] = props.pdf ? '18px' : '24px'
                let impactTextStyle = props.pdf ? { fontSize: '0.7em' } : { fontSize: '15px' }
                if (l.i.includes('labor_cogs')) {
                    finalCopy = `Labor COGS - ${parentMetric.copy}`
                }

                if (props.compareWith === 'None' || props.compareWith === 'Default:None') {
                    return (
                        <div key={l.i} className='impact-square' style={allStyles}>
                            <div style={{ marginBottom: '5px' }}>
                                <div style={impactTextStyle}>{`${finalCopy} Impact Box`}</div>
                            </div>
                            <div>
                                <div style={impactTextStyle}>Compare with None Selected</div>
                            </div>
                        </div>
                    )
                } else {
                    // Annual PNL and Balance Sheet use common_size keys, Cash Flow and Kfis are calculated
                    let { copy, impactText, status, deltaCommonSize, impactValueFormatted } = buildImpactBox(parentMetric, parentMetric['sheet'], props.displayColumnsBy, props.reportPeriod, props.compareWith, props.year, props.yearRange, calcs, previousYearCalcs, yoy_calcs, previous_yoy_calcs, props.calcsAvgType, calcs_3_years_back, props.monthlyCompareWithTitle, props.forecastVals)
                    if (!impactText) {
                        impactText = noSpecificTextMaker(finalCopy, status, impactValueFormatted, deltaCommonSize, props.compareWith, props.monthlyCompareWithTitle)
                    }
                    let impactTextClass = impactBoxStatusColor(status)

                    if (impactText) {
                        return (
                            <div key={l.i} className='impact-square' style={allStyles}>
                                <div style={{ marginBottom: '5px' }}>
                                    <span className={impactTextClass} style={statusTextColor}>{deltaCommonSize}</span>
                                    <div style={impactTextStyle}>{impactText['text1']}</div>
                                </div>
                                <div>
                                    <span className={impactTextClass} style={statusTextColor}>{impactValueFormatted}</span>
                                    <div style={impactTextStyle}>{impactText['textNotesSection']}</div>
                                </div>
                            </div>
                        )
                    }
                }
            } else if (l.i.includes('notes')) {
                return (
                    <div key={l.i} style={positionStyles}>
                        {props.pdf ? null : <button className='lock-btn' onClick={() => props.handleToggleDraggable(l.i, 'moneyDashboard', props.parentDashBoardInfo)}>{l.isDraggable ? <FontAwesomeIcon icon={faLockOpen} /> : <FontAwesomeIcon icon={faLock} />}</button>}
                        <MetricNotes noteProps={l} parentNotes={props.parentNotes} />
                    </div>
                )
            } else {
                let chart;
                if (props.page === 'practice') {
                    chart = pageCharts['practice']['dashboard_items'].find(c => c.key === l.i);
                } else if (props.page === 'customers') {
                    chart = pageCharts['customers']['dashboard_items'].find(c => c.key === l.i);
                } else if (props.page === 'people') {
                    chart = pageCharts['people'].find(c => c.key === l.i);
                } else {
                    chart = moneyCharts[props.page].find(c => c.key === l.i);
                }

                if (chart) {
                    let chartKey = chart.key
                    let youData, compareData, chartOptions;
                    let chartProps = {
                        i: l.i,
                        aggregateYTDDate: props.aggregateYTDDate || null,
                        companyYTDDate: props.companyYTDDate || null,
                        format: chart.format,
                        headerCols: chart.headerCols,
                        compareWith: props.compareWith || null,
                        compareWithTitle: props.monthlyCompareWithTitle || null,
                        displayColumnsBy: props.displayColumnsBy || null,
                        fiscalYearEnd: props.fiscalYearEnd || '12/31',
                        key: chartKey,
                        keys: chart.keys || null,
                        metricKey: chart.metricKey || null,
                        months: props.monthLabels || null,
                        page: props.page,
                        parentKey: props.parentDashBoardInfo.key,
                        pdf: props.pdf || false,
                        percentOfRevenueKey: chart.percentOfRevenueKey || props.parentDashBoardInfo.percentOfRevenueKey || null,
                        reportPeriod: props.reportPeriod,
                        title: titleSetter(chart),
                        subtitle: chart.subtitle,
                        trailing12Key: chart.trailing12Key || null,
                        styles: props.parentStyles || chart.styles,
                        x: chart.x,
                        y: chart.y,
                        years: props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date' ? [props.year - 1, props.year] : props.yearRange,
                    }

                    switch (chart.chartType) {
                        case '3DBubble':
                            chartOptions = get3DBubbleChartOptions(chartProps, props.calculations);
                            break;
                        case 'quadrantBubble':
                            if (props.page === 'practice') {
                                chartOptions = getQuadrantOptions(chartProps, props.calculations);
                            }
                            if (props.page === 'money') {
                                let chartData = { name: props.naicsCode, data: [] };
                                let dataObj = { name: props.clientName, color: '#69B144' }
                                if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
                                    dataObj.x = Math.round(calcs.you[chartProps.keys[0]][props.year])
                                    dataObj.y = Math.round(calcs.you[chartProps.keys[1]][props.year])
                                    dataObj.z = calcs.you['total_revenue'][props.year]
                                    chartData.data.push(dataObj);
                                }
                                if (props.displayColumnsBy === 'Months' || props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date') {
                                    const peerviewMetrics = peerviewMetricsYears[props.year]['you'];
                                    dataObj.x = peerviewMetrics.hasOwnProperty(chartProps.keys[0]) ? peerviewMetrics[chartProps.keys[0]] : 0;
                                    dataObj.y = peerviewMetrics.hasOwnProperty(chartProps.keys[1]) ? peerviewMetrics[chartProps.keys[1]] : 0;
                                    dataObj.z = props.displayColumnsBy === 'Months' ? objReducer(calcs.you['total_revenue']) : objReducer(calcs[props.year]['you']['total_revenue']);
                                    chartData.data.push(dataObj);
                                }
                                chartOptions = getQuadrantOptions(chartProps, chartData)
                            }
                            break;
                        case 'multiDataBarChart':
                            chartOptions = getMultiDataBarChartOptions(chartProps, props['rankingData'])
                            break;
                        case 'rankingTable':
                            return buildRankingTable(chartProps, props['rankingData'])
                            break;
                        case 'scatterPlot':
                            chartOptions = getScatterPlotOptions(chartProps, props.calculations);
                            break;
                        case 'line':
                            let lineData = buildIndustryLineData(chartProps)
                            chartOptions = getIndustryLineChartOptions(chartProps, lineData)
                            break;
                        case 'bar':
                            let barData = buildIndustryBarData(chartProps)
                            chartOptions = getIndustryBarChartOptions(chartProps, barData)
                            break;
                        case 'editableChart':
                        case 'lineBar':
                            youData = buildYouLineOrColData(chartProps);
                            compareData = chartProps.compareWith !== 'None' && props.compareWith !== 'Default:None' ? buildCompareLineOrColData(chartProps) : null;
                            chartOptions = getLineOrColOptions(chartProps, youData, compareData, props.fiscalYearEnd);
                            break;
                        case 'verticalBars':
                            youData = buildVerticalBarData(chartProps, 'you');
                            compareData = chartProps.compareWith !== 'None' && props.compareWith !== 'Default:None' ? buildVerticalBarData(chartProps, chartProps.compareWith) : null;
                            chartOptions = getVerticalBarChartOptions(chartProps, youData, compareData);
                            break;
                        case 'horizontalBars':
                            let xCategories = chart.keys.map((k) => COPY[k]);
                            let hBarSeries = buildHorizonalBarSeries(chartProps);
                            chartOptions = getHorizontalBarOptions(chartProps, xCategories, hBarSeries);
                            break;
                        case 'stackedBars':
                            youData = buildAPARAgingSeries(chartProps)
                            chartOptions = getStackedColumnOptions(chartProps, youData);
                            break;
                        case 'lines':
                            let seriesTitles = chart.keys.map((k) => COPY[k])
                            let lineSeries = buildLineSeries(chartProps)
                            chartOptions = getLineChartOptions(chartProps, seriesTitles, lineSeries);
                            break;
                        case 'wageDataScaffoldTable':
                            return (<div className='wage-data-scaffold' key={chartKey}><WageDataScaffold wageData={calcs.wagedata} /></div>)
                        case 'noChart':
                            let value = costOfTurnover();
                            return (
                                <div className='cost-of-turnover' key={chartKey}>
                                    <h3>Cost of Turnover</h3>
                                    <p>(Based on S.H.R.M. Standards)</p>
                                    <div className='turnover-border'>
                                        <h2 className='cost-of-turnover-value'>
                                            {parseMoney(Math.abs(value))}
                                        </h2>
                                        <p>{value < 0 ? 'Less' : 'More'} Than Your Peers</p>
                                    </div>
                                </div>
                            )
                    }

                    if (chart.dragTogglable) {
                        return (
                            <div key={chartKey} style={positionStyles}>
                                <button className='lock-btn' onClick={() => props.handleToggleDraggable(chartKey, props.parentDashBoardInfo)}>{l.isDraggable ? <FontAwesomeIcon icon={faLockOpen} /> : <FontAwesomeIcon icon={faLock} />}</button>
                                <HighchartsReact ref={setNewRef} containerProps={{ style: { width: '100%', height: '100%' }, key: chartKey, id: chartKey }} highcharts={Highcharts} options={chartOptions} />
                            </div>
                        )
                    } else {
                        return (
                            <div key={chartKey} style={positionStyles}>
                                {chart.chartType === "editableChart" || chart.chartType === "lineBar" ? <button className='edit-dash-item-btn' onClick={() => props.handleEditChartModal('open', chartProps, chartOptions)}><FontAwesomeIcon icon={faPencil} /></button> : null}
                                <HighchartsReact ref={setNewRef} containerProps={{ style: { width: '100%', height: '100%' }, key: chartKey, id: chartKey }} highcharts={Highcharts} options={chartOptions} />
                            </div>
                        )
                    }
                }
                return null
            }
        })

    }, [props.dashboardLayout, props.year, props.calculations, calcs, showChart, showImpactComment])

    if (props.pdf) {
        return (
            <>
                {layoutItems}
            </>
        )
    } else {
        return (
            <SizeMe
                monitorWidth
            >
                {({ size }) => (
                    <ResponsiveGridLayout
                        className='layout'
                        width={size.width}
                        breakpoints={{ lg: 1200, md: 1078, sm: 858, xs: 638, xxs: 428, xxxs: 200 }}
                        layouts={{ lg: props.dashboardLayout }}
                        cols={{ lg: 6, md: 5, sm: 4, xs: 3, xxs: 2, xxxs: 1 }}
                        rowHeight={199}
                        onDragStart={(a, b, c, d, e) => e.stopPropagation()}
                        isDraggable={true}
                        isDroppable={true}
                        isResizable={true}
                        preventCollision={true}
                        onResizeStop={(layout, oldItem, newItem) => {
                            // Force reflow() on specific highchart item when user resizes it.
                            let item = oldItem ? oldItem : newItem;
                            let checkChartRefs = _.isEmpty(chartRefs)
                            if (!checkChartRefs && item && chartRefs[item.i]) {
                                chartRefs[item.i].chart.reflow();
                            }
                            window.dispatchEvent(new Event('resize'))
                        }}
                        onLayoutChange={(layout) => {
                            props.handleOnLayoutChange('childMyDashBoard', layout, props.parentDashBoardInfo)
                            // Force reflow() on highcharts when different display columns by filter submits
                            // For some odd reason the highcharts are not resizing to new layout like before
                            let checkChartRefs = _.isEmpty(chartRefs)
                            if (!checkChartRefs) {
                                const childWidthHelper = { 1: 204, 2: 210, 3: 212, 4: 212, 5: 214, 6: 214 }
                                for (const chartRef in chartRefs) {
                                    if (chartRefs[chartRef].chart) {
                                        let chartInfo = layout.find(l => l.i === chartRef)
                                        if (chartInfo) {
                                            let chartWidth = chartInfo.w * childWidthHelper[chartInfo.w];
                                            let chartHeight = chartInfo.h * 204;
                                            chartRefs[chartRef].chart.setSize(chartWidth, chartHeight)
                                        } else {
                                            chartRefs[chartRef].chart.reflow();
                                        }
                                    }
                                }
                            }
                        }}
                    >
                        {layoutItems}
                    </ResponsiveGridLayout>
                )}
            </SizeMe>
        )
    }
}
export default ChildDashboard;