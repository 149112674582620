import React, { useState, useContext, useRef, useMemo } from 'react';
import { Data } from './contexts';
import { CommonSizeArrows } from './report_components/MoneyHelpers';
import { ratioCalculations, ratioCalculationsMoney, calculateEBITDA, netWorkingCapitalCalculation, netWorkingCapitalRatioCalculation, trailing12MonthCalculations } from './monthly/monthy_analysis_table_trailing12_calculations';
import { objReducer, calculateKFIMetric, calculateSeniorDebtToEBITDA, calculateEBITDAtoAnnualInterest, monthlyTotalRevenueGRCalc, monthlyPercentOfRevenueCalc, ytdPercentOfRevenueCalc, ytdTotalRevenueGRCalc } from './report_components/MoneyHelpers';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { COPY } from './charts/chart_helpers';
import { pageCharts } from './charts';
import { gaugeKeys, getIndustryLineChartOptions, getIndustryBarChartOptions, get3DBubbleChartOptions, getQuadrantOptions, getScatterPlotOptions, getGaugeOptions, buildDrilldownData, getPieOptions, getDonutOptions, format, axisFormat, getLineOrColOptions, getVerticalBarChartOptions, getHorizontalBarOptions, getStackedColumnOptions, getLineChartOptions } from './charts/DashBoardHighChartsHelper';
import WageDataScaffold from './tables/WageDataScaffold';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts.js';
import highchartsMore from 'highcharts/highcharts-more.js';
import highchartsMap from 'highcharts/modules/map';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import HC_exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import drilldown from 'highcharts/modules/drilldown';
import { capitalize, parseMoney } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpen, faLock, faPencil } from '@fortawesome/free-solid-svg-icons';
const ResponsiveGridLayout = WidthProvider(Responsive);
highchartsMore(Highcharts);
highchartsMap(Highcharts);
solidGauge(Highcharts);
HC_exporting(Highcharts);
offlineExporting(Highcharts);
drilldown(Highcharts);

const ReportsDashboard = (props) => {
  const { peerviewMetricsYears, calcs, previousYearCalcs, yoy_calcs, previous_yoy_calcs } = useContext(Data);
  const [chartRefs, setChartRefs] = useState({})
  // Get an object of all the highcharts refs so that they reflow() or force resizing when user resizes them in the react-grid-layout
  const setNewRef = (ref) => {
    if (ref) {
      let itemId = ref.container.current.id;
      chartRefs[itemId] = ref;
      setChartRefs(prevState => {
        return {
          ...prevState,
          itemId: ref
        }
      })
    }
  };

  const titleSetter = (chart) => {
    if (props.displayColumnsBy === 'Months' && chart.key === 'total_revenue_growth_rate') {
      return 'Monthly Revenue % of Annual Total Revenue'
    } else {
      return (chart.title ? chart.title : COPY[chart.key])
    }
  }

  const buildIndustryLineData = (chart) => {
    let categories = [];
    const barColor = {
      industryMoney: '#69B144',
      industryPeople: '#00AEEF',
      industryCustomers: '#542667'
    }
    let key = chart.key;
    if (chart.key === 'profitability') {
      key = 'profit_margin'
    }
    let seriesData = []

    if (calcs['avg'] && calcs['avg'][key]) {
      seriesData = ['avg'].map((category, i) => {
        categories = Object.keys(calcs[category][key]);
        return {
          name: capitalize(category),
          title: capitalize(category),
          data: Object.values(calcs[category][key]),
          showInLegend: false,
          type: 'line',
          color: barColor[props.page],
          _colorIndex: i,
          _symbolIndex: i,
        }
      })
    }
    return { seriesData, categories }
  }

  const buildIndustryBarData = (chart) => {
    let categories;
    const barColor = {
      industryMoney: '#69B144',
      industryPeople: '#00AEEF',
      industryCustomers: '#542667'
    }
    let key = chart.key;
    let seriesData = ['avg'].map((category, i) => {
      categories = Object.keys(calcs[category][key]);
      let dataValues = [];
      if (key === 'senior_debt_to_ebitda') {
        dataValues = categories.map(year => {
          let value;
          let longTermDebt = calcs[category]['long_term_debt'][year];
          let lineOfCredit = calcs[category]['line_of_credit'][year];
          let currentPortionOfLTD = calcs[category]['current_portion_of_ltd'][year];
          let netIncome = calcs[category]['operating_expenses_net_income'][year];
          let interest = calcs[category]['interest_and_other_expenses'][year];
          let taxes = calcs[category]['total_other_costs_taxes_- federal & state'][year];
          let deprAmor = calcs[category]['depreciation_and_amortization'][year];
          let ebitda = netIncome + interest + taxes + deprAmor;
          return value = (longTermDebt + lineOfCredit + currentPortionOfLTD) / ebitda;
        })
      } else if (key === 'ebitda_to_annual_interest') {
        dataValues = categories.map(year => {
          let value;
          let netIncome = calcs[category]['operating_expenses_net_income'][year];
          let interest = calcs[category]['interest_and_other_expenses'][year];
          let taxes = calcs[category]['total_other_costs_taxes_- federal & state'][year];
          let deprAmor = calcs[category]['depreciation_and_amortization'][year];
          let ebitda = netIncome + interest + taxes + deprAmor;
          return value = ebitda / interest;
        })
      } else {
        dataValues = Object.values(calcs[category][key]);
      }
      return {
        name: capitalize(category),
        title: capitalize(category),
        data: dataValues,
        showInLegend: false,
        type: 'column',
        color: barColor[props.page],
        _colorIndex: i % 2,
        _symbolIndex: i,
      }
    })
    return { seriesData, categories }
  }

  const buildHorizonalBarSeries = (chart) => {
    if (props.page === 'industryCustomers') {
      let vals = chart.keys.map((key) => {
        let val = calcs['avg'][key][props.year];
        return val ? (Math.round(val * 10) / 10) : null;
      })

      return [{
        data: vals,
        showInLegend: false,
        name: 'avg',
        color: '#542667',
      }]

    } else {
      if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
        return ['you', 'avg'].map((g, i) => {
          let dataName;
          if (g === 'you') {
            dataName = 'You'
          }
          if (g === 'avg') {
            dataName = 'Peers'
          }
          let vals = chart.keys.map((key) => {
            let val = calcs[g][key][props.year];
            return val ? (Math.round(val * 10) / 10) : null;
          })

          return {
            data: vals,
            showInLegend: true,
            name: dataName,
            color: dataName === 'You' ? '#542667' : '#ae6dc9',
          }
        })
      }

      return ['you', 'avg'].map((g, i) => {
        let dataName;
        if (g === 'you') {
          dataName = 'You'
        }
        if (g === 'avg') {
          dataName = 'Peers'
        }

        return {
          data: [0, 0],
          showInLegend: true,
          name: dataName,
          color: dataName === 'You' ? '#542667' : '#ae6dc9',
        }
      })
    }
  }

  const buildLineSeries = (chart) => {
    if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
      return ['you', 'avg'].map((g, i) => {
        let dataName;
        if (g === 'you') {
          dataName = 'You'
        }
        if (g === 'avg') {
          dataName = 'Peers'
        }
        let vals = chart.keys.map((key) => {
          // need latest year for cltv70, 80, 90
          let val = calcs[g][key][props.year];
          return val ? val : null;
        })

        return {
          data: vals,
          showInLegend: true,
          name: dataName,
          title: dataName,
          color: dataName === 'You' ? '#542667' : '#ae6dc9',
          _symbolIndex: i,
        }
      })
    }
    return ['you', 'avg'].map((g, i) => {
      let dataName;
      if (g === 'you') {
        dataName = 'You'
      }
      if (g === 'avg') {
        dataName = 'Peers'
      }

      return {
        data: [0, 0],
        showInLegend: true,
        name: dataName,
        color: dataName === 'You' ? '#542667' : '#ae6dc9',
      }
    })
  }

  const buildVerticalBarData = (chart, set) => {
    let value = null;
    if (props.displayColumnsBy !== 'Months' && props.reportPeriod !== 'Year to Date') {
      if (set === 'you' && calcs['you']) {
        value = calcs['you'][chart.key][props.year];
      }
      if (set === 'Peers' && calcs['avg']) {
        value = calcs['avg'][chart.key][props.year];
      }
      if (set === 'YOY' && previousYearCalcs['you']) {
        value = previousYearCalcs['you'][chart.key][props.year];
      }

    } else {
      let monthNumber = 0;
      let metrics = set === 'you' ? props.youMetrics : props.compareMetrics;
      let useableMonths;
      if (props.displayColumnsBy === 'Months') {
        useableMonths = Object.values(calcs['you']['roa']);
      } else if (props.reportPeriod === 'Year to Date') {
        useableMonths = Object.values(calcs[props.year]['you']['roa']);
      }
      useableMonths.forEach(thing => thing !== null ? monthNumber += 1 : null);
      if (metrics[chart.key]) {
        value = Math.round(metrics[chart.key][monthNumber]);
      }
    }

    return value;
  }

  // Used by AP and AR Aging Summary Reports
  const buildAPARAgingSeries = (chart) => {
    let returnSeries;
    let firstData = [];
    let secondData = [];
    let thirdData = [];
    let fourthData = [];
    let currentData = [];
    let apOrAr = chart.key === 'ap_aging_summary' ? 'ap' : 'ar';
    let agingKey91Over = apOrAr + '_91_and_over';
    let agingKey6191 = apOrAr + '_61_90';
    let agingKey3160 = apOrAr + '_31_60';
    let agingKey130 = apOrAr + '_1_30';
    let agingKeyCurrent = apOrAr + '_current';

    if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
      chart.years.forEach(year => {
        firstData.push(calcs['you'][agingKey91Over][year])
        secondData.push(calcs['you'][agingKey6191][year])
        thirdData.push(calcs['you'][agingKey3160][year])
        fourthData.push(calcs['you'][agingKey130][year])
        currentData.push(calcs['you'][agingKeyCurrent][year])
      })
      returnSeries = [
        {
          name: '91 and over',
          color: '#ffb74d',
          data: firstData
        },
        {
          name: '61-90',
          color: '#999',
          data: secondData
        },
        {
          name: '31-60',
          color: '#542667',
          data: thirdData
        },
        {
          name: '1-30',
          color: '#00aeef',
          data: fourthData
        },
        {
          name: 'Current',
          color: '#69b144',
          data: currentData
        }
      ]
    }

    if (props.reportPeriod === 'Year to Date') {
      chart.years.forEach(year => {
        let firstVals = calcs[year]['you'][agingKey91Over] ? Object.values(calcs[year]['you'][agingKey91Over]) : [];
        let secondVals = calcs[year]['you'][agingKey6191] ? Object.values(calcs[year]['you'][agingKey6191]) : [];
        let thirdVals = calcs[year]['you'][agingKey3160] ? Object.values(calcs[year]['you'][agingKey3160]) : [];
        let fourthVals = calcs[year]['you'][agingKey130] ? Object.values(calcs[year]['you'][agingKey130]) : [];
        let currentVals = calcs[year]['you'][agingKeyCurrent] ? Object.values(calcs[year]['you'][agingKeyCurrent]) : [];
        firstData.push(firstVals[firstVals.length - 1])
        secondData.push(secondVals[secondVals.length - 1])
        thirdData.push(thirdVals[thirdVals.length - 1])
        fourthData.push(fourthVals[fourthVals.length - 1])
        currentData.push(currentVals[currentVals.length - 1])
      })
      returnSeries = [
        {
          name: '91 and over',
          color: '#ffb74d',
          data: firstData
        },
        {
          name: '61-90',
          color: '#999',
          data: secondData
        },
        {
          name: '31-60',
          color: '#542667',
          data: thirdData
        },
        {
          name: '1-30',
          color: '#00aeef',
          data: fourthData
        },
        {
          name: 'Current',
          color: '#69b144',
          data: currentData
        }
      ]
    }

    if (props.displayColumnsBy === 'Months') {
      returnSeries = [
        {
          name: '91 and over',
          color: '#ffb74d',
          data: Object.values(calcs['you'][agingKey91Over])
        },
        {
          name: '61-90',
          color: '#999',
          data: Object.values(calcs['you'][agingKey6191])
        },
        {
          name: '31-60',
          color: '#542667',
          data: Object.values(calcs['you'][agingKey3160])
        },
        {
          name: '1-30',
          color: '#00aeef',
          data: Object.values(calcs['you'][agingKey130])
        },
        {
          name: 'Current',
          color: '#69b144',
          data: Object.values(calcs['you'][agingKeyCurrent])
        }
      ]
    }
    return returnSeries;
  }

  const buildYouLineOrColData = (chart) => {
    const key = chart.key;
    let dataMap = [];
    if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
      dataMap = chart.years.map(year => {
        let value;
        try {
          if (key === 'net_working_capital') {
            let totalCurrentAssets = calcs['you']['total_current_assets'][year];
            let totalCurrentLiabilities = calcs['you']['total_current_liabilities'][year];
            value = totalCurrentAssets - totalCurrentLiabilities;
          } else if (key === 'senior_debt_to_ebitda') {
            let longTermDebt = calcs['you']['long_term_debt'][year];
            let lineOfCredit = calcs['you']['line_of_credit'][year];
            let currentPortionOfLTD = calcs['you']['current_portion_of_ltd'][year];
            let netIncome = calcs['you']['operating_expenses_net_income'][year];
            let interest = calcs['you']['interest_and_other_expenses'][year];
            let taxes = calcs['you']['total_other_costs_taxes_- federal & state'][year];
            let deprAmor = calcs['you']['depreciation_and_amortization'][year];
            let ebitda = netIncome + interest + taxes + deprAmor;
            value = (longTermDebt + lineOfCredit + currentPortionOfLTD) / ebitda;
          } else if (key === 'ebitda_to_annual_interest') {
            let netIncome = calcs['you']['operating_expenses_net_income'][year];
            let interest = calcs['you']['interest_and_other_expenses'][year];
            let taxes = calcs['you']['total_other_costs_taxes_- federal & state'][year];
            let deprAmor = calcs['you']['depreciation_and_amortization'][year];
            let ebitda = netIncome + interest + taxes + deprAmor;
            value = ebitda / interest;
          } else {
            value = calcs.you[key][year];
          }
          return value ? value : null;
        } catch (e) {
          return null;
        }
      })
    }

    if (props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date') {
      if (props.page === 'money') {
        dataMap = chart.years.map(year => {
          try {
            if (chart.percentOfRevenueKey) {
              let value = ytdPercentOfRevenueCalc(chart.percentOfRevenueKey, key, calcs[year]['you']);
              return value;
            } else if (key === 'senior_debt_to_ebitda') {
              let value = calculateSeniorDebtToEBITDA(calcs[year]['you'])
              return value;
            } else if (key === 'ebitda_to_annual_interest') {
              let value = calculateEBITDAtoAnnualInterest(calcs[year]['you'])
              return value;
            } else if (key === 'total_revenue_growth_rate') {
              let totalRevenueGR = ytdTotalRevenueGRCalc(calcs[year]['you'], previousYearCalcs['monthly'][year - 1]['you'])
              return totalRevenueGR;
            } else {
              let value = calculateKFIMetric(key, calcs[year]['you'], previousYearCalcs['monthly'][year - 1]['you'])
              return value;
            }
          } catch (e) {
            return null;
          }
        })
      } else {
        let selectedYearVal, previousYearVal = null;
        let monthNumber = 0;
        let useableMonths = Object.values(calcs[props.year]['you']['roa']);
        useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthNumber += 1 : null);

        if (key === 'total_revenue' || key === 'gross_profit' || key === 'operating_expenses_net_income') {
          selectedYearVal = objReducer(calcs[props.year]['you'][key])
          previousYearVal = objReducer(calcs[props.year - 1]['you'][key])
        } else {
          if (props.youMetrics[key]) {
            selectedYearVal = props.youMetrics[key][monthNumber];
          }
          if (props.youPreviousYearMetricsYTD[key]) {
            previousYearVal = props.youPreviousYearMetricsYTD[key][monthNumber];
          }
        }
        dataMap = [previousYearVal, selectedYearVal]
      }
    }

    if (props.displayColumnsBy === 'Months') {
      let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      if (props.page === 'money') {
        if (chart.trailing12Key) {
          let trailing12Obj = trailing12MonthCalculations(chart.trailing12Key, calcs.you, previousYearCalcs.you);
          dataMap = Object.values(trailing12Obj);
        } else if (chart.percentOfRevenueKey) {
          let percentOfRevenueObj = monthlyPercentOfRevenueCalc(chart.percentOfRevenueKey, key, calcs.you);
          dataMap = Object.values(percentOfRevenueObj);
        } else if (key === 'current_ratio') {
          let currentRatioObj = ratioCalculations('total_current_assets', calcs.you)
          dataMap = Object.values(currentRatioObj);
        } else if (key === 'net_working_capital_ratio') {
          let netWorkingCapRatioObj = netWorkingCapitalRatioCalculation(calcs.you)
          dataMap = Object.values(netWorkingCapRatioObj);
        } else if (key === 'net_working_capital') {
          let netWorkingCapitalObj = netWorkingCapitalCalculation(calcs.you)
          dataMap = Object.values(netWorkingCapitalObj)
        } else if (key === 'total_revenue_growth_rate') {
          let totalRevenueGR = monthlyTotalRevenueGRCalc(calcs.you, previousYearCalcs.you)
          dataMap = Object.values(totalRevenueGR);
        } else {
          let returnObj = {};
          let metricValues = _.pickBy(calcs.you[key], _.isNumber);
          for (let month in metricValues) {
            returnObj[month] = metricValues[month]
          }
          dataMap = Object.values(returnObj);
        }
      } else {
        let returnObj = {};
        let metricValues = _.pickBy(props.youMetrics[key], _.isNumber);
        for (let month in metricValues) {
          returnObj[month] = metricValues[month]
        }
        dataMap = Object.values(returnObj);
      }
    }
    return dataMap;
  }

  const buildCompareLineOrColData = (chart) => {
    const key = chart.key;
    let dataMap = [];
    if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
      dataMap = chart.years.map(year => {
        let value;
        try {
          if (key === 'net_working_capital') {
            let totalCurrentAssets = calcs['avg']['total_current_assets'][year];
            let totalCurrentLiabilities = calcs['avg']['total_current_liabilities'][year];
            value = totalCurrentAssets - totalCurrentLiabilities;
          } else if (key === 'senior_debt_to_ebitda') {
            let longTermDebt = calcs['avg']['long_term_debt'][year];
            let lineOfCredit = calcs['avg']['line_of_credit'][year];
            let currentPortionOfLTD = calcs['avg']['current_portion_of_ltd'][year];
            let netIncome = calcs['avg']['operating_expenses_net_income'][year];
            let interest = calcs['avg']['interest_and_other_expenses'][year];
            let taxes = calcs['avg']['total_other_costs_taxes_- federal & state'][year];
            let deprAmor = calcs['avg']['depreciation_and_amortization'][year];
            let ebitda = netIncome + interest + taxes + deprAmor;
            value = (longTermDebt + lineOfCredit + currentPortionOfLTD) / ebitda;
          } else if (key === 'ebitda_to_annual_interest') {
            let netIncome = calcs['avg']['operating_expenses_net_income'][year];
            let interest = calcs['avg']['interest_and_other_expenses'][year];
            let taxes = calcs['avg']['total_other_costs_taxes_- federal & state'][year];
            let deprAmor = calcs['avg']['depreciation_and_amortization'][year];
            let ebitda = netIncome + interest + taxes + deprAmor;
            value = ebitda / interest;
          } else {
            value = calcs.avg[key][year];
          }
          return value ? value : null;
        } catch (e) {
          return null;
        }
      })
    }

    if (props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date') {
      if (props.page === 'money') {
        dataMap = chart.years.map(year => {
          try {
            if (chart.percentOfRevenueKey) {
              let value = ytdPercentOfRevenueCalc(chart.percentOfRevenueKey, key, calcs[year]['avg']);
              return value;
            } else if (key === 'senior_debt_to_ebitda') {
              let value = calculateSeniorDebtToEBITDA(calcs[year]['avg'])
              return value;
            } else if (key === 'ebitda_to_annual_interest') {
              let value = calculateEBITDAtoAnnualInterest(calcs[year]['avg'])
              return value;
            } else if (key === 'total_revenue_growth_rate') {
              let totalRevenueGR = ytdTotalRevenueGRCalc(calcs[year]['avg'], previousYearCalcs['monthly'][year - 1]['avg'])
              return totalRevenueGR;
            } else {
              let value = calculateKFIMetric(key, calcs[year]['avg'], previousYearCalcs['monthly'][year - 1]['avg'])
              return value;
            }
          } catch (e) {
            return null;
          }
        })
      } else {
        let selectedYearVal, previousYearVal = null;
        let monthNumber = 0;
        let useableMonths = Object.values(calcs[props.year]['you']['roa']);
        useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthNumber += 1 : null);

        if (key === 'total_revenue' || key === 'gross_profit' || key === 'operating_expenses_net_income') {
          selectedYearVal = objReducer(calcs[props.year]['avg'][key])
          previousYearVal = objReducer(calcs[props.year - 1]['avg'][key])
        } else {
          if (props.compareMetrics[key]) {
            selectedYearVal = props.compareMetrics[key][monthNumber];
          }
          if (props.comparePreviousYearMetricsYTD[key]) {
            previousYearVal = props.comparePreviousYearMetricsYTD[key][monthNumber];
          }
        }

        dataMap = [previousYearVal, selectedYearVal]
      }
    }

    if (props.displayColumnsBy === 'Months') {
      let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

      if (props.page === 'money') {
        if (chart.compareWith === 'YOY') {
          if (chart.trailing12Key) {
            let trailing12Obj = trailing12MonthCalculations(chart.trailing12Key, previousYearCalcs.you, previous_yoy_calcs.you)
            dataMap = Object.values(trailing12Obj);
          } else if (chart.percentOfRevenueKey) {
            let percentOfRevenueObj = monthlyPercentOfRevenueCalc(chart.percentOfRevenueKey, key, previousYearCalcs.you);
            dataMap = Object.values(percentOfRevenueObj);
          } else if (key === 'current_ratio') {
            let currentRatioObj = ratioCalculations('total_current_assets', previousYearCalcs.you)
            dataMap = Object.values(currentRatioObj);
          } else if (key === 'net_working_capital_ratio') {
            let netWorkingCapRatioObj = netWorkingCapitalRatioCalculation(previousYearCalcs.you)
            dataMap = Object.values(netWorkingCapRatioObj);
          } else if (key === 'net_working_capital') {
            let netWorkingCapitalObj = netWorkingCapitalCalculation(previousYearCalcs.you)
            dataMap = Object.values(netWorkingCapitalObj)
          } else if (key === 'total_revenue_growth_rate') {
            let totalRevenueGR = monthlyTotalRevenueGRCalc(previousYearCalcs.you, previous_yoy_calcs.you)
            dataMap = Object.values(totalRevenueGR);
          } else {
            let returnObj = {};
            let metricValues = _.pickBy(previousYearCalcs.you[key], _.isNumber);
            for (let month in metricValues) {
              returnObj[month] = metricValues[month]
            }
            dataMap = Object.values(returnObj);
          }
        }

        if (chart.compareWith === 'Peers') {
          if (chart.trailing12Key) {
            let trailing12Obj = trailing12MonthCalculations(chart.trailing12Key, calcs.avg, previousYearCalcs.avg)
            dataMap = Object.values(trailing12Obj);
          } else if (chart.percentOfRevenueKey) {
            let percentOfRevenueObj = monthlyPercentOfRevenueCalc(chart.percentOfRevenueKey, key, calcs.avg);
            dataMap = Object.values(percentOfRevenueObj);
          } else if (key === 'current_ratio') {
            let currentRatioObj = ratioCalculations('total_current_assets', calcs.avg)
            dataMap = Object.values(currentRatioObj);
          } else if (key === 'net_working_capital_ratio') {
            let netWorkingCapRatioObj = netWorkingCapitalRatioCalculation(calcs.avg)
            dataMap = Object.values(netWorkingCapRatioObj);
          } else if (key === 'net_working_capital') {
            let netWorkingCapitalObj = netWorkingCapitalCalculation(calcs.avg)
            dataMap = Object.values(netWorkingCapitalObj)
          } else if (key === 'total_revenue_growth_rate') {
            let totalRevenueGR = monthlyTotalRevenueGRCalc(calcs.avg, previousYearCalcs.avg)
            dataMap = Object.values(totalRevenueGR);
          } else {
            let returnObj = {};
            let metricValues = _.pickBy(calcs.avg[key], _.isNumber);
            for (let month in metricValues) {
              returnObj[month] = metricValues[month]
            }
            dataMap = Object.values(returnObj);
          }
        }
      } else {
        let returnObj = {};
        let metricValues = _.pickBy(props.compareMetrics[key], _.isNumber);
        for (let month in metricValues) {
          returnObj[month] = metricValues[month]
        }
        dataMap = Object.values(returnObj);
      }
    }
    return dataMap
  }

  const pieExpenseTitles = {
    you: 'Your Expenses',
    avg: 'Peer Expenses',
    practice_avg: 'Practice Expenses',
    yoy: 'YOY Expenses'
  }
  let NaicsCode = window.data ? window.data.code : props.naicsCode;
  let pagePies, MoneyArrayBasedOnNAICS, CustomersArrayBasedOnNAICS;
  if (props.page === 'industryMoney' || props.page === 'money' || props.page === 'people') {
    // The Pie chart on Annual Money Report needs to display different things depending on what NAICS code the company is
    let NaicsCode3digits, NaicsCode4digits;
    NaicsCode !== undefined ? NaicsCode3digits = NaicsCode.slice(0, 3) : null
    NaicsCode !== undefined ? NaicsCode4digits = NaicsCode.slice(0, 4) : null

    if (NaicsCode3digits === '621') {
      MoneyArrayBasedOnNAICS = ['automobile_expenses', 'bad_debt_expenses', 'computer_related_costs', 'contributions_and_donations', 'insurance_expenses', 'office_communications', 'office_expenses', 'other_operating_expenses', 'total_professional_fees', 'rent_and_facilities_costs', 'laboratory_fees', 'medical_supplies']
      CustomersArrayBasedOnNAICS = ['total_customer_costs_sales_& marketing', 'total_customer_costs_billings_& collections', 'travel_entertainment_and_meals', 'customers_misc_professional_fees']
    } else if (NaicsCode4digits === '6212') {
      MoneyArrayBasedOnNAICS = ['automobile_expenses', 'bad_debt_expenses', 'computer_related_costs', 'contributions_and_donations', 'insurance_expenses', 'office_communications', 'office_expenses', 'other_operating_expenses', 'total_professional_fees', 'rent_and_facilities_costs', 'laboratory_fees', 'dental_supplies']
      CustomersArrayBasedOnNAICS = ['total_customer_costs_sales_& marketing', 'total_customer_costs_billings_& collections', 'travel_entertainment_and_meals', 'customers_misc_professional_fees']
    } else if (NaicsCode === '713910') {
      MoneyArrayBasedOnNAICS = ['automobile_expenses', 'bad_debt_expenses', 'computer_related_costs', 'contributions_and_donations', 'insurance_expenses', 'office_communications', 'office_expenses', 'total_operations_ops_- golf', 'total_operations_ops_- food & bev', 'total_operations_ops_- sporting activities', 'total_operations_ops_- clubhouse', 'other_operating_expenses', 'total_professional_fees', 'rent_and_facilities_costs', 'warranty_expense']
      CustomersArrayBasedOnNAICS = ['sales_and_marketing', 'travel_entertainment_and_meals', 'customers_misc_professional_fees']
    } else {
      MoneyArrayBasedOnNAICS = ['automobile_expenses', 'bad_debt_expenses', 'computer_related_costs', 'contributions_and_donations', 'insurance_expenses', 'office_communications', 'office_expenses', 'other_operating_expenses', 'total_professional_fees', 'rent_and_facilities_costs', 'warranty_expense']
      CustomersArrayBasedOnNAICS = ['sales_and_marketing', 'travel_entertainment_and_meals', 'customers_misc_professional_fees']
    }

    pagePies = {
      money: {
        keys: {
          'Money': MoneyArrayBasedOnNAICS,
          'People': ['total_compensation', 'total_taxes_and_benefits', 'total_corporate_culture', 'subcontractor_expenses', 'people_misc_professional_fees'],
          'Customers': CustomersArrayBasedOnNAICS,
          'COGS': ['labor_costs', 'cogs_subcontractors', 'materials_costs', 'overhead', 'transportation', 'other_costs'],
          'Net Income': ['operating_expenses_net_income'],
        },
        commonSize: 'revenue',
        subtitle: '(By % of Total Revenue)'
      },

      people: {
        keys: {
          'Compensation': [{ key: 'total_compensation', title: 'OpEx Compensation' }, 'labor_cogs_compensation'],
          'Taxes and Benefits': ['labor_cogs_taxes_and_benefits', 'payroll_taxes', 'health_insurance', 'retirement_plan', 'other_taxes_and_benefits'],
          'Corporate Culture': ['recruiting', 'training', { key: 'other_corporate_culture', title: 'Other' }],
          'Subcontractor Expenses': ['cogs_subcontractors', { key: 'subcontractor_expenses', title: 'OpEx Subcontractor Expenses' }],
          'Professional Fees': ['people_misc_professional_fees'],
        },
        commonSize: 'revenue',
        subtitle: '(By % of Total Revenue)'
      }
    }

  }

  const costOfTurnover = () => {
    const lostProductivity = .2;
    const lostProductivityTime = .125;
    const lostWagesTime = .125;
    const employeeProductivityDecline = .5;
    const employeeProductivityDeclineTime = .185;

    // Cost of Turnover Formula
    // (Your Turnover Rate(year) − Peer Turnover Rate(year)) 
    // * (Number of Employees(year) 
    // * ((Revenue Per Employee(year) * Lost Productivity * Lost Productivity Time) 
    //   + (Average Wages and Salaries(year) * Lost Wages Time ) 
    //   + ((.25 * Training(year))/Number of Employees(year))
    //   + (Revenue Per Employee(year) * Employee Productivity Decline * Employee Productivity Decline Time)))

    if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
      if (calcs.you.turnover_rate[props.year] !== 0 && calcs.you.total_number_of_employees[props.year] !== 0 && calcs.you.revenue_per_employee[props.year] !== 0 && calcs.you.average_wages_and_salaries[props.year] !== 0) {
        return ((calcs.you.turnover_rate[props.year] - calcs.avg.turnover_rate[props.year])
          * (calcs.you.total_number_of_employees[props.year]
            * ((calcs.you.revenue_per_employee[props.year] * lostProductivity * lostProductivityTime)
              + (calcs.you.average_wages_and_salaries[props.year] * lostWagesTime)
              + ((calcs.you.training[props.year] * .25) / calcs.you.total_number_of_employees[props.year])
              + (calcs.you.revenue_per_employee[props.year] * employeeProductivityDecline * employeeProductivityDeclineTime))));
      } else {
        return 0;
      };
    }

    return 0;
  }

  const buildRankingTable = (chartProps, rankingInfo) => {
    return (
      <div className='snapshot-table' key={chartProps.key}>
        <table>
          <thead>
            <tr>
              <th className='snapshot-title snap-cell' colSpan='100%'>{chartProps.title}</th>
            </tr>
            <tr className='snapshot-header-cols'>
              {
                (
                  chartProps.headerCols.map((h) => {
                    return <th key={`${chartProps.title}-${h}`}><div className='snap-cell'>{h}</div></th>
                  })
                )
              }
            </tr>
          </thead>
          <tbody>
            {
              (rankingInfo[chartProps.key].map((s, i) => {
                let rowColor = i % 2 === 0 ? { backgroundColor: '#F5F5F5' } : {}
                return (
                  <tr key={`${chartProps.key}-row-${i}`} style={rowColor}>{
                    chartProps.keys.map((k) => {
                      if (Array.isArray(k)) {
                        let key = k[0]
                        return <td key={`snap-cell-${k}`}><div className='snap-cell'>{format(k[1], s[key])}</div></td>
                      } else {
                        return <td key={`snap-cell-${k}`}><div className='snap-cell'>{s[k]}</div></td>
                      }
                    })
                  }</tr>
                )
              }))
            }
          </tbody>
        </table>
      </div>
    )
  }

  const getPrintPositionStyles = (item) => {
    const printStyles = { position: 'absolute', backgroundColor: 'white', border: '1px solid black', borderRadius: '2px' }
    let width = item.w > 1 ? (item.w * 34) + (item.w - 1) : item.w * 34
    let setHeightNum = item.h > 6 ? 6 : item.h
    let height = setHeightNum > 1 ? (setHeightNum * 34) + (setHeightNum - 1) : setHeightNum * 34
    let posX = (item.x * 34) + ((item.x + 1) * 1)
    let posY = (item.y * 34) + ((item.y + 1) * 1)

    printStyles.width = `${width}mm`
    printStyles.height = `${height}mm`
    printStyles.left = `${posX}mm`
    printStyles.top = `${posY}mm`
    return printStyles
  }

  // react-grid-layout recommends memoizing items in layout to optimize rendering
  let layoutItems = useMemo(() => {
    return props.dashboardLayout.map((l) => {
      let positionStyles = props.pdf ? getPrintPositionStyles(l) : {}

      if (l.i === 'peerview_gauges') {
        let allStyles = { ...positionStyles, display: 'flex' }
        return (
          <div key='peerview_gauges' className='dashboard-gauges' style={allStyles}>
            {gaugeKeys[props.page].map((metricKey, i) => {
              let gaugeData = 0;
              if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
                gaugeData = Math.round(calcs.you[metricKey][props.year])
              }
              if (props.displayColumnsBy === 'Months' || props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date') {
                const peerviewMetrics = peerviewMetricsYears[props.year]['you'];
                if (peerviewMetrics.hasOwnProperty(metricKey)) {
                  gaugeData = Math.round(peerviewMetrics[metricKey]);
                } else {
                  gaugeData = 0;
                }
              }
              let gaugeOptions = getGaugeOptions(metricKey, gaugeData);
              return (
                <HighchartsReact key={metricKey} ref={setNewRef} containerProps={{ style: { width: '100%', height: '100%' }, key: `gauge-${i}`, id: `gauge-${i}` }} highcharts={Highcharts} options={gaugeOptions} />
              )
            })}
          </div>
        )
      } else if (l.i === 'you_pie' || l.i === 'compare_pie') {
        let group;
        if (l.i === 'you_pie') {
          group = 'you'
        } else {
          group = props.compareWith === 'Peers' ? 'avg' : props.compareWith === 'Practice' ? 'practice_avg' : 'yoy';
        }
        let pieCalcs;
        if (group === 'yoy') {
          pieCalcs = previousYearCalcs['you'];
        } else if (props.reportPeriod === 'Year to Date') {
          pieCalcs = calcs[props.year][group];
        } else if (props.reportPeriod !== 'Year to Date') {
          pieCalcs = calcs[group];
        }

        const pieProps = {
          commonSize: pagePies[props.page].commonSize,
          data: pieCalcs,
          displayColumnsBy: props.displayColumnsBy,
          keys: pagePies[props.page].keys,
          page: props.page,
          reportPeriod: props.reportPeriod,
          subtitle: pagePies[props.page].subtitle,
          title: pieExpenseTitles[group],
          year: props.year,
        }
        let pieOptions = getPieOptions(pieProps);
        let pieKey = l.i;
        let pieClass = props.page === 'money' ? 'money-expenses-charts' : 'people-expenses-charts';
        return (
          <div key={pieKey} className={pieClass} style={positionStyles}><HighchartsReact ref={setNewRef} containerProps={{ style: { width: "100%", height: "100%" }, key: pieKey, id: pieKey }} highcharts={Highcharts} options={pieOptions} /></div>
        )
      } else if (l.i === 'donut_pie_industry_expenses') {
        let donutKey = l.i;
        const donutProps = {
          commonSize: pagePies['money'].commonSize,
          data: calcs['avg'],
          displayColumnsBy: props.displayColumnsBy,
          keys: pagePies['money'].keys,
          page: 'money',
          reportPeriod: props.reportPeriod,
          year: props.year,
        }

        let donutOptions = getDonutOptions(donutProps);

        return (
          <div key={donutKey} className={donutKey} style={positionStyles}><HighchartsReact ref={setNewRef} containerProps={{ style: { width: "100%", height: "100%", paddingRight: "5px" }, key: donutKey, id: donutKey }} highcharts={Highcharts} options={donutOptions} /></div>
        )
      } else {
        let chart = props.dashBoardType === 'normalDashboard' ? pageCharts[props.page].find(c => c.key === l.i) : null
        if (chart) {
          let chartKey = chart.key
          let youData, compareData, chartOptions;
          let chartProps = {
            i: l.i,
            aggregateYTDDate: props.aggregateYTDDate || null,
            companyYTDDate: props.companyYTDDate || null,
            format: chart.format,
            headerCols: chart.headerCols,
            compareWith: props.compareWith || null,
            displayColumnsBy: props.displayColumnsBy || null,
            fiscalYearEnd: props.fiscalYearEnd || '12/31',
            key: chart.key,
            keys: chart.keys || null,
            months: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
            page: props.page,
            percentOfRevenueKey: chart.percentOfRevenueKey || null,
            reportPeriod: props.reportPeriod,
            title: titleSetter(chart),
            subtitle: chart.subtitle,
            styles: l.styles || chart.styles,
            trailing12Key: chart.trailing12Key || null,
            x: chart.x,
            y: chart.y,
            years: props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date' ? [props.year - 1, props.year] : props.yearRange,
          }

          switch (chart.chartType) {
            case '3DBubble':
              chartOptions = get3DBubbleChartOptions(chartProps, props.calculations);
              break;
            case 'quadrantBubble':
              if (props.page === 'practice') {
                chartOptions = getQuadrantOptions(chartProps, props.calculations);
              }
              if (props.page === 'money') {
                let chartData = { name: props.naicsCode, data: [] };
                let dataObj = { name: props.clientName, color: '#69B144' }
                if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
                  dataObj.x = Math.round(calcs.you[chartProps.keys[0]][props.year])
                  dataObj.y = Math.round(calcs.you[chartProps.keys[1]][props.year])
                  dataObj.z = calcs.you['total_revenue'][props.year]
                  chartData.data.push(dataObj);
                }
                if (props.displayColumnsBy === 'Months' || props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date') {
                  const peerviewMetrics = peerviewMetricsYears[props.year]['you'];
                  dataObj.x = peerviewMetrics.hasOwnProperty(chartProps.keys[0]) ? peerviewMetrics[chartProps.keys[0]] : 0;
                  dataObj.y = peerviewMetrics.hasOwnProperty(chartProps.keys[1]) ? peerviewMetrics[chartProps.keys[1]] : 0;
                  dataObj.z = props.displayColumnsBy === 'Months' ? objReducer(calcs.you['total_revenue']) : objReducer(calcs[props.year]['you']['total_revenue']);
                  chartData.data.push(dataObj);
                }
                chartOptions = getQuadrantOptions(chartProps, chartData)
              }
              break;
            case 'rankingTable':
              return buildRankingTable(chartProps, props['snapshotInfo'])
              break;
            case 'scatterPlot':
              chartOptions = getScatterPlotOptions(chartProps, props.calculations);
              break;
            case 'line':
              let lineData = buildIndustryLineData(chartProps)
              chartOptions = getIndustryLineChartOptions(chartProps, lineData)
              break;
            case 'bar':
              let barData = buildIndustryBarData(chartProps)
              chartOptions = getIndustryBarChartOptions(chartProps, barData)
              break;
            case 'editableChart':
            case 'lineBar':
              chartProps.editStyles = true;
              youData = buildYouLineOrColData(chartProps);
              compareData = buildCompareLineOrColData(chartProps);
              chartOptions = getLineOrColOptions(chartProps, youData, compareData, props.fiscalYearEnd);
              break;
            case 'verticalBars':
              youData = buildVerticalBarData(chartProps, 'you');
              compareData = buildVerticalBarData(chartProps, chartProps.compareWith);
              chartOptions = getVerticalBarChartOptions(chartProps, youData, compareData);
              break;
            case 'horizontalBars':
              let xCategories = chart.keys.map((k) => COPY[k]);
              let hBarSeries = buildHorizonalBarSeries(chartProps);
              chartOptions = getHorizontalBarOptions(chartProps, xCategories, hBarSeries);
              break;
            case 'stackedBars':
              youData = buildAPARAgingSeries(chartProps)
              chartOptions = getStackedColumnOptions(chartProps, youData);
              break;
            case 'lines':
              let seriesTitles = chart.keys.map((k) => COPY[k])
              let lineSeries = buildLineSeries(chartProps)
              chartOptions = getLineChartOptions(chartProps, seriesTitles, lineSeries);
              break;
            case 'wageDataScaffoldTable':
              return (<div className='wage-data-scaffold' key={chartKey} style={positionStyles}><WageDataScaffold wageData={calcs.wagedata} /></div>)
            case 'noChart':
              let value = costOfTurnover();
              return (
                <div className='cost-of-turnover' key={chartKey} style={positionStyles}>
                  <h3>Cost of Turnover</h3>
                  <p>(Based on S.H.R.M. Standards)</p>
                  <div className='turnover-border'>
                    <h2 className='cost-of-turnover-value'>
                      {parseMoney(Math.abs(value))}
                    </h2>
                    <p>{value < 0 ? 'Less' : 'More'} Than Your Peers</p>
                  </div>
                </div>
              )
          }
          if (chart.dragTogglable) {
            return (
              <div key={chartKey} style={positionStyles}>
                <button className='lock-btn' onClick={() => props.handleToggleDraggable(chartKey)}>{l.isDraggable ? <FontAwesomeIcon icon={faLockOpen} /> : <FontAwesomeIcon icon={faLock} />}</button>
                <HighchartsReact ref={setNewRef} containerProps={{ style: { width: '100%', height: '100%' }, key: chartKey, id: chartKey }} highcharts={Highcharts} options={chartOptions} />
              </div>
            )
          } else {
            return (
              <div className='reports-dashboard-chart' key={chartKey} style={positionStyles}>
                {props.page !== 'industryMoney' && chartProps.editStyles ? <button className='edit-dash-item-btn' onClick={() => props.handleEditChartModal('open', chartProps, chartOptions)}><FontAwesomeIcon icon={faPencil} /></button> : null}
                <HighchartsReact ref={setNewRef} containerProps={{ style: { width: '100%', height: '100%' }, key: chartKey, id: chartKey }} highcharts={Highcharts} options={chartOptions} />
              </div>
            )
          }
        }
        return null
      }
    })

  }, [props.dashboardLayout, props.year, props.calculations, calcs])

  if (props.pdf) {
    if (props.dashboardLayout.length > 0) {
      let heightStyle = {
        height: `248mm`
      }

      return (
        <div className='parent-dash-portrait-pdf' style={heightStyle}>
          {layoutItems}
        </div>
      )
    }
  } else {
    return (
      <ResponsiveGridLayout
        className='parent-layout'
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        layouts={{ lg: props.dashboardLayout }}
        cols={{ lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 }}
        rowHeight={210}
        isDraggable={true}
        isDroppable={true}
        isResizable={true}
        onResizeStop={(layout, oldItem, newItem) => {
          // Force reflow() on specific item when user resizes it.
          let item = oldItem ? oldItem : newItem;
          let checkChartRefs = _.isEmpty(chartRefs)
          if (!checkChartRefs && item && chartRefs[item.i]) {
            chartRefs[item.i].chart.reflow();
          }
        }}
        onLayoutChange={(layout) => {
          props.handleOnLayoutChange('normalDashboard', layout)
          // Force reflow() on highcharts when different display columns by filter submits
          // For some odd reason the highcharts are not resizing to new layout like before
          let checkChartRefs = _.isEmpty(chartRefs)
          if (!checkChartRefs) {
            for (const chartRef in chartRefs) {
              if (chartRefs[chartRef].chart) {
                let chartInfo = layout.find(l => l.i === chartRef)
                if (chartInfo) {
                  let chartWidth = chartInfo.w * 214;
                  let chartHeight = chartInfo.h * 208;
                  chartRefs[chartRef].chart.setSize(chartWidth, chartHeight)
                } else {
                  chartRefs[chartRef].chart.reflow();
                }
              }
            }
          }
        }}
      >
        {layoutItems}
      </ResponsiveGridLayout>
    )
  }
}
export default ReportsDashboard;
